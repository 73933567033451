import { typeCategorias } from "../utils/categories"

export const translateCategory = (category) => {
    switch (category) {
        case 'todo':
        return 'all'

        case 'Todo':
        return 'All'

        case 'cosmeticos':
        return 'Cosmetics'

        case 'bolsas':
        return 'Bags'

        case 'cuidado-corporal-y-fitness':
        return 'Bodycare & Fitness'

        case 'cables':
        return 'Cables'

        case 'componentes':
        return 'Computer Components'

        case 'muebles':
        return 'Furniture'

        case 'ropa-deportiva-para-hombres':
        return "Men's Sportswear"

        case 'aspiradoras':
        return 'Vacuum Cleaners'

        case 'accesorios-para-el-hogar':
        return 'Home Accessories'

        case 'bricolaje':
        return 'DIY'

        case 'cuidado-de-la-piel':
        return 'Skincare'

        case 'fragancias':
        return 'Fragrances'

        case 'afeitado' :
        return 'Shaving'

        case 'ropa-abrigo-para-hombres':
        return "Men's Outerwear"

        case 'salud' :
        return 'Health'

        case 'perifericos' :
        return 'Peripherals'

        case 'articulos-para-el-hogar':
        return 'General Household'

        case 'aparatos-para el-cuidado-del-cabello':
        return 'Haircare Appliances'

        case 'productos-para-el-cuidado-del-cabello':
        return 'Haircare Products'

        case 'ordenadores-portatiles':
        return 'Laptops'

        case'teclados':
        return 'Keyboards'

        case 'calzado-para-hombres':
        return "Men's Footwear"

        case 'ordenadores':
        return 'Computer'

        case 'Calzado para Hombres' :
        return "Men's Footwear"

        case 'deportes-de-raqueta':
        return'Racket Sports'

        case 'gadgets':
        return'Gadgets'
        
        case 'redes':
        return 'Networking'

        case 'accesorios-de-television':
        return 'TV Accessories'

        
        
    }
}
export const translateCategorySpanish = (category) => {
    switch (category) {

        case 'all':
        return 'Todo'

        case 'All':
        return 'Todo'
    
        case 'Cosmetics':
        return 'Cosmeticos'
    
        case 'Bags':
        return 'Bolsas'
    
        case 'Bodycare & Fitness':
        return 'Cuidado corporal y fitness'
    
        case 'Cables':
        return 'Cables'        
                                
        case 'Computer Components':
        return 'Componentes'
    
        case 'Furniture':
        return 'Muebles'
    
        case "Men's Sportswear":
        return 'Ropa Deportiva para Hombres'
    
        case 'Vacuum Cleaners':
        return 'Aspiradoras'
    
        case 'Home Accessories':
        return 'Accesorios para el Hogar'
    
        case 'DIY':
        return 'Bricolaje'
    
        case 'Skincare':
        return 'Cuidado de la Piel'
    
        case 'Fragrances':
        return 'Fragancias'
    
        case 'Shaving':
        return 'Afeitado' 
    
        case "Men's Outerwear":
        return 'Ropa Abrigo para Hombres'
    
        case 'Health':
        return 'Salud' 
    
        case 'Peripherals':
        return 'Perifericos' 
            
        case 'General Household':
        return 'Articulos para el Hogar'
    
        case 'Haircare Appliances':
        return 'Aparatos para el Cuidado del Cabello'
    
        case 'Haircare Products':
        return 'Productos para el Cuidado del Cabello'

        case 'Laptops':
        return 'Ordenadores Portatiles'
    
        case 'Keyboards':
        return'Teclados'
    
        case "Men's Footwear":
        return 'Calzado para Hombres'    

        case 'Computer':
        return 'Ordenadores'

        case 'Computers':
        return 'Ordenadores'
        
        case'Racket Sports':
        return 'Deportes de Raqueta'         

        case 'Networking':
        return 'Redes'
                                        
        case 'TV Accessories':
        return 'Accesorios de Television'     
        
        default:    
        return 'Todo'
        
        }
}