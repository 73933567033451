import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useRef, useState } from 'react'
import { useParams, useLocation } from 'wouter'

import { ListOfProductCardAwin } from '../../components/Products/ListOfProductCardAwin'
import { NoResultsFound } from '../../components/NoResultsFound/NoResultsFound'
import { Filters } from '../../components/Filters/Filters'
import { Spinner } from '../../components/Spinner/Spinner.jsx'

import { usePagination } from '../../hooks/usePagination.js'
import { useProduct } from '../../hooks/useProduct'
import { useSearch } from '../../hooks/useSearch'
import { useGoTop } from '../../hooks/useGoTop'
import { useFilter } from '../../hooks/useFilter'
import { useBrand } from '../../hooks/useBrand.js'
import { translateCategory, translateCategorySpanish } from '../../services/translateCategory.js'

import { ArroyRight, ArroyLeft } from '../../icons/icons.jsx'
import { useUrl } from '../../hooks/useUrl.js'
import { getsCategoryFromSearch } from '../../services/getFetchCategory.js'

export function Search() {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const data = useParams()
  const isInitial = useRef(true)
  const { search, setSearch } = useSearch()
  const { scrollTop } = useGoTop()
  const { filters, setFilters, prevFilters } = useFilter()
  const { handleArroyRight, handleArroyLeft, currentPage, setCurrentPage } = usePagination()
  const { fetchProducts, products, loading, getsProducts, error, totalProducts, totalProductsSearch, getsProductsByCategory } = useProduct()
  const [location, setLocation] = useLocation()
  const { getBrandFromSearch } = useBrand()

  let limit = 25
  const total = search || search === '' ? totalProductsSearch : totalProducts

  //const maxPage = Math.ceil(total / limit)

  const url = useUrl({ search, currentPage, filters: filters })
  const hasProducts = totalProducts > 0

  useEffect(() => {

    if (containerWidth > 1130 && containerWidth < 1280) {

      limit = 20
    }
    if (containerWidth > 881 && containerWidth < 1024) {

      limit = 20
    }
    if (containerWidth > 604 && containerWidth < 880) {

      limit = 15
    }
    if (containerWidth < 604) {
      limit = 10
    }
  }, [])


  const urlParams = new URLSearchParams(window.location.search);
  let marcaParam = urlParams.get('marca');
  const precioParam = urlParams.get('precio');
  const pageParam = urlParams.get('page');
  let categoryParam = urlParams.get('categorias');

  useEffect(() => {
    setLocation(url)
  }, [url, location, setLocation])

  useEffect(() => {

    if (data.keyword !== undefined) {
      console.log('data.keyword no undefined')
      setSearch(data.keyword.toLocaleLowerCase().replace(/-/g, ' '))

      setFilters(prevState => ({
        ...prevState,
        category: translateCategory(categoryParam) || 'all',
        brand: marcaParam ? marcaParam : 'all',
        price: parseInt(precioParam) || '0'
      }))

      if (pageParam !== null) {
        setCurrentPage(parseInt(pageParam))
      }
      // if (products.length <= 0 && location.startsWith('/ofertas') && location !== '/ofertas/black-friday')
      if (products.length <= 0 && location.startsWith('/ofertas') && location !== '/ofertas/black-friday') {
        if (marcaParam === null) {
          marcaParam = 'all'
        }
        if (categoryParam === null) {
          categoryParam = 'todo'

        }
        if (isInitial.current) {

          if (marcaParam || categoryParam) {
            console.log('marcaParam', 'categoryParam')
            getsProducts({ search: data.keyword.toLocaleLowerCase().replace(/-/g, ' '), category: translateCategory(categoryParam), brand: marcaParam, price: filters.price, page: currentPage, limit: limit })
          } else {
            console.log('no marcaParam', 'no categoryParam')
            getsProducts({ search: data.keyword.toLocaleLowerCase().replace(/-/g, ' '), category: filters.category, brand: filters.brand, price: filters.price, page: currentPage, limit: limit })
          }
        }
      }

      if (products.length <= 0 && location === '/ofertas/black-friday') {
        console.log('black friday')
        getsProducts({ search: "", category: filters.category, brand: marcaParam, price: filters.price, page: currentPage, limit: limit })
      }
      getBrandFromSearch({ search: data.keyword, category: filters.category, price: filters.price })
      getsCategoryFromSearch({ search: data.keyword, category: filters.category, price: filters.price })

    }

    if (data.category !== undefined) {
      console.log('data.category no undefined')

      setFilters(prevState => ({
        ...prevState,
        category: translateCategory(data.category),
        brand: marcaParam || 'all',
        price: parseInt(precioParam) || '0'

      }))
      if (pageParam !== null) {
        setCurrentPage(parseInt(pageParam))
      }
      console.log(data.category !== null)
      if (data.category !== null) {

        if (products.length === 0 && !fetchProducts) {
          if (marcaParam !== null || precioParam !== null) {
            console.log('marcaParam', 'precioParam')
            console.log(search, translateCategory(data.category), marcaParam, precioParam)
            getsProductsByCategory({
              search: search, category: translateCategory(data.category), brand: marcaParam, price: parseInt(precioParam), page: currentPage, limit: limit
            })

          } else {
            console.log('no marcaParam', 'no precioParam')
            getsProductsByCategory({
              search: search, category: translateCategory(data.category), brand: filters.brand, price: filters.price, page: currentPage, limit: limit
            })
          }
        }
      }

      getBrandFromSearch({ search: search, category: translateCategory(data.category), price: filters.price })

      scrollTop()
    }


  }, [])


  useEffect(() => {

    if (isInitial.current) {
      isInitial.current = false
      return
    }
    if (search !== '') {

      if (isInitial.current) {
        getBrandFromSearch({ search: search, category: filters.category, price: filters.price })
      }
    }

    if (filters.category !== prevFilters.category) {

      if (!isInitial.current) {

        getBrandFromSearch({ search: search, category: filters.category, price: filters.price })

      }

    }

  }, [search, filters])


  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false
      return
    }

    if (products.length > 0 && currentPage > 1) {
      console.log('products.length > 0')
      getsProducts({ search: search, category: filters.category, brand: filters.brand, price: filters.price, page: currentPage, limit: limit })
      scrollTop()
    }
  }, [currentPage])



  const busqueda = data.keyword ? `${total} Ofertas de ${data.keyword.replace(/-/g, ' ')}`
    : (error
      ? ""
      : `${total} ofertas de ${translateCategorySpanish(filters.category).replace(/-/g, ' ')}`)

  const categorias = data.category ? (data.category) : categoryParam


  const getNextUpdateDate = () => {
    const today = new Date()
    let nextUpdateDate = new Date(today)

    // Determinar el próximo día de actualización
    switch (today.getDay()) {
      case 1: // Lunes
        nextUpdateDate.setDate(today.getDate() + 2) // Miércoles
        break;
      case 3: // Miércoles
        nextUpdateDate.setDate(today.getDate() + 4) // Sábado
        break;
      case 6: // Sábado
        nextUpdateDate.setDate(today.getDate() + 2) // Lunes
        break;
      default:
        // Si no es un día de actualización, ir al próximo lunes
        const daysUntilMonday = (1 - today.getDay() + 7) % 7
        nextUpdateDate.setDate(today.getDate() + daysUntilMonday)
    }
    return nextUpdateDate.toISOString().split('T')[0]
  }

  const processedProducts = products.map((product) => {
    const precios = [product.price, product.priceSearch, product.priceSaving, product.priceBase, product.priceRrp, product.priceNow, product.priceOld].filter(value => Number.isFinite(value));
    const priceMax = precios.length > 0 ? Math.max(...precios) : null;
    const priceMin = precios.length > 0 ? Math.min(...precios) : null;
    return {
      ...product,
      priceMin,
      priceMax,
      priceValidUntil: getNextUpdateDate(),
    }
  })


  const jsonLd = hasProducts ? {
    "@context": "http://schema.org/",
    "@type": "ItemList",
    "itemListElement": processedProducts.map((product, index) => ({

      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "Product",
        "name": product.title,
        "image": product.imageUrl,
        "description": product.description,
        "brand": {
          "@type": "Brand",
          "name": product.brand
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "EUR",
          "price": product.priceMin !== null ? product.priceMin : (product.priceMax !== null ? product.priceMax : "N/A"),
          "priceValidUntil": product.priceValidUntil,
          "availability": "https://schema.org/InStock"
        }
      }
    }))

  } : null

  const today = new Date();
  const monthIndex = today.getMonth();
  const year = today.getFullYear()
  const months = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ]
  const monthName = months[monthIndex]

  return (
    <>
      <HelmetProvider>
        <Filters />
        {
          (!hasProducts && loading && !error) &&
          <>
            <Helmet>
              <title>Cargando ofertas...</title> && <meta name='description' content='Cargando Ofertas' />
            </Helmet>
            <Spinner title={'Cargando Ofertas...'} />
          </>

        }

        {/* hasProducts && loading && !error &&              
              <Helmet>
                <title>Cargando ofertas...</title> && <meta name='description' content='Cargando Ofertas' />
              </Helmet>
              */}

        {(!hasProducts && error) &&
          <>
            <Helmet>
              <title>Precios Rebajados || Ofertas y descuentos || No se encontraron ofertas</title>
              &&
              <meta name='description' content='No se encontraron ofertas' />
            </Helmet>
            <NoResultsFound />
          </>
        }
        {
          //error && <>{error}</>
        }
        {
          //(!hasProducts && !loading && !error) ? <> <NoResultsFound /> <p>probando</p></> : null

        }

        {
          //(hasProducts && !loading && error) ? <><NoResultsFound /> <p>asdasd</p> <h4>Te proponemos estos productos:</h4></> : null
        }


        {(hasProducts && loading && !error) &&
          <Helmet>
            <title>Cargando ofertas...</title> && <meta name='description' content='Cargando Ofertas' />
          </Helmet> &&
          <Spinner title={'Cargando Productos...'} />
        }

        {hasProducts && !loading &&
          (<>
            <Helmet>
              {// ➜ ${monthName} ${year}
              }
              <title>{search ? `Ofertas en ${search} ➜ ${monthName} ${year} || Precios Rebajados` : data.category ? `Ofertas exclusivas en ${data.category} || Precios Rebajados` : `${categoryParam}`}</title>
              <meta name='description' content={search ? `Compra ${search} con el mejor precio. Aprovecha Descuentos Increíbles y Ofertas Exclusivas. ¡No te Pierdas las Rebajas! || Precios Rebajados` : data.category ? `Compra ${data.category} en Oferta. Compra al mejor precio y disfruta de nuestras ofertas. Descuentos de las mejores marcas y tiendas || Precios Rebajados` : `Compra ${categoryParam} en Oferta. Compra al mejor precio y disfruta de nuestras ofertas. Descuentos de las mejores marcas y tiendas || Precios Rebajados`} />
              <meta name='keywords' content={search ? `${search}, ofertas, descuentos, precios rebajados` : `ofertas, descuentos, precios rebajados`} />
              <meta name="robots" content="index, follow"></meta>
              <script type="application/ld+json">
                {JSON.stringify(jsonLd)}
              </script>
              <meta property="og:title" content={`Explora ${search ? search : 'nuestros productos'} - Ofertas y Descuentos`} />
              <meta property="og:description" content={`Encuentra los mejores productos en oferta ${search ? search : 'en nuestra tienda'}. Aprovecha descuentos exclusivos.`} />
              <meta property="og:image" content="https://preciosrebajados.com/api/images/portatiles.webp" />
              <meta property="og:url" content={`https://preciosrebajados.com/ofertas/${search ? search : ''}`} />
              <meta property="og:type" content="website" />
              <link rel="canonical" href={data.category ? `https://preciosrebajados.com/categorias` : search ? `https://www.preciosrebajados.com/ofertas/${search}` : ''} />
            </Helmet>
            {products.length === 0
              ? <NoResultsFound />
              : (<ListOfProductCardAwin products={products} title={`Ofertas totales: ${totalProducts} ofertas`} busqueda={busqueda} />
              )
            }
            {products.length > 0
              ? <div className='listofproducts-buttons'>
                <a className={`listofproducts-arroyleft ${currentPage === 1 ? 'desactive' : ''} `} onClick={handleArroyLeft} >
                  <ArroyLeft />
                </a>
                <div>
                  {currentPage} de {Math.ceil(total / limit)}
                </div>
                <a className={`listofproducts-arroyright ${parseInt(currentPage) === Math.ceil(total / limit) ? 'desactive' : ''}`} onClick={handleArroyRight}>
                  <ArroyRight />
                </a>
              </div>
              : null}
          </>)
        }
      </HelmetProvider>
    </>
  )
}