import { BlogEditor } from '../../components/Blog/BlogEditor'

export function newBlog() {
    return (
        <div>
            <BlogEditor />
        </div>
    )
}



