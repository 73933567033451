import { useEffect, useRef } from "react";
import { ProductCard } from "../Products/ProductCard";
import { useProduct } from "../../hooks/useProduct";
import { useSearch } from "../../hooks/useSearch";
import { useBlog } from "../../hooks/useBlog";
import { Spinner } from "../Spinner/Spinner";

export function ArticleBlog({
    id, title, description, tags,
    image, category, author, date,
    content = [], altImg }) {

    const { resetProductsBlog, productsBlog, loadingBlogProducts, getsProductsBlog } = useProduct()

    const { blogs, errorBlogs } = useBlog();
    const { searchBlog, setSearchBlog } = useSearch()
    const isInitial = useRef(true)

    console.log(errorBlogs)

    useEffect(() => {
        //const foundBlog = listBlogs.find(blog => blog.slug === slug)

        content
            .filter(section => section.productCard !== undefined)
            .forEach((item) => {
                {  // Verifica que item.content no sea undefined ni null

                    const productCard = item.productCard;

                    const foundProduct = productsBlog.find(products => products.blogSearch === productCard);

                    if (!foundProduct) {

                        getsProductsBlog({ search: productCard, page: 1, limit: 1, blogSearch: productCard })
                    }

                }
            })


    }, [content])



    const getSimilarityPercentage = (text1, text2) => {
        const words1 = text1.toLowerCase().replace(/[^a-záéíóúñ\s]/gi, '').split(" ")
        const words2 = text2.toLowerCase().replace(/[^a-záéíóúñ\s]/gi, '').split(" ")

        const matches = words1.filter(word => words2.includes(word)).length
        const shortestLength = Math.min(words1.length, words2.length)

        // Calcula el porcentaje de coincidencia
        return (matches / shortestLength) * 100
    }

    author = author ? author : "Jorge"

    return (
        <div className="container-article-blog" key={id}>
            <section className="article-blog">
                <article>
                    <header>
                        <h1 className="article-blog-title">{title}</h1>
                        <div className="article-description" dangerouslySetInnerHTML={{ __html: description }} />
                    </header>
                    <div className="inline">
                        <div className="category-blog-content">
                            <strong>Categoria:</strong>
                            <span className="category-blog-category">{category}</span>
                        </div>
                        <div className="author-date">
                            <span className="author-name">{author}</span>.<span className="date-blog">{new Date(date).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                        </div>
                    </div>
                    <div className="article-image">
                        <img src={image} alt={altImg} loading="lazy" />
                    </div>
                    <div className="article-content">
                        {content && content.map((section, index) => {

                            let matchingProduct = null
                            if (section.productCard) {
                                matchingProduct = productsBlog.find(product =>
                                    getSimilarityPercentage(product.blogSearch, section.productCard) >= 80)
                            }




                            return (
                                <div key={index} className="content-section">

                                    <h2>{section.section}</h2>
                                    <div className="text" dangerouslySetInnerHTML={{ __html: section.text }} />

                                    {section.showProduct ?
                                        <>
                                            < div className="card-product-blog-url">

                                                {
                                                    section.urlImage ?
                                                        <div className="card-product-blog-img-img">
                                                            <img src={section.urlImage} alt={section.section} loading="lazy" />
                                                        </div>
                                                        : null
                                                }


                                                {section.showProductUrl ?
                                                    <div>
                                                        <button onClick={() => window.open(section.productUrl, '_blank')}>Ir a la oferta</button>
                                                        <button onClick={() => window.open(`https://preciosrebajados.com/ofertas/${section.section}`, '_blank')}>Buscar productos similares</button>
                                                    </div>
                                                    : null
                                                }
                                            </div>

                                            {
                                                section.showProductCard ?

                                                    <div className="product-card-container">
                                                        {
                                                            loadingBlogProducts
                                                                ? <Spinner />
                                                                : matchingProduct && (
                                                                    <ProductCard
                                                                        id={matchingProduct.id}
                                                                        merchant={matchingProduct.merchant}
                                                                        linkToProduct={matchingProduct.linkToProduct}
                                                                        title={matchingProduct.title}
                                                                        price={matchingProduct.price}
                                                                        priceNow={matchingProduct.priceNow}
                                                                        priceSearch={matchingProduct.priceSearch}
                                                                        priceSaving={matchingProduct.priceSaving}
                                                                        priceBase={matchingProduct.priceBase}
                                                                        priceRrp={matchingProduct.priceRrp}
                                                                        priceOld={matchingProduct.priceOld}
                                                                        imageUrl={matchingProduct.imageUrl}
                                                                        description={matchingProduct.description}
                                                                        category={matchingProduct.category}
                                                                        images={matchingProduct.images}
                                                                        brand={matchingProduct.brand}
                                                                        rating={matchingProduct.rating}
                                                                    />)

                                                        }
                                                        {/*
                                                                <ProductCard
                                                                    id={productsBlog[index].id}
                                                                    merchant={productsBlog[index].merchant}
                                                                    linkToProduct={productsBlog[index].linkToProduct}
                                                                    title={productsBlog[index].title}
                                                                    price={productsBlog[index].price}
                                                                    priceNow={productsBlog[index].priceNow}
                                                                    priceSearch={productsBlog[index].priceSearch}
                                                                    priceSaving={productsBlog[index].priceSaving}
                                                                    priceBase={productsBlog[index].priceBase}
                                                                    priceRrp={productsBlog[index].priceRrp}
                                                                    priceOld={productsBlog[index].priceOld}
                                                                    imageUrl={productsBlog[index].imageUrl}
                                                                    description={productsBlog[index].description}
                                                                    category={productsBlog[index].category}
                                                                    images={productsBlog[index].images}
                                                                    brand={productsBlog[index].brand}
                                                                    rating={productsBlog[index].rating}
                                                                />*/
                                                        }
                                                    </div>

                                                    : null
                                            }
                                        </>
                                        : null}
                                    {/*
                                        matchingProduct &&
                                        (
                                            <div className="product-card-container">
                                                <ProductCard
                                                    id={matchingProduct.id}
                                                    merchant={matchingProduct.merchant}
                                                    linkToProduct={matchingProduct.linkToProduct}
                                                    title={matchingProduct.title}
                                                    price={matchingProduct.price}
                                                    priceNow={matchingProduct.priceNow}
                                                    priceSearch={matchingProduct.priceSearch}
                                                    priceSaving={matchingProduct.priceSaving}
                                                    priceBase={matchingProduct.priceBase}
                                                    priceRrp={matchingProduct.priceRrp}
                                                    priceOld={matchingProduct.priceOld}
                                                    imageUrl={matchingProduct.imageUrl}
                                                    description={matchingProduct.description}
                                                    category={matchingProduct.category}
                                                    images={matchingProduct.images}
                                                    brand={matchingProduct.brand}
                                                    rating={matchingProduct.rating}
                                                />
                                            </div>
                                        )*/
                                    }

                                </div>

                            )

                        })}
                    </div>
                    <footer className="article-footer">
                        {tags && (
                            <div className="tags">
                                <h3>Etiquetas:</h3>
                                {tags.map((tag, index) => (
                                    <span key={index} className="tag">{tag}</span>
                                ))}
                            </div>
                        )}
                    </footer>
                </article >
            </section >
        </div >
    )
}
