import { useContext, useCallback, useEffect,useRef } from 'react'
import { useLocation,useParams } from 'wouter'
import { FiltersContext } from '../context/filterscontext.jsx'
import { useGoTop } from './useGoTop.js'
import { useProduct } from './useProduct.js'
import { usePagination } from './usePagination.js'
import { translateCategory,translateCategorySpanish } from '../services/translateCategory.js'
import { useSearch } from './useSearch.js'

import { useCategory } from './useCategory.js'



export function useFilter () {
  const data = useParams()
  const isInitial = useRef(true)
  const {setFilters, filters, filterActive, setFilterActive,prevFilters,
    setPrevFilters } = useContext(FiltersContext)
  const [location, setLocation] = useLocation()
  const { scrollTop } = useGoTop()
  const {currentPage,setCurrentPage} = usePagination()    
  const { getsProductsByCategory, getsProducts} = useProduct()   
  const { search,setSearch } = useSearch()
  
  const {clearCategoryProduct } = useCategory()
  //console.log('filters',filters)
  const limit = 25

  const toslug = (title) => {
            return title.toLowerCase()
                .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
                .replace(/[éèëê]/g, 'e')
                .replace(/[íìïî]/g, 'i')
                .replace(/[óòöô]/g, 'o')
                .replace(/[úùüû]/g, 'u')
                .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
                .replace(/[Ñ]/g, 'n')     // Reemplazar Ñ por N
                .replace(/[^\w\s-]/g, '')
                .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
                .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
                .replace(/^-+|-+$/g, '')
        }
  


  const handleCategoryClick = useCallback((e) => {
    e.preventDefault()
    

    const value = e.currentTarget.getAttribute('value') 
    const valueUrl = e.currentTarget.getAttribute('valueurl') 
    
    if (value) {
      //const slug = toslug(translateCategorySpanish(value));      
             
      clearCategoryProduct()
      setCurrentPage(1)      
      setSearch('')           
      setFilters(prevState => ({ ...prevState, category: value, brand: 'all', price: 0 }))
              
      setLocation('/categorias/'+valueUrl);
    
     getsProductsByCategory({ category : value ,brand:filters.brand, price:filters.price, page: currentPage })     
      scrollTop()
    }   
    
  }, [filters])

  const handleCategory = useCallback((e) => {
    e.preventDefault();
   
    const valueUrl = e.target.getAttribute('valueurl');
    const value = e.target.value;    
   
    if (value !== null ) {       
      
      setCurrentPage(1);
      if (data.keyword !== undefined) {
        setFilters(prevState => ({
          ...prevState,
          category:  value ,
          brand: "all" ,
          price: filters.price 
        }))

        getsProducts({
          search,
          category: value,
          brand: filters.brand ,
          price: filters.price,
          page: 1,
          limit
          
        }) 
      }else{
        setFilters(prevState => ({
          ...prevState,
          category:  value ,
          brand: "all" ,
          price: filters.price 
        }))
        
        getsProductsByCategory({ category: value,
           brand: filters.brand,
            price: filters.price,
            page: 1 })

          }
      
      clearCategoryProduct()     
      scrollTop();
    }
    
  }, [filters, search,  getsProducts, setFilters, currentPage, setLocation, setSearch])

  
  
  const handlePrice = useCallback((e) => {
    e.preventDefault()
    const value = e.target.value
    const valueurl = e.target.getAttribute('valueurl')    
    const slug = toslug(translateCategorySpanish(filters.category));

    setFilters(prevState => ({ ...prevState, 
      price: value, 
    }))
    //console.log('entro en handlePrice')     
    setCurrentPage(1)
    getsProducts({ search:search, category: filters.category, brand: filters.brand, price: value, page: currentPage ,limit: limit })
    
    
    scrollTop()
  }, [filters, setFilters, search,setSearch,currentPage])

  const handleBrand = useCallback((e) => {
    e.preventDefault()
    // const valueurl = e.target.getAttribute('valueurl')
    const value = e.target.value   
    const slug = toslug(translateCategorySpanish(filters.category))
    
    setFilters(prevState => ({ ...prevState, brand: value }))    
    
    setCurrentPage(1)
    getsProducts({ search:search, category: filters.category, brand: value, price: filters.price, page: currentPage ,limit: limit })
    
    
    scrollTop()
  }, [filters, setFilters, search,setSearch,currentPage])

  const clearFilters = useCallback(() => {     

    const value = 'all'
    setFilters(
      prevState => ({
        ...prevState,
        category: value,
        price: 0,
        brand: value,
        merchant: value,
        merchantId: 0,
        type: value       

      })
      
    )   
    
    setCurrentPage(1)
    
    scrollTop()
    
  }, [filters])

  
     

  useEffect(() => {
    if (filters.brand !== 'all' || filters.category !== 'all' || filters.price !== 0) {
      setFilterActive(true)
    } else {
      setFilterActive(false)
    }  
    
  }, [filters, setFilterActive])  

  

  useEffect(() => {
    if (filters.brand !== prevFilters.brand || filters.category !== prevFilters.category || filters.price !== prevFilters.price) {
      // Guarda el estado actual de los filtros antes de actualizar prevFilters
      setPrevFilters(filters);
    }
  }, [filters, prevFilters, setPrevFilters]);

  

  
 
  return {prevFilters, setPrevFilters,
     filterActive, filters, location, 
     clearFilters, handleCategoryClick,
      handleCategory, handleBrand, handlePrice,
       setFilters }
}