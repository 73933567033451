import { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useParams, useLocation } from 'wouter'

import { useBlog } from '../../hooks/useBlog'
import { useGoTop } from '../../hooks/useGoTop'

import { ListofBlogs } from '../../components/Blog/ListofBlogs'
import { LastBlog } from '../../components/Blog/LastBlog'
import { NavBlog } from '../../components/Blog/NavBlog'
import { Rrss } from '../../components/RRSS/Rrss'
import { Spinner } from '../../components/Spinner/Spinner'

export function Blog() {
    const { listBlogs, getsArticles, loadingBlogs } = useBlog()
    const { scrollTop } = useGoTop()
    const url = useLocation()
    const data = useParams()

    useEffect(() => {
        getsArticles()
        scrollTop()
    }, [])

    const sortedBlogs = listBlogs.sort((a, b) => new Date(b.date) - new Date(a.date));
    const lastArticle = sortedBlogs[0]

    return (
        <HelmetProvider>
            <Helmet>
                <title>Mundo Precios Rebajados || El blog especialista en noticias, reviews, guias, tutoriales, ofertas y
                    comparativas en Tecnología.
                </title>
                <meta name="robots" content="index, follow"></meta>
                <meta name="keywords" content="blog, noticias, reviews, guias, tutoriales, ofertas, comparativas, tecnología" />
                <meta name="description" content="Descubre los mejores consejos para ahorrar, guías de compra y las ofertas más recientes en Precios Rebajados. Encuentra cupones, descuentos y recomendaciones para tus compras inteligentes." />
            </Helmet>
            <div className='container-blog'>
                <NavBlog />
                <div className='content-blog'>
                    <div>
                        {
                            lastArticle && !loadingBlogs ?
                                <LastBlog
                                    author={'Jorge'}
                                    id={lastArticle.id}
                                    tags={lastArticle.tags}
                                    description={lastArticle.description}
                                    title={lastArticle.title}
                                    date={lastArticle.date}
                                    slug={lastArticle.slug}
                                    category={lastArticle.category}
                                    image={lastArticle.image}
                                />
                                : <Spinner />
                        }
                        {
                            loadingBlogs ?
                                <div className='spinner-container'><Spinner /></div> :
                                (listBlogs.length === 0
                                    ? <h3>No hay artículos</h3>
                                    : <ListofBlogs listBlogs={listBlogs} />)
                        }
                    </div>
                    <div className='publi'>
                        <div className='publi1'>
                            <div className='content-publi'>
                            </div>
                            Disfruta de lo nuevo en tecnología, noticias, reviews, guías, tutoriales, ofertas y comparativas.
                        </div>
                        <div className='publi2'>
                            <Rrss />
                        </div>
                        <div className='publi2'>

                        </div>


                    </div>
                </div>
            </div>
        </HelmetProvider >
    )
}
