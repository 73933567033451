import { useState, useRef, useEffect, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createBlog, getFetchBlogList, updateBlog, deleteBlog } from '../../services/getFetchBlog';
import { useBlog } from '../../hooks/useBlog';

export function BlogEditor({ onSave, initialData }) {
    const { listBlogs, getsArticles } = useBlog()

    const [selectedBlog, setSelectedBlog] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [title, setTitle] = useState(initialData?.title || '');
    const [slug, setSlug] = useState(initialData?.slug || '');
    const [description, setDescription] = useState(initialData?.description || '');
    const [category, setCategory] = useState(initialData?.category || '');
    const [imageUrl, setImageUrl] = useState(initialData?.image || '');
    const [imageFile, setImageFile] = useState(null);
    const [tags, setTags] = useState(initialData?.tags || []);
    const [keySearch, setkeySearch] = useState(initialData?.keySearch || '');
    const [newTag, setNewTag] = useState('');
    const [sections, setSections] = useState(initialData?.content || []);
    const [inputPassword, setInputPassword] = useState('')
    const [message, setMessage] = useState('')
    const [productSearch, setProductSearch] = useState('')
    const editorRef = useRef(null);
    const password = 'awsdñlksjdfñlkj'


    useEffect(() => {
        if (listBlogs.length === 0) {
            getsArticles()
        }

    }, [])

    const handleSelectBlog = (e) => {
        const selectedSlug = e.target.value
        const selectedId = e.target.options[e.target.selectedIndex].dataset.id;


        const blog = listBlogs.find(b => b.slug === selectedSlug);


        if (blog) {

            if (selectedBlog?.id !== blog.id) { // Compara con el estado previo
                setMessage('Blog encontrado: ' + blog.title);
                setSelectedBlog(blog);
                setIsEditing(true);
                setTitle(blog.title);
                setSlug(blog.slug);
                setDescription(blog.description);
                setCategory(blog.category);
                setImageUrl(blog.image);
                setTags(blog.tags);
                setSections(blog.content);
                setkeySearch(blog.keySearch);
            }
        } else {
            setMessage('Blog no encontrado:' + blog);
            //console.log("Blog no encontrado:", blog)
        }
    }

    const handleAddSection = () => {
        const newSection = {
            section: '',
            text: '',
            showProduct: false,
            showProductUrl: false,
            productUrl: '',
            showProductCard: false,
            product: '',
            urlImage: ''
        };
        setSections([...sections, newSection]);
    }

    const handleUpdateSection = useCallback((index, key, value) => {
        const updatedSections = sections.map((section, i) => {
            if (i === index) {
                if (section[key] === value) {
                    return section;
                }
                return { ...section, [key]: value };
            }

            return section;
        });

        if (JSON.stringify(updatedSections) !== JSON.stringify(sections)) {
            setSections(updatedSections);
        }
    }, [sections]);

    const handleImageUpload = () => {
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImageUrl(e.target.result);
            };
            reader.readAsDataURL(imageFile);
        }
    }

    const handleCreateBlog = async (e) => {
        e.preventDefault();

        if (inputPassword !== password) {
            setMessage('Contraseña incorrecta. Inténtalo de nuevo.');
            return; // Evita que el formulario se envíe si la contraseña no coincide
        }
        const date = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD

        /*
          slug: title.toLowerCase()
                .replace(/[^\w\s-]/g, '')  // Eliminar caracteres no alfanuméricos ni espacios ni guiones
                .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
                .replace(/[éèëê]/g, 'e')
                .replace(/[íìïî]/g, 'i')
                .replace(/[óòöô]/g, 'o')
                .replace(/[úùüû]/g, 'u')
                .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
                .replace(/[Ñ]/g, 'n')      // Reemplazar Ñ por N
                .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
                .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
                .replace(/^-+|-+$/g, ''),
        
        */
        const blogData = {
            id: initialData?.id || Date.now(), // Si es una edición, tomamos el id existente
            title,
            slug,
            description,
            category,
            image: imageUrl,
            content: sections,
            date,
            tags,
            keySearch,
        }

        // Guardar como JSON en un archivo local (simulación)
        const json = JSON.stringify(blogData, null, 2);

        // Crear un Blob a partir del JSON
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        // Crear un enlace de descarga
        const a = document.createElement('a');
        a.href = url;
        a.download = `${blogData.slug}.json`; // Nombre del archivo
        document.body.appendChild(a);
        a.click(); // Simular clic en el enlace
        document.body.removeChild(a); // Limpiar el DOM

        // Liberar el objeto URL
        URL.revokeObjectURL(url);
        try {
            await createBlog(blogData);
            setMessage('Artículo creado exitosamente');
            // Puedes resetear los estados si es necesario después de crear
            resetForm();
        } catch (error) {
            console.error('Error al crear el artículo:', error);
            setMessage('Error al crear el artículo');
        }
    };

    const handleUpdateBlog = async (e) => {
        e.preventDefault();
        try {
            await updateBlog({
                id: selectedBlog.id,
                title,
                slug,
                description,
                category,
                image: imageUrl,
                tags,
                content: sections,
                keySearch,
            });
            setMessage('Artículo actualizado exitosamente');
            resetForm();
            setMessage('');
        } catch (error) {
            console.error('Error al actualizar el artículo:', error);
            setMessage('Error al actualizar el artículo');
        }
    }
    const resetForm = () => {
        setSelectedBlog(null);
        setIsEditing(false);
        setTitle('');
        setSlug('');
        setDescription('');
        setCategory('');
        setImageUrl('');
        setTags([]);
        setSections([]);
        setkeySearch('');
    }



    const handleRemoveSection = (index) => {
        const updatedSections = sections.filter((_, i) => i !== index)
        setSections(updatedSections)
    }

    const handleToggleProduct = (index) => {
        const updatedSections = sections.map((section, i) => {
            if (i === index) {
                return { ...section, showProduct: !section.showProduct }
            }
            return section;
        });
        setSections(updatedSections);
    }

    const handleToggleProductUrl = (index) => {
        const updatedSections = sections.map((section, i) => {
            if (i === index) {
                return { ...section, showProductUrl: !section.showProductUrl }
            }
            return section;
        });
        setSections(updatedSections);
    }

    const handleToggleProductCard = (index) => {
        const updatedSections = sections.map((section, i) => {
            if (i === index) {
                return { ...section, showProductCard: !section.showProductCard }
            }
            return section;
        });
        setSections(updatedSections);
    }

    const handleProductSearch = (e) => {
        setProductSearch(e.target.value);
    }



    const handleDeleteBlog = async (e) => {

        e.preventDefault();

        if (!selectedBlog) return;
        const confirmation = window.confirm("¿Estás seguro de que deseas eliminar este artículo?");
        if (!confirmation) return;

        try {

            await deleteBlog(selectedBlog.id)

            setMessage('Artículo eliminado exitosamente')
            resetForm()
            setMessage('')
        } catch (error) {
            console.error('Error al eliminar el artículo:', error)
            setMessage('Error al eliminar el artículo')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (inputPassword !== password) {
            setMessage('Contraseña incorrecta. Inténtalo de nuevo.');
            return; // Evita que el formulario se envíe si la contraseña no coincide
        }
        const date = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD


        const blogData = {
            id: initialData?.id || Date.now(), // Si es una edición, tomamos el id existente
            title,
            slug,
            description,
            category,
            image: imageUrl,
            content: sections,
            date,
            tags,
            keySearch,
        }

        // Guardar como JSON en un archivo local (simulación)
        const json = JSON.stringify(blogData, null, 2);

        // Crear un Blob a partir del JSON
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        // Crear un enlace de descarga
        const a = document.createElement('a');
        a.href = url;
        a.download = `${blogData.slug}.json`; // Nombre del archivo
        document.body.appendChild(a);
        a.click(); // Simular clic en el enlace
        document.body.removeChild(a); // Limpiar el DOM

        // Liberar el objeto URL
        URL.revokeObjectURL(url);

        // Guardar en Firebase
        try {
            if (initialData?.id) {
                // Si hay un id, llamamos a la función de actualizar (PUT)
                await updateBlog(blogData);
                setMessage('Artículo actualizado exitosamente');
                setMessage(' ');
            } else {
                // Si no hay id, creamos un nuevo artículo (POST)
                await createBlog(blogData);
                setMessage('Artículo creado exitosamente');
            }
        } catch (error) {
            console.error('Error al guardar el artículo:', error);
            setMessage('Error al guardar el artículo. Intentalo de nuevo.');
        }


    }

    return (
        <div>
            <h1>{isEditing ? 'Editar Artículo' : 'Crear Artículo'}</h1>
            <label>Seleccionar Blog:</label>

            <select onChange={handleSelectBlog} value={selectedBlog?.slug || ''}>
                <option value="">Seleccione un blog</option>
                {listBlogs.map((blog) => (
                    <option key={blog.id} value={blog.slug} data-id={blog.id}>{blog.title}</option>
                ))}
            </select>

            <button type="button" onClick={resetForm}>Crear Nuevo Blog</button>


            {
                // <form onSubmit={handleSubmit} className="blog-editor"> 
            }

            <form onSubmit={isEditing ? handleUpdateBlog : handleCreateBlog} className="blog-editor">
                {message && <p className='editblog-message'>{message} || id: {selectedBlog ? selectedBlog.id : 'No hay id'}</p>}
                <div>
                    <label>Título:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                    <label>Slug:</label>
                    <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} required />
                </div>
                <div>
                    <label>Descripción:</label>
                    <ReactQuill
                        value={description}
                        onChange={setDescription}
                        placeholder="Escribe la descripción del artículo..."
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }, { 'header': '3' }, { 'font': [] }],
                                [{ size: [] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                ['link', 'image'],
                                ['clean']
                            ]
                        }}
                    />
                </div>
                <div>
                    <label>Categoría:</label>
                    <input
                        type="text"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label>Imagen de portada:</label>
                    <input
                        type="url"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                        placeholder="URL de la imagen de portada"
                    />
                    <p>O</p>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImageFile(e.target.files[0])}
                    />
                    <button type="button" onClick={handleImageUpload}>Subir Imagen</button>
                </div>

                {/* Contenido con Editor Enriquecido */}
                <div >
                    <h3>Contenido:</h3>
                    {sections.map((section, index) => (
                        <div key={index} className="content-section-editor">
                            <label>Título de la sección:</label>
                            <input
                                type="text"
                                value={section.section}
                                onChange={(e) => handleUpdateSection(index, 'section', e.target.value)}
                                placeholder="Título de la sección"
                            />

                            <label>Texto de la sección:</label>
                            <ReactQuill
                                value={section.text}
                                onChange={(value) => handleUpdateSection(index, 'text', value)}
                                placeholder="Escribe aquí el contenido..."
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'header': '3' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ]
                                }}
                            />
                            {
                                /*                                
                                <h3>Vista HTML</h3>
                                <textarea
                                    rows="10"
                                    cols="50"
                                    value={section.text} // Muestra el HTML
                                    readOnly // Solo lectura
                                />                                
                                */
                            }
                            <div>
                                <label>Mostrar producto:</label>
                                <input
                                    type="checkbox"
                                    checked={section.showProduct}
                                    onChange={() => handleToggleProduct(index)}
                                />
                                {section.showProduct && (
                                    <div>
                                        <label>Mostrar url producto:</label>
                                        <input
                                            type="checkbox"
                                            checked={section.showProductUrl}
                                            onChange={() => handleToggleProductUrl(index)}
                                        />
                                        <label>Mostrar card producto:</label>
                                        <input
                                            type="checkbox"
                                            checked={section.showProductCard}
                                            onChange={() => handleToggleProductCard(index)}
                                        />

                                        {section.showProductUrl && (
                                            <div>
                                                <label>Buscar url:</label>
                                                <input
                                                    type="text"
                                                    value={section.productUrl}
                                                    placeholder="URL del producto"
                                                    onChange={(e) => handleUpdateSection(index, 'productUrl', e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    value={section.urlImage}
                                                    placeholder="URl de imagen de producto"
                                                    onChange={(e) => handleUpdateSection(index, 'urlImage', e.target.value)} />


                                            </div>
                                        )}
                                        {section.showProductCard && (
                                            <div>
                                                <label>Buscar producto:</label>
                                                <input
                                                    type="text"
                                                    value={section.productCard}
                                                    placeholder="Buscar producto"
                                                    onChange={(e) => handleUpdateSection(index, 'productCard', e.target.value)}
                                                />
                                            </div>
                                        )}



                                    </div>
                                )}
                                <div>
                                    <button type="button" onClick={() => handleRemoveSection(index)}>Eliminar Sección</button>
                                </div>
                            </div>


                        </div>
                    ))}
                    <button type="button" onClick={handleAddSection}>Agregar Sección</button>
                </div>

                <div>
                    <label>Etiquetas:</label>
                    <div>
                        {tags.map((tag, index) => (
                            <div key={index} className="tag">
                                {tag}
                                <button onClick={() => {
                                    const updatedTags = tags.filter((_, i) => i !== index);
                                    setTags(updatedTags);
                                }}>✖</button>
                            </div>
                        ))}
                        <input
                            type="text"
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && newTag) {
                                    setTags([...tags, newTag]);
                                    setNewTag('');
                                    e.preventDefault();
                                }
                            }}
                            placeholder="Nueva etiqueta"
                        />
                        <button type="button" onClick={() => {
                            if (newTag) {
                                setTags([...tags, newTag]);
                                setNewTag('');
                            }
                        }}>Agregar Etiqueta</button>
                    </div>
                </div>
                <div>
                    <label>key Search:</label>
                    <input
                        type="text"
                        value={keySearch}
                        onChange={(e) => setkeySearch(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={inputPassword} // Este es el valor de la contraseña ingresada por el usuario
                        onChange={(e) => setInputPassword(e.target.value)} // Actualiza el valor de la contraseña ingresada
                        required
                    />
                </div>

                <button type="submit">{isEditing ? 'Actualizar Artículo' : 'Crear Artículo'}</button>

                {isEditing && (
                    <button type='button' onClick={handleDeleteBlog}>Eliminar Artículo</button>
                )}
                {message && <p>{message}</p>}
            </form>
        </div >
    )
}
