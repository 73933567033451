import { ProductCardAwin } from './ProductCardAwin'

export function ListOfProductCardAwin({ products, title, busqueda }) {

  return (
    <>
      {
        busqueda ? <h1>{busqueda}</h1> : null
      }

      <h2>{title}</h2>
      <div className='container-listofproducts'>
        <section className='listofproducts'>
          <ul className='listofproducts-products'>
            {
              products.map((product) => {
                return (
                  <li className='container-product' key={product.id}>
                    <ProductCardAwin
                      id={product.id}
                      linkToProduct={product.linkToProduct}
                      title={product.title}
                      price={product.price}
                      priceNow={product.priceNow}
                      priceSearch={product.priceSearch}
                      priceSaving={product.priceSaving}
                      priceBase={product.priceBase}
                      priceRrp={product.priceRrp}
                      priceOld={product.priceOld}
                      imageUrl={product.imageUrl}
                      imageUrls={product.imageUrls}
                      description={product.description}
                      category={product.category}
                      brand={product.brand}
                      rating={product.rating}
                    />
                  </li>
                )
              })}
          </ul>

        </section>
      </div>
    </>

  )
}
