import { Link } from 'wouter'
import { useState } from 'react'

export function ProductCardAwin({
  id, linkToProduct,
  title, merchant,
  merchantId, price, priceSearch, priceSaving, priceBase, priceRrp, priceNow, priceOld,
  imageUrl, imageUrls, imageUrlLarge,
  imageUrl1, description,
  category, categoryId,
  brand
}) {
  const [fallback, setFallback] = useState(false);
  const precios = [price, priceSearch, priceSaving, priceBase, priceRrp, priceNow, priceOld].filter(value => Number.isFinite(value))
  const priceMax = Math.max.apply(Math, precios)
  const priceMin = Math.min.apply(Math, precios)
  const porcentaje = (((priceMin * 100) / priceMax) - 100).toFixed(2) + ' %'

  const toslug = (title) => {
    return title.toLowerCase()
      .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
      .replace(/[Ñ]/g, 'n')     // Reemplazar Ñ por N
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
      .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
      .replace(/^-+|-+$/g, '')
  }


  return (
    <section className='content-product'>
      <article className='product-article'>
        <header className='product-header'>
          <Link href={`/ofertas/${toslug(title)}/${id}`}><h3 className='product-title'>{title}</h3></Link>
        </header>
        <main>
          <div className='product-img'>
            <a>
              <img
                src={!fallback && imageUrl ? imageUrl : imageUrls}
                alt={title}
                loading="lazy"
                onError={() => setFallback(true)}
              />
            </a>
          </div>
          <div className='product-description'>
            <p>{description}</p>
          </div>
        </main>
        <footer className='product-footer'>
          <div className="product-prices">
            {priceMax === priceMin
              ? <div className="product-now">
                <span >Precio: {priceMin} €</span>
              </div>
              : <>
                <div className="product-later">
                  <span >Antes: {priceMax} €</span>
                </div>
                <div className="product-now">
                  <span >Ahora: {priceMin} €</span>
                </div>
                <div >
                  <span className="product-percentage"> {porcentaje} </span>
                </div>
              </>
            }
          </div>
          <div className="product-prices-mobile">

            {priceMax === priceMin
              ? <div className="product-now">
                <span > {priceMin} €</span>
              </div>
              : <>
                <div className="product-later">
                  <span > {priceMax} €</span>
                </div>
                <div className="product-now">
                  <span > {priceMin} €</span>
                </div>
                <div >
                  <span className="product-percentage"> {porcentaje} </span>
                </div>
              </>
            }
          </div>

          <div >
            <div className="product-moreinfo">
              <Link href={`/ofertas/${toslug(title)}/${id}`}>
                <span> Más información </span>
              </Link>
            </div>
            <div className="product-gooffer">
              <a href={linkToProduct} target="_blank" rel="noreferrer nofollow" >
                <button> Ir a la Oferta </button>
              </a>
            </div>
          </div>

        </footer>
      </article>
    </section>
  )
}
