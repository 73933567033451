export const getFetchBlog = async ({slug}) => {
  
  try {
    const baseUrl = import.meta.env.VITE_API_BLOG
    const response = await fetch(`${baseUrl}/${slug}`);
    if (!response.ok) throw new Error('No se encontró el artículo');
    const data = await response.json();
    
    return data;
  } catch (err) {
    //console.error('Error en getFetchBlog:', err);
    throw new Error('No se encontró el artículo');
    //return null;
  }
}

export const updateBlog = async ({ id, description, category, image, tags, title, content, slug, keySearch  }) => {
  
  try {
    const baseUrl = import.meta.env.VITE_API_BLOG_UPDATE; // Asegúrate de tener esta URL configurada
    const response = await fetch(`${baseUrl}/`, {
      method: 'PUT', // Cambiamos a PUT para editar
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id, description, category, image, tags, title, content, slug, keySearch })
    })
    
    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error en updateBlog:', err);
     return null;
  }
}

export const getFetchBlogList = async () => {
  try {
    const baseUrl = import.meta.env.VITE_API_BLOG
    const response = await fetch(baseUrl);
    if (!response.ok) throw new Error('No se encontraron artículos');
    const data = await response.json();
    
    return data;
  } catch (err) {
    console.error('Error en getFetchBlogList:', err);
   return null;
  }
}

export const createBlog = async ({id, description, category, image, tags, title, content, slug, keySearch }) => {
  try {
    const baseUrl = import.meta.env.VITE_API_BLOG_NEW
    const response = await fetch(`${baseUrl}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id, description, category, image, tags, title, content, slug, keySearch})
    });
   
    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error en getFetchBlog:', err);    
    return null;
  }
}

export const deleteBlog = async ( id ) => {
  console.log('idfront', id)
  try {
    const baseUrl = import.meta.env.VITE_API_BLOG_DELETE
    const response = await fetch(`${baseUrl}/${id}`, {
      method: 'DELETE'
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error en deleteBlog:', err);
    return null;
  }
}

