import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { FiltersProvider } from './context/filterscontext.jsx'
import { RouteChangeTracker } from './utils/RouteChangeTracker.js'
import { BrandProvider } from './context/brandcontext.jsx'
import { CategoryProvider } from './context/categorycontext.jsx'
import { ButtonUp } from './components/Buttons/ButtonUp.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(

        <FiltersProvider>
                <BrandProvider>
                        <CategoryProvider>
                                <RouteChangeTracker />
                                <App />
                                <ButtonUp />
                        </CategoryProvider>
                </BrandProvider>
        </FiltersProvider>

)
