/*  Section carrossel with recomended deals */
import { useEffect } from 'react'
import { ListOfProductCardAwin } from './ListOfProductCardAwin'
import { useProduct } from '../../hooks/useProduct'
import { useBlog } from '../../hooks/useBlog'

export const Recomended = () => {
  const { blogs } = useBlog()
  const { products, getsProducts } = useProduct()


  useEffect(() => {
    if (blogs.keySearch) {
      getsProducts({ search: blogs.keySearch, category: 'all', brand: 'all', price: 0, page: 1, limit: 8 })
    }
  }, [blogs.keySearch])

  return (

    <ListOfProductCardAwin products={products} title={'Te recomendamos estos productos'} />

  )
}
