import { useStorePagination } from '../store/pagination.js'


export function usePagination () {
  const setCurrentPage  = useStorePagination(state => state.setCurrentPage)
  const currentPage = useStorePagination(state => state.currentPage) 
  const currentPageCarrousel = useStorePagination(state => state.currentPageCarrousel)
  const setCurrentPageCarrousel = useStorePagination(state => state.setCurrentPageCarrousel)
  const minPage = useStorePagination(state => state.minPage)
  const currentPageRelated = useStorePagination(state => state.currentPageRelated)
  const setCurrentPageRelated = useStorePagination(state => state.setCurrentPageRelated)
  
  
  const nextPage = currentPage + 1
  const prevPage = currentPage - 1
 
  if (currentPageRelated <= 0) {
    setCurrentPageRelated(minPage)    
  }
  
  if (currentPage <= 0) {
    setCurrentPage(minPage)
  }

  
  const handleArroyRight = () => {    
      setCurrentPage(currentPage + 1)  
        
  }

  const handleArroyLeft = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleArroyRightCarrousel = () => {
    setCurrentPageCarrousel(currentPageCarrousel + 1)
  }

  const handleArroyLeftCarrousel = () => {
    setCurrentPageCarrousel(currentPageCarrousel - 1)
  }

  const handleArroyRightRelated = () => {    
      setCurrentPageRelated(currentPageRelated + 1)  
        
  }

  const handleArroyLeftRelated = () => {
    setCurrentPageRelated(currentPageRelated - 1)
  }
 

  return {
    handleArroyRight,
    handleArroyLeft,
    currentPage,
    setCurrentPage,
    minPage,
    currentPageCarrousel,
    setCurrentPageCarrousel,
    handleArroyRightCarrousel,
    handleArroyLeftCarrousel,
    handleArroyRightRelated,
    handleArroyLeftRelated,
    currentPageRelated,
    setCurrentPageRelated
  }
}