import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'wouter';
import { useBlog } from '../../hooks/useBlog';
import { useGoTop } from '../../hooks/useGoTop';
import { useSearch } from '../../hooks/useSearch';

import { ArticleBlog } from '../../components/Blog/ArticleBlog';
import { NavBlog } from '../../components/Blog/NavBlog';
import { Spinner } from '../../components/Spinner/Spinner';
//import { ListOfCarrousel } from '../../components/Carrousel/ListOfCarrousel';
import { Error } from '../Error/error'
import { Recomended } from '../../components/Products/Recomended';
//import { ProductCard } from '../../components/Products/ProductCard';

export function DetailBlog() {
    const slug = useParams();
    const { searchBlog, setSearchBlog } = useSearch()
    const { blogs, setBlogs, listBlogs, loadingBlogs, errorBlogs, errorListBlogs, getsBlogslug, getsArticles } = useBlog();
    const [, setLocation] = useLocation();

    const { scrollTop } = useGoTop()
    const [isSearchReady, setIsSearchReady] = useState(false);


    useEffect(() => {
        if (!blogs) {
            getsArticles()
        }

        const foundBlog = listBlogs.find(blog => blog.slug === slug.slug) // Buscar en la lista global de blogs

        if (foundBlog) {

            setBlogs(foundBlog)
            if (blogs.keySearch) {
                setSearchBlog(foundBlog.keySearch)
                setIsSearchReady(true)
            }

        } else {

            getsBlogslug({ slug: slug.slug })
        }
        scrollTop()
    }, [slug])

    if (errorListBlogs || blogs === null || errorBlogs) {
        setLocation('/error');

        return <Error /> // Redirigir a la página de error
    }








    return (

        <HelmetProvider>
            <Helmet>
                <title>{blogs.title}</title>
                <meta name='description' content={blogs.description} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={blogs.title} />
                <meta property="og:description" content={blogs.description} />
                <meta property="og:image" content={blogs.image} />
                <meta property="og:url" content={`https://preciosrebajados.com/${blogs.slug}`} />
                <meta property="og:site_name" content="Precios Rebajados" />
                <meta property="og:locale" content="es_ES" />

                <meta name="twitter:title" content={blogs.title} />
                <meta name="twitter:description" content={blogs.description} />
                <meta name="twitter:image" content={blogs.image} />
                <meta name="twitter:url" content={`https://preciosrebajados.com/${blogs.slug}`} />
                <meta name="twitter:site" content="@PreciosRebajado" />
            </Helmet>
            <div className='container-blog'>
                <NavBlog />

                {loadingBlogs ? <Spinner /> :

                    <ArticleBlog
                        title={blogs.title}
                        description={blogs.description}
                        category={blogs.category}
                        content={blogs.content}
                        image={blogs.image}
                        tags={blogs.tags}
                        author={blogs.author}
                        date={blogs.date}
                        altImg={blogs.title}
                    />
                }
                {
                    <Recomended />
                }
            </div>
        </HelmetProvider >

    )

}