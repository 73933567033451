import React from 'react';
import { useLocation } from 'wouter';
export function Error() {
  const [, setLocation] = useLocation();

  const handleGoHome = (e) => {
    e.preventDefault(); // Evitar el comportamiento por defecto del formulario
    setLocation('/');   // Redirigir a la página de inicio
  };
  return (
    <div className="error-container">
      <header className="error-header">
        <h1>Error 404</h1>
      </header>
      <main className="error-main">
        <div className="error-content">
          <div className="error-icon">
            <span role="img" aria-label="error">🚫</span>
          </div>
          <div className="error-message">
            <h2>¡Ups! Algo salió mal.</h2>
            <p>La página que estás buscando no se encuentra disponible.</p>
          </div>
          <div className="error-action">

            <button type="button" className="back-button" onClick={handleGoHome}>Volver al menú principal</button>

          </div>
        </div>
      </main>
    </div>
  );
}
