import { BlogCard } from "./BlogCard";
//import { useBlog } from "../../hooks/useBlog";


export function ListofBlogs({ listBlogs }) {
    //const { blogs, listBlogs } = useBlog()




    const latestBlog = listBlogs.reduce((latest, blog) => {
        return new Date(blog.date) > new Date(latest.date) ? blog : latest;
    }, listBlogs[0]);

    //const highestId = Math.max(...listBlogs.map(blog => Number(blog.id)));


    //no puedo hacer el filtro por el id ya que cuando modifico un blog no me lo cambiara el id

    return (
        <div className='container-post'>
            {
                listBlogs.length === 0 ? null :
                    <>
                        <h1>Últimos artículos</h1>
                        <div className="content-post">
                            {
                                listBlogs
                                    .filter(blog => Number(blog.id) !== Number(latestBlog.id))
                                    .map((post) => (
                                        <BlogCard
                                            key={post.id}
                                            id={post.id}
                                            tags={post.tags}
                                            description={post.description}
                                            title={post.title}
                                            date={post.date}
                                            slug={post.slug}
                                            category={post.category}
                                            image={post.image}
                                        />
                                    ))
                            }
                        </div>
                    </>
            }
        </div>
    )
}