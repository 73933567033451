import { useState, useEffect, useCallback, useId } from 'react'
import { FilterIcon } from '../../icons/icons.jsx'

import { useFilter } from '../../hooks/useFilter.js'
import { useVouncher } from '../../hooks/useVouncher'

import { Spinner } from '../Spinner/Spinner.jsx'

export function FiltersCoupon() {
    const [isModal, setIsModal] = useState(window.innerWidth <= 1180)
    const [openModal, setOpenModal] = useState(false)
    const { filters, setFilters } = useFilter()
    const [value, setValue] = useState(0)
    const { vouncher, vouncherAdvertisers, getVouncherData, loading } = useVouncher()

    const merchandtFilterId = useId()
    const typeFilterId = useId()


    const handleFilters = (e) => {
        e.preventDefault()
        if (filters.type !== "true") {
            getVouncherData({ id: filters.merchantId, type: filters.type })
        } else {
            getVouncherData({ id: filters.merchantId, exclusiveOnly: filters.type })
        }

    }
    const handleType = async (e) => {
        e.preventDefault();
        const selectedType = e.target.value;
        setFilters(prevFilters => ({ ...prevFilters, type: selectedType }));

    }
    const handleMerchant = async (e) => {
        e.preventDefault();
        const selectedMerchant = e.target.value;
        const selectedMerchantId = e.target.options[e.target.selectedIndex].getAttribute('data-key');
        setFilters(prevFilters => ({ ...prevFilters, merchant: selectedMerchant, merchantId: selectedMerchantId }))
    }

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }

    const clearFiltersVouncher = async () => {
        setFilters(prevFilters => ({
            ...prevFilters,
            brand: 'all',
            merchant: 'all',
            merchantId: 0,
            type: 'all',
            // Asegúrate de incluir todos los filtros que necesites restablecer
        }))

        await getVouncherData({ id: 0, type: 'all', exclusiveOnly: false });
    }





    const filtersActive = useCallback(() => {

        if (filters.merchant !== 'all' || filters.type !== 'all') {
            const activeFilters = [filters.merchant !== 'all' || filters.type !== 'all'].filter(Boolean).length;
            setValue(activeFilters);

        } else {
            setValue(0);
        }
    }, [filters])



    useEffect(() => {

        const handleResize = () => {
            setIsModal(window.innerWidth <= 1180); // Cambia entre modal y normal según el tamaño de la pantalla
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    useEffect(() => {
        filtersActive()
    }, [filters])



    //const vouncherData = vouncherAdvertisers ? vouncherAdvertisers : []

    return (isModal
        ? (
            <div className='filters-modal-content'>
                <div className='filters-modal'>
                    <div className='filters-modal-active'>
                        <span>
                            {value === 1 ? `${value} Filtro activo` : `${value} Filtros activos`}

                        </span>
                    </div>
                    <div className='filters-modal-icon'>
                        <a onClick={handleOpenModal}>
                            <FilterIcon />
                        </a>
                    </div>
                </div>
                {
                    openModal &&
                    <div className='filters-modal-window'>
                        <div className='filters-modal-title'>
                            <h2>Filtros</h2>
                        </div>
                        <div className='filters-modal-content'>
                            <div className='filters-modal-content-merchants'>
                                <div className='filters-modal-merchants-title'>
                                    <label htmlFor={merchandtFilterId}>Tiendas</label>
                                </div>
                                <div className='filters-modal-merchants-select'>
                                    {
                                        loading ? <Spinner /> :

                                            <select id={merchandtFilterId} value={filters.merchant} onChange={handleMerchant}>
                                                <option value='all'>Todo</option>
                                                {

                                                    vouncherAdvertisers.map((vouncher) => (
                                                        <option key={vouncher.id} data-key={vouncher.id} value={vouncher.name}>
                                                            {vouncher.name}
                                                        </option>
                                                    ))

                                                }

                                            </select>
                                    }
                                </div>

                            </div>
                            <div className='filters-modal-type'>
                                <div className='filters-modal-type-title'>
                                    <label htmlFor={typeFilterId}>Marcas</label>
                                </div>
                                <div className='filters-modal-type-select'>
                                    <div className='filters-type-select'>
                                        <select id={typeFilterId} value={filters.type} onChange={handleType}>
                                            <option value='all'>Todo</option>
                                            <option value='voucher'>Codigos Descuento</option>
                                            <option value='promotion'>Promociones</option>
                                            <option value='true'>Exclusivos</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='filters-modal-clear'>
                            <button onClick={() => setOpenModal(false)}>Cerrar</button>
                            <button onClick={clearFiltersVouncher}>Resetear</button>
                            <button onClick={handleFilters}>Aplicar</button>
                        </div>
                    </div>
                }
            </div>
        ) : (
            (
                <section className='filters-main '>
                    <div className='container-filters-main'>
                        <div className='filters-type filters-grid-row'>
                            <div className='filters-type-title'>
                                <label htmlFor={typeFilterId}>Tipo de Cúpon</label>
                            </div>
                            <div className='filters-type-select'>
                                <select id={typeFilterId} value={filters.type} onChange={handleType}>
                                    <option value='all'>Todo</option>
                                    <option value='voucher'>Codigos Descuento</option>
                                    <option value='promotion'>Promociones</option>
                                    <option value='true'>Exclusivos</option>
                                </select>
                            </div>
                        </div>
                        <div className='filters-merchants filters-grid-row'>
                            <div className='filters-merchants-title'>
                                <label htmlFor={merchandtFilterId}>Tiendas</label>
                            </div>
                            <div className='filters-merchants-select'>
                                {
                                    loading ? <Spinner /> :

                                        <select id={merchandtFilterId} value={filters.merchant} onChange={handleMerchant}>
                                            <option value='all'>Todo</option>
                                            {

                                                vouncherAdvertisers.map((vouncher) => (
                                                    <option key={vouncher.id} data-key={vouncher.id} value={vouncher.name}>
                                                        {vouncher.name}
                                                    </option>
                                                ))

                                            }

                                        </select>
                                }
                            </div>
                        </div>
                        <div className='filters-apply-reset filters-grid-row'>
                            <div className='filters-reset'>
                                <div className='filters-reset-title'>
                                    <label>Resetear filtros</label>
                                </div>
                                <div className='filters-reset-button'>
                                    <button onClick={clearFiltersVouncher}>Resetear</button>
                                </div>
                            </div>
                            <div className='filters-apply'>
                                <div className='filters-apply-title'>
                                    <label>Aplicar filtros</label>
                                </div>
                                <div>
                                    <button onClick={handleFilters}>Aplicar</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            )
        ))

}