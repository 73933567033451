import { create } from 'zustand'
// setProducts: (products) => set(prevProducts => prevProducts.concat(products)),
export const useStoreProduct = create((set, get) => {
  
  return {
    products: [],
    setProducts: (products) => set(() => ({products})),
    totalProducts: 0,
    setTotalProducts: (totalProducts) => set(() => ({ totalProducts })),
    error: null,
    setError: (error) => set(()=>({ error })),
    loading: false,
    setLoading: (loading) => set(()=>({ loading })),
    fetchProducts:false,
    setFetchProducts: (fetchProducts) => set(()=>({ fetchProducts })),
    
    totalProductsSearch: 0,
    setTotalProductsSearch: (totalProductsSearch) => set(() => ({ totalProductsSearch })),    
    
    productsCarrousel: [],
    setProductsCarrousel: (productsCarrousel) => set(() => ({productsCarrousel})),
    totalProductsCarrousel: 0,
    setTotalProductsCarrousel: (totalProductsCarrousel) => set(() => ({ totalProductsCarrousel })),
    totalProductsSearchCarrousel: 0,
    setTotalProductsSearchCarrousel: (totalProductsSearchCarrousel) => set(() => ({ totalProductsSearchCarrousel })),
    errorCarrousel: null,
    setErrorCarrousel: (error) => set(()=>({ error })),
    loadingCarrousel: false,
    setLoadingCarrousel: (loadingCarrousel) => set(()=>({ loadingCarrousel })),
    
    productsBlog: [],
    setProductsBlog: (productsBlog) => set((state) => ({productsBlog: [...state.productsBlog, ...productsBlog]})),
    resetProductsBlog: () => set(()=>({ productsBlog: [] })),
    errorBlogProducts: null,
    setErrorBlogProducts: (errorBlogProducts) => set(()=>({ errorBlogProducts })),
    loadingBlogProducts: false,
    setLoadingBlogProducts: (loadingBlogProducts) => set(()=>({ loadingBlogProducts })),

    productSingle:[],
    setProductSingle: (productSingle) => set(()=>({ productSingle })),
    errorSingleProducts: null,
    setErrorSingleProducts: (errorSingleProducts) => set(()=>({ errorSingleProducts })),
    loadingSingleProducts: false,
    setLoadingSingleProducts: (loadingSingleProducts) => set(()=>({ loadingSingleProducts })),

    productRelated:[],
    //setProductRelated: (productRelated) => set((state)=>({ productRelated: [...state.productRelated, ...productRelated] })),
    setProductRelated: (productRelated) => set(()=>({ productRelated })),
    errorRelatedProducts: null,
    setErrorRelatedProducts: (errorRelatedProducts) => set(()=>({ errorRelatedProducts })),
    loadingRelatedProducts: false,
    setLoadingRelatedProducts: (loadingRelatedProducts) => set(()=>({ loadingRelatedProducts })),
    totalProductsRelated: 0,
    setTotalProductsRelated: (totalProductsRelated) => set(()=>({ totalProductsRelated })),
    totalProductsSearchRelated: 0,
    setTotalProductsSearchRelated: (totalProductsSearchRelated) => set(()=>({ totalProductsSearchRelated })),
  }
})
/*setProducts: (products) => set((state) => ({
      products: [...state.products, ...newProducts] // Concatenar productos nuevos con los existentes
    })),*/ 