// Popup.js
import React, { useEffect, useState } from 'react';
import './Popup.css'; // Asegúrate de tener el CSS correspondiente

export function Popup({ onClose }) {
  const [randomPeople] = useState(() => Math.floor(Math.random() * (500 - 10 + 1)) + 10)
  const message = `Actualmente, ${randomPeople} personas están viendo este producto.`


  useEffect(() => {
    // Configura el temporizador para cerrar el popup después de 3 segundos (3000 ms)
    const timer = setTimeout(() => {
      onClose()
    }, 5000)

    // Limpia el temporizador si el componente se desmonta antes de que expire
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <p>{message}</p>
      </div>
    </div>
  )
}


