import { createContext, useState } from 'react'
// import { useLocation } from 'wouter'
// import { useGotop } from '../hooks/useGoTop'

// 1.createContext
export const FiltersContext = createContext()
// 2.Provider
export function FiltersProvider({ children }) {
  const [filters, setFilters] = useState({
    price: 0,
    category: '',
    brand: '',
    merchant: 'all',
    merchantId: 0,
    type: 'all'
  })
  const [errorFilter, setErrorFilter] = useState(null)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [prevFilters, setPrevFilters] = useState(filters)

  return (
    <FiltersContext.Provider value={{
      filters,
      setFilters,
      setErrorFilter,
      loadingFilter,
      setLoadingFilter,
      errorFilter,
      filterActive,
      setFilterActive,
      prevFilters,
      setPrevFilters
    }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
