
export const getsBrandFromSearch = async ({ search,category,price }) => {  
  try {
    const baseUrl = import.meta.env.VITE_API_SEARCH_BRAND
    if (!baseUrl) {
      throw new Error('API_SEARCH_BRAND is not defined')
    } 
    
    const response = await fetch(`${baseUrl}/?search=${search}&category=${category}&price=${price}`)
    const json = await response.json()
    const datas = json[0].Data

    const brand = datas?.filter(value => value.brand_name !== '')
      .filter((item, index, array) => array.findIndex(t => (t.brand_name === item.brand_name)) === index)      
      .map(data => ({
        brand_name: data.brand_name        
      }))
     
    return brand

  } catch (error) {
    throw new Error('Error en la petición: ' + error)
  }
}