export const getsDescription = async ({ title, description }) => {

    try {
        const baseUrl = import.meta.env.VITE_API_AI
        if (!baseUrl) {
            throw new Error('VITE_API_AI is not defined')
          } 
        const response = await fetch(`${baseUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ title, description })
        });
        
        return response.json();
    } catch (error) {
        throw new Error('Error en la solicitud');
    }


    
}
export const getInfo = async ({ title }) => {

    try {
        const baseUrl = import.meta.env.VITE_API_AI_INFO
        if (!baseUrl) {
            throw new Error('VITE_API_AI_INFO is not defined')
          } 
        const response = await fetch(`${baseUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ title })
        })
        const data= await response.json()
       
        return data
    } catch (error) {
        throw new Error('Error en la solicitud');
    }
}