import { useEffect } from 'react'
import { useCategory } from '../../hooks/useCategory.js'
import { useFilter } from '../../hooks/useFilter.js'
import { Category } from './Category.jsx'

export function ListOfCategory() {
  const { getCategory, getsCategory } = useCategory()
  const { handleCategoryClick } = useFilter()

  useEffect(() => {
    if (getCategory.length === 0) {
      getsCategory()
    }

  }, [])

  return (

    <ul className='listofcategory-category'>
      {

        getCategory.map((category) => (
          <li key={category.id} value={category.value} valueurl={category.valueUrl} onClick={handleCategoryClick}>

            <Category
              title={category.title}

            />
          </li>
        )).slice(0, 8)
      }
    </ul>


  )
}
