import { ListOfCategoryCard } from '../../components/Category/ListOfCategoryCard.jsx'

import { Sponsor } from '../../components/Sponsor/Sponsor.jsx'

import { Helmet, HelmetProvider } from 'react-helmet-async'


export function Home() {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Ofertas Flash, ofertones y descuentos increibles en Precios Rebajados</title>
          <meta name='description' content="Ofertas Flash, ofertones y descuentos increibles en Precios Rebajados" />
          <meta name='keywords' content="Ofertas Flash, ofertones, descuentos, Precios Rebajados" />
          <link rel='canonical' href='https://preciosrebajados.com' />
          <meta property='og:title' content='Ofertas Flash, ofertones y descuentos increibles en Precios Rebajados' />
        </Helmet>
        <ListOfCategoryCard />
        <Sponsor href={'https://www.awin1.com/cread.php?s=2863308&v=22406&q=410878&r=1476940'}
          src={'https://a1.awin1.com/ads/awin/22406/imges4-1619665065311.png'} />

        { /*
          <ListOfCarrousel products={filteredProducts} param={search} title={'Productos destacados'}/>

          <Recomended products={filteredProducts} />
          <iframe src="https://preciosrebajados.com/ofertas" width="1280" height="500" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          */
        }
      </HelmetProvider >
    </>
  )
}
