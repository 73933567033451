export function Rrss() {
    return (
        <div className='rrss'>
            <span>Síguenos</span>
            <div className="content-rss">

                <a href='https://www.instagram.com/precios__rebajados' target='_blank' rel='noreferrer nofollow'>
                    <img src='/api/images/instagram.webp' alt='instagram' loading="lazy" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61566968670859" target='_blank' rel='noreferrer nofollow'>
                    <img src="/api/images/facebook.webp" alt='facebook' loading="lazy" />
                </a>
            </div>
        </div>
    )

}