import { Link, useParams, useLocation } from 'wouter'
import { useBlog } from '../../hooks/useBlog';


export function NavBlog() {
    let { slug } = useParams();
    const data = useParams();
    const { blogs = [], getsBlogslug } = useBlog();
    const url = useLocation()

    const convertirFrase = (str) => {
        return str.charAt(0) + str.slice(1).toLowerCase();
    }

    return (
        <nav className='nav-blog'>
            <Link to="/">Home</Link> &gt;
            <Link to="/blog">Blog</Link>
            {
                (blogs && blogs.slug && url[0] !== '/blog') ?
                    <>
                        &gt;
                        <Link to={`/${blogs.slug}`}>{convertirFrase(blogs.title)}</Link>
                    </>
                    : null
            }
        </nav>
    )
}