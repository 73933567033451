import {  useCallback  } from 'react'
import { getsDescription, getInfo } from '../services/getDescription.js'

import { useStoreDescriptionAi } from '../store/descriptionAi.js'

export function useGenerateAi() {

    const descriptionText=useStoreDescriptionAi(state=>state.descriptionText)
    const setDescriptionText=useStoreDescriptionAi(state=>state.setDescriptionText)
    const loadingText=useStoreDescriptionAi(state=>state.loadingText)
    const setLoadingText=useStoreDescriptionAi(state=>state.setLoadingText)
    const error=useStoreDescriptionAi(state=>state.error)
    const setError=useStoreDescriptionAi(state=>state.setError)
    const info=useStoreDescriptionAi(state=>state.info)
    const setInfo=useStoreDescriptionAi(state=>state.setInfo)
    const loadingInfo=useStoreDescriptionAi(state=>state.loadingInfo)
    const setLoadingInfo=useStoreDescriptionAi(state=>state.setLoadingInfo)
    const errorInfo=useStoreDescriptionAi(state=>state.errorInfo)
    const setErrorInfo=useStoreDescriptionAi(state=>state.setErrorInfo)

    
    
    
    const getDescription = useCallback(async ({ title, description }) => {
        try {
          setLoadingText(true)
          setError(null)
          const data = await getsDescription({ title, description })
        
          setDescriptionText(data)
        } catch (err) {
          setError(err.message)
        } finally {
          setLoadingText(false)
        }
        
    }, []);

    const getInfos = useCallback (async ({ title }) => {
      
        try {
          setLoadingInfo(true)
          setErrorInfo(null)
          const data = await getInfo({ title })         
          setInfo(data)
        } catch (err) {
          setErrorInfo(err.message)
        } finally {
          setLoadingInfo(false)
        }
    },[])

      
   
  

  return {getDescription, descriptionText, loadingText, error, getInfos, info, loadingInfo, errorInfo };
}