import { useState } from 'react';

export function Link() {
    const [link, setLink] = useState('');



    return (
        <div className='listoflink-container'>
            <h1>Links a las mejores ofertas</h1>
            <div className='links'>
                <ul className='listoflink'>
                    <li><strong><a href='/cupones/promofarma' target='_blank' rel="noopener noreferrer">Ofertas Promofarma</a></strong></li>
                    <li><strong><a href='/cupones/Weekendesk' target='_blank' rel="noopener noreferrer">Ofertas Weekendesk</a></strong></li>
                    <li><strong><a href='/cupones/pandora' target='_blank' rel="noopener noreferrer ">Ofertas en Pandora</a></strong></li>
                    <li><strong><a href='/las-mejores-ofertas-de-black-friday-2024-para-volverse-loco' target='_blank' rel="noopener noreferrer">Ofertas Black Friday 1</a></strong></li>


                </ul>
            </div>
            <div className='listoflink-text'>
                <h2>Descubre las Mejores Ofertas del Momento</h2>
                <p>¡Bienvenido a nuestra página de ofertas exclusivas! Aquí encontrarás los mejores descuentos y promociones disponibles en el mercado, cuidadosamente seleccionados para ofrecerte grandes ahorros en tus compras.</p>
                <p>Desde productos de belleza y moda hasta escapadas de fin de semana y experiencias únicas, tenemos algo para todos los gustos y necesidades.</p>

                <p>Explora nuestras categorías destacadas y accede directamente a los enlaces de las ofertas en tiendas de confianza como Promofarma, Weekendesk y Pandora. Cada enlace te lleva directamente a las mejores promociones de cada marca, garantizándote los mejores precios y oportunidades para ahorrar en cada compra.</p>

                <p>No olvides visitar esta página regularmente, ya que actualizamos las ofertas constantemente para que siempre tengas acceso a las últimas novedades y descuentos. ¡Aprovecha y ahorra en grande con nuestras recomendaciones de ofertas!</p>
                <br />
                <h2>¿No encuentras alguna oferta?</h2>
                <p>Si no encuentras alguna oferta, puedes buscar en nuestra página de <a href='/cupones' target='_blank' rel="noopener noreferrer"><strong>cupones</strong></a> o en nuestra página de <a href='/ofertas' target='_blank' rel="noopener noreferrer"><strong>ofertas</strong></a></p>
            </div>
        </div >
    )
}