import { create } from "zustand"

export const useStoreVouncher = create((set, get) => {
  return {
    vouncher: [],
    setVouncher: (vouncher) => set(() => ({vouncher})),    
    vouncherAdvertisers: [],
    setVouncherAdvertisers: (vouncherAdvertisers) => set(() => ({vouncherAdvertisers})),
    merchand: {},
    setMerchand: (merchand) => set(() => ({merchand})),
    totalVouncher: 0,
    setTotalVouncher: (totalVouncher) => set(() => ({ totalVouncher })),
    error: null,
    setError: (error) => set(()=>({ error })),
    loading: false,
    setLoading: (loading) => set(()=>({ loading }))
  }
})