import { create } from 'zustand'

export const useStoreBlogs = create((set, get) => {
  
  return {
    blogs: [],
    setBlogs: (blogs) => set(() => ({blogs})),
    errorBlogs: null,
    setErrorBlogs: (errorBlogs) => set(()=>({ errorBlogs })),
    loadingBlogs: false,
    setLoadingBlogs: (loadingBlogs) => set(()=>({ loadingBlogs })),


    listBlogs: [],
    setlistBlogs: (listBlogs) => set(() => ({listBlogs})),
    errorListBlogs: null,
    setErrorListBlogs: (errorListBlogs) => set(()=>({ errorListBlogs })),
    loadingListBlogs: false,
    setLoadingListBlogs: (loadingListBlogs) => set(()=>({ loadingListBlogs })),
    

    }
})