import { useCallback } from 'react'
import { getsFetch, getsFetchSearchId, getsFetchProductsByCategory ,getsFetchCarrousel,getsFetchBlog, getsFetchRelated} from '../services/getFetchProducts.js'

import { useStoreProduct } from '../store/products.js'

export function useProduct () {

  const products = useStoreProduct(state => state.products)
  const setProducts = useStoreProduct(state => state.setProducts)
  const loading = useStoreProduct(state => state.loading)
  const setLoading = useStoreProduct(state => state.setLoading)
  const error = useStoreProduct(state => state.error)
  const setError = useStoreProduct(state => state.setError)
  const totalProducts = useStoreProduct(state => state.totalProducts)
  const setTotalProducts = useStoreProduct(state => state.setTotalProducts)
  const totalProductsSearch = useStoreProduct(state => state.totalProductsSearch)
  const setTotalProductsSearch = useStoreProduct(state => state.setTotalProductsSearch)
  const fetchProducts = useStoreProduct(state => state.fetchProducts)
  const setFetchProducts = useStoreProduct(state => state.setFetchProducts)
  /*Productos Carrousel*/
  const productsCarrousel = useStoreProduct(state => state.productsCarrousel)
  const setProductsCarrousel = useStoreProduct(state => state.setProductsCarrousel)
  const totalProductsCarrousel = useStoreProduct(state => state.totalProductsCarrousel)
  const setTotalProductsCarrousel = useStoreProduct(state => state.setTotalProductsCarrousel)
  const totalProductsSearchCarrousel = useStoreProduct(state => state.totalProductsSearchCarrousel)
  const setTotalProductsSearchCarrousel = useStoreProduct(state => state.setTotalProductsSearchCarrousel)
  const errorCarrousel = useStoreProduct(state => state.errorCarrousel)
  const setErrorCarrousel = useStoreProduct(state => state.setErrorCarrousel)
  const loadingCarrousel = useStoreProduct(state => state.loadingCarrousel)
  const setLoadingCarrousel = useStoreProduct(state => state.setLoadingCarrousel)
  /*Productos Blogs*/
  const productsBlog = useStoreProduct(state => state.productsBlog)
  const setProductsBlog = useStoreProduct(state => state.setProductsBlog)
  const errorBlogProducts = useStoreProduct(state => state.errorBlogProducts)
  const setErrorBlogProducts = useStoreProduct(state => state.setErrorBlogProducts)
  const loadingBlogProducts = useStoreProduct(state => state.loadingBlogProducts)
  const setLoadingBlogProducts = useStoreProduct(state => state.setLoadingBlogProducts)
  const resetProductsBlogProducts = useStoreProduct(state => state.resetProductsBlogProducts)
  /*Products Related*/  
  const productRelated = useStoreProduct(state => state.productRelated)
  const setProductRelated = useStoreProduct(state => state.setProductRelated)
  const errorRelatedProducts = useStoreProduct(state => state.errorRelatedProducts)
  const setErrorRelatedProducts = useStoreProduct(state => state.setErrorRelatedProducts)
  const loadingRelatedProducts = useStoreProduct(state => state.loadingRelatedProducts)
  const setLoadingRelatedProducts = useStoreProduct(state => state.setLoadingRelatedProducts)
  const totalProductsRelated = useStoreProduct(state => state.totalProductsRelated)
  const setTotalProductsRelated = useStoreProduct(state => state.setTotalProductsRelated)
  const totalProductsSearchRelated = useStoreProduct(state => state.totalProductsSearchRelated)
  const setTotalProductsSearchRelated = useStoreProduct(state => state.setTotalProductsSearchRelated)



  const getsProductsId = useCallback(async ({ id }) => {
    try {
      setLoading(true)
      setError(null)
      const newSearch = await getsFetchSearchId({ id })
      setProducts(newSearch)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const getsProductsByCategory = useCallback(async ({ category,brand,price, page  }) => {
   
    try {
      setFetchProducts(true)
      setLoading(true)
      setError(null)
      const newSearch = await getsFetchProductsByCategory({ category,brand,price, page })
      setProducts(newSearch.products)
      setTotalProducts(newSearch.totalProducts)
      setTotalProductsSearch(newSearch.totalProductsSearch)
    } catch (e) {
      setError(e.message)
      
    } finally {
      setLoading(false)
    }
  }, [])

  const getsProducts = useCallback(async ({ search, category, brand, price, page,limit }) => {
       if (!search) {
        search = null
       }
    
    try {
      setLoading(true)
      setError(null)
      const newSearch = await getsFetch({ search, category, brand, price, page ,limit})      
      setProducts(newSearch.products)
     
      setTotalProducts(newSearch.totalProducts)
      setTotalProductsSearch(newSearch.totalProductsSearch)
    } catch (e) {
      setError(e.message)
      
    } finally {
      setLoading(false)
    }
  }, [])

  const getsProductsCarrousel = useCallback(async ({ search, category, brand, price, page,limit }) => {
           
    try {
      setLoadingCarrousel(true)
      setErrorCarrousel(null)
      const newSearchCarrousel = await getsFetchCarrousel({ search, category, brand, price, page ,limit}) 
           
      setProductsCarrousel(newSearchCarrousel.productsCarrousel)
      setTotalProductsCarrousel(newSearchCarrousel.totalProductsCarrousel)
      setTotalProductsSearchCarrousel(newSearchCarrousel.totalProductsSearchCarrousel)
    } catch (e) {
      setErrorCarrousel(e.message)
    } finally {
      setLoadingCarrousel(false)
    }
  }, [])

  const getsProductsBlog = useCallback(async ({ search, page,limit,blogSearch }) => {
    
    try {
      setLoadingBlogProducts(true)
      setErrorBlogProducts(null)
      const newProductsBlogs = await getsFetchBlog({ search, blogSearch,category:'all', brand:'all', price:0, page ,limit})      
      setProductsBlog(newProductsBlogs.products)
      
    } catch (e) {
      setErrorBlogProducts(e.message)
    } finally {
      setLoadingBlogProducts(false)
    }
  }, [])

  const getsProductsRelated = useCallback(async ({ search, category, brand, price, page,limit }) => {
    
    try {
      setLoadingRelatedProducts(true)
      setErrorRelatedProducts(null)
      const newSearch = await getsFetchRelated({ search, category, brand, price, page ,limit})      
      setProductRelated(newSearch.products)
      setTotalProductsRelated(newSearch.totalProducts)
      setTotalProductsSearchRelated(newSearch.totalProductsSearch)
    } catch (e) {
      setErrorRelatedProducts(e.message)
      console.log(e.message)
    } finally {
      setLoadingRelatedProducts(false)
    }
  }, [])

  return { products, 
          totalProducts, 
          totalProductsSearch, 
          fetchProducts,
          getsProducts,
          getsProductsId, 
          getsProductsByCategory, 
          loading,
          error,
          productsCarrousel,
          totalProductsCarrousel,
          totalProductsSearchCarrousel,
          getsProductsCarrousel,
          loadingCarrousel,
          errorCarrousel    ,
          getsProductsBlog,
          productsBlog,
          errorBlogProducts,
          loadingBlogProducts,
          resetProductsBlogProducts,
          getsProductsRelated,
          productRelated,
          errorRelatedProducts,
          loadingRelatedProducts,
          totalProductsRelated,
          totalProductsSearchRelated
              

        }
}