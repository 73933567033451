import { create } from 'zustand'
export const useStorePagination = create((set, get) => {
  return {
    currentPage: 1,
    setCurrentPage: (currentPage) => set({ currentPage }),
    currentPageCarrousel: 1,
    setCurrentPageCarrousel: (currentPageCarrousel) => set({ currentPageCarrousel }),
    minPage: 1,
    currentPageRelated: 1,
    setCurrentPageRelated: (currentPageRelated) => set({ currentPageRelated }),
    

    }
})