import { useEffect } from 'react'
import { CategoryCard } from './CategoryCard'

import { useCategory } from '../../hooks/useCategory.js'
import { useFilter } from '../../hooks/useFilter'
import { NoResultsFound } from '../NoResultsFound/NoResultsFound.jsx'
import { Spinner } from '../Spinner/Spinner.jsx'

import { Helmet, HelmetProvider } from 'react-helmet-async'

const randonNumber = Math.floor(Math.random() * 20)
const randonNumberPLus = randonNumber + 8

export function ListOfCategoryCard () {
  const { getCategory,getsCategory, loading, error } = useCategory()
  const { handleCategoryClick } = useFilter()
  
  const urlImages= import.meta.env.VITE_API_IMAGES

  useEffect(() => {
    if (getCategory.length === 0){
    getsCategory() 
  }
  }, [])
  
  return (
   
      <HelmetProvider>
      {loading && <Spinner title={'Cargando Categorias...'}/>}
      {!loading && getCategory.length === 0 && <NoResultsFound/>}
      {!loading && getCategory.length > 0 &&
      <div className='container-category-card'>
        <Helmet>
          <title>Ofertas Flash y Descuentos Exclusivos en Precios Rebajados</title>
          <meta name='description' content="Descubre ofertas flash y descuentos exclusivos en productos de calidad. Aprovecha los mejores precios y no te pierdas nuestras increíbles promociones en Precios Rebajados." />
        </Helmet>
        <h1>Nosotros Buscamos las Mejores Ofertas, ¡Tú Solo Tienes que Disfrutarlas!</h1>
        <h2>Ofertas por Categorías</h2>
            <section className='listofcategory-card'>
              <ul className='listofcategory-category'>
                {error && <p>Algo fue mal</p>}
                  {getCategory.length === 0 && !loading && <NoResultsFound/>}
                {
                getCategory.slice(randonNumber, randonNumberPLus).map((category) => (
                  <li key={category.id} className='listofcategory-li' onClick={handleCategoryClick} value={category.value} valueurl={category.valueUrl}>
                    
                    <CategoryCard
                      value={category.value}
                      id={category.id}
                      title={category.title}
                      imageurl={`${urlImages}${category.imageUrl}`}
                      description={category.description}
                      url={category.url}
                    />
                  </li>
                ))}
              </ul>
            </section>
        </div>}
      </HelmetProvider>
    
  )
  
}
