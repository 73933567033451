import { useMemo } from 'react';
import {useParams,useLocation } from 'wouter'
import { translateCategorySpanish ,translateCategory} from '../services/translateCategory.js'
import { useFilter } from './useFilter.js';


export function useUrl( {search, currentPage,filters}) {
  //const{filters}=useFilter()
  const paramsData = useParams()  
  const [location] = useLocation();
  
  const toslug = (title) => {
            return title.toLowerCase()
                .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
                .replace(/[éèëê]/g, 'e')
                .replace(/[íìïî]/g, 'i')
                .replace(/[óòöô]/g, 'o')
                .replace(/[úùüû]/g, 'u')
                .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
                .replace(/[Ñ]/g, 'n')     // Reemplazar Ñ por N
                .replace(/[^\w\s-]/g, '')
                .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
                .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
                .replace(/^-+|-+$/g, '')
        }
        
  return useMemo(() => {
    
   const params = new URLSearchParams();

    // Identificación de las vistas
    const isCouponView = location.startsWith('/cupones');
    const isCategoryView = location.startsWith('/categorias');
    //const isSearchView = !!search;  // Vista /ofertas (cuando hay búsqueda activa)
    const isSearchView = location.startsWith('/ofertas');
    
    // Filtros para /cupones (solo merchant y type)
    if (isCouponView) {
      /*
      if (filters.merchant && filters.merchant !== 'all') {
        params.set('tienda', filters.merchant);
      }*/
      if(filters.brand && filters.brand !== 'all'){
        params.delete('marca');
      }
      if(filters.price && filters.price !== '0'){
        params.delete('precio');
      }

      if (filters.type && filters.type !== 'all') {
        params.set('tipo', filters.type);
      }
    }

    // Filtros para /ofertas (category, price, brand) - Incluye categoría
    if (isSearchView) {
      if (filters.category && filters.category !== 'all') {
        const categorySlug = toslug(translateCategorySpanish(filters.category));
        params.set('categorias', categorySlug);
      }
      
      if (filters.price && filters.price !== "0") {     
        params.set('precio', filters.price);
      }

      if (filters.brand && filters.brand !== 'all') {
        params.set('marca', filters.brand);
      }
    }

    // Filtros para /categorias (solo price y brand)
    if (isCategoryView) {
      if (filters.price && filters.price !== "0") {     
        params.set('precio', filters.price);
      }

      if (filters.brand && filters.brand !== 'all') {
        params.set('marca', filters.brand);
      }
    }

    // Manejo de la paginación (aplica a todas las vistas)
    if (currentPage > 1) {
      params.set('page', currentPage);
    } else {
      params.delete('page');
    }

    // Determinar la ruta base según la vista actual
    let basePath = '';
    if (isCouponView) {
      
       if (filters.merchant === 'all') {
         basePath = '/cupones';        
       }else if(filters.brand !== 'all'|| filters.price !== '0' || filters.category !== 'all'){
         basePath = `/cupones/${filters.merchant}`
       }
       else{
         basePath = `/cupones/${filters.merchant}`
       }
      
    } else if (isCategoryView) {
      basePath = `/categorias/${toslug(translateCategorySpanish(filters.category) )}`
    } else if (isSearchView) {
      basePath = `/ofertas/${toslug(search)}`
    }

      
    
    // Construir la URL completa
    const url = `${basePath}${params.toString() ? '?' + params.toString() : ''}`
   
    return url ;
  }, [filters, search, currentPage, paramsData, location]) // Dependencias para que se regenere la URL cuando cambien filters o search
}
