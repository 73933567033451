import { create } from "zustand"

export const useStoreDescriptionAi = create((set, get) => {
    return {
        descriptionText: '',
        setDescriptionText: (descriptionText) => set(() => ({ descriptionText })),
        loadingText: false,
        setLoadingText: (loadingText) => set(() => ({ loadingText })),
        error: null,
        setError: (error) => set(() => ({ error })),

        info: { historia: '', informacion: '' },
        setInfo: (info) => set(() => ({ info })),
        loadingInfo: false,
        setLoadingInfo: (loadingInfo) => set(() => ({ loadingInfo })),
        errorInfo: null,
        setErrorInfo: (errorInfo) => set(() => ({ errorInfo }))

    }
})
