import { useContext, useState, useCallback } from 'react'
import { getsBrandFromSearch } from '../services/getFetchBrand.js'
import { BrandContext } from '../context/brandcontext.jsx'




export function useBrand () {
  const {getBrand, setGetBrand, clearBrand} = useContext(BrandContext) 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
   

  const getBrandFromSearch = useCallback(async ({ search ,category ,price}) => {
    try {
      setLoading(true)
      setError(null)
      const brand = await getsBrandFromSearch({ search ,category ,price})         
      setGetBrand(brand)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])
 
  
  

  return { getBrand, loading, error, clearBrand ,getBrandFromSearch}
}
