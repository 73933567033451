export const getVouncher = async ({id,type,exclusiveOnly}) => {   
    try {           
                if (id === undefined || id === null) {
            id = []
        } else if(!Array.isArray(id)) {
            id = [parseInt(id)]
        }        
        
        const baseUrl = import.meta.env.VITE_API_VOUNCHER
        if (!baseUrl) {
            throw new Error('API_VOUNCHER is not defined')
        }

        const response = await fetch(`${baseUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                filters: {
                        advertiserIds: id,
                        exclusiveOnly: exclusiveOnly,
                        membership: "joined",
                        regionCodes: ["ES"],
                        status: "active",
                        type: type                        
                    },
                    pagination: {
                        page: 1,
                        pageSize: 200
                    }
            })
        })
        const json = await response.json()    
      
        return  json

    } catch (error) {
        throw new Error('Error en la petición: ' + error)
    }
}

export const getVouncherAdvertisers = async () => {
   
    try {   
                
        const baseUrl = import.meta.env.VITE_API_VOUNCHERMERCHANT
        if (!baseUrl) {
            throw new Error('API_VOUNCHER is not defined')
        }

        const response = await fetch(`${baseUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                filters: {
                        advertiserIds: [],
                        exclusiveOnly: false,
                        membership: "all",
                        regionCodes: ["ES"],
                        status: "active",
                        type: 'all'                        
                    },
                    pagination: {
                        page: 1,
                        pageSize: 200
                    }
            })
        })
        const json = await response.json()   
        
       const datas = json.data

       const merchant = datas?.filter(value => value.advertiser.name !== '')
       .filter((item, index, array) => array.findIndex(t => (t.advertiser.name === item.advertiser.name)) === index)
        .map(data => {
            const nameWithoutES = data.advertiser.name.replace(/\bES\b/g, '').trim() // Elimina "ES" en mayúsculas
            const formattedName = nameWithoutES.replace(/\s+/g, '-') // Reemplaza los espacios con guiones
            return {
                name: formattedName.replace(/-+$/, ''), // Elimina guiones al final
                id: data.advertiser.id
        }
        })
       
        return  merchant

    } catch (error) {
        throw new Error('Error en la petición: ' + error)
    }
}

