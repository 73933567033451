import { Link } from 'wouter'

export function CarrouselItem({ id, title, image, description, price, priceSearch, priceSaving, priceOld, priceBase, priceRrp, priceNow, linkToProduct }) {

  const precios = [price, priceSearch, priceSaving, priceBase, priceRrp, priceNow, priceOld].filter(value => Number.isFinite(value))
  const priceMax = Math.max.apply(Math, precios)
  const priceMin = Math.min.apply(Math, precios)
  const porcentaje = (((priceMin * 100) / priceMax) - 100).toFixed(2) + ' %'

  const toslug = (title) => {
    return title.toLowerCase()
      .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
      .replace(/[Ñ]/g, 'n')     // Reemplazar Ñ por N
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
      .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
      .replace(/^-+|-+$/g, '')
  }


  return (
    <article className="carrousel-product-content">
      <header className="carrousel-product-header">
        <Link href={`/ofertas/${toslug(title)}/${id}`}><h3 className='carrousel-title'>{title}</h3></Link>

      </header>
      <main>
        <div className="carrousel-product-img">
          <img src={image} alt={title} loading="lazy" />
        </div>
      </main>
      <footer className="carrousel-product-footer">
        <div>
          {
            priceMax === priceMin
              ? <div className="product-price-now">
                <span > Precio : {priceMin} €</span>
              </div>
              : <>
                <div className="product-price-later">
                  <span > Precio : {priceMax} €</span>
                </div>
                <div className="product-price-now">
                  <span > Ahora : {priceMin} €</span>
                </div>
              </>
          }
          <div>
            <a href={linkToProduct} target='_blank' rel="noreferrer nofollow">
              <button > Ir a la oferta </button>

            </a>
          </div>
        </div>
      </footer>
    </article >
  )
}
