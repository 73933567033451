import { Link } from "wouter"

export function BlogCard({ id, tags, description, content, title, date, slug, category, image }) {
    {
        /*
        categorias: Tablets, perfiericos,audiovisual,cables,telefonia,
        componentes,redes,ordenadores,ocioytiempolibre,electrohogar,
        consolas y videojuegos
        secciones: noticias, reviews, guias, tutoriales, ofertas, 
        comparativas         
        */

    }
    const url = `/blog/${slug}`
    const newDate = date ? new Date(date).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' }) : null
    return (

        <article key={id} className="blog-post">
            <header className="blog-header">
                <div>
                    <Link href={url}><h2 className="blog-post-title truncate2">{title}</h2></Link>
                </div>

            </header>
            <Link to={url} className="prueba">
                <main className="blog-description">
                    <div className="blog-category">
                        <span>Categoria: {category}</span>
                    </div>
                    <div className="blog-img">
                        <img src={image} alt={title} loading="lazy" />
                    </div>
                    <div className="blog-description-description truncate5">
                        <div className="article-description" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </main>
            </Link >
            <footer className="blog-footer">
                <div className="blog-footer-link">
                    <Link to={url}>Ver más...</Link>
                </div>
                <div className="blog-footer-date">
                    <p><em>{newDate}</em></p>
                </div>
            </footer>
        </article >

    )
}

