import {  useEffect,useCallback, useRef } from 'react'
import { useStoreSearch } from '../store/search.js'

import { useProduct } from './useProduct.js'
import { useLocation } from 'wouter'

export function useSearch () { 
    
  const search = useStoreSearch(state => state.search)
  const setSearch = useStoreSearch(state => state.setSearch)
  const error = useStoreSearch(state => state.error)
  const setError = useStoreSearch(state => state.setError)
  const searchBlog = useStoreSearch(state => state.searchBlog)
  const setSearchBlog = useStoreSearch(state => state.setSearchBlog)
  const searchDetail = useStoreSearch(state => state.searchDetail)
  const setSearchDetail = useStoreSearch(state => state.setSearchDetail)

  const isMounted = useRef(true)
  const { products, getsProducts }= useProduct()  
  const [location, setLocation] = useLocation()

  const toslug = (title) => {
            return title.toLowerCase()
                .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
                .replace(/[éèëê]/g, 'e')
                .replace(/[íìïî]/g, 'i')
                .replace(/[óòöô]/g, 'o')
                .replace(/[úùüû]/g, 'u')
                .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
                .replace(/[Ñ]/g, 'n')     // Reemplazar Ñ por N
                .replace(/[^\w\s-]/g, '')
                .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
                .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
                .replace(/^-+|-+$/g, '')
        }
 
    
 
  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = search === ''
      return
    }
    if (search === '' && products.length === 0) {
      setError('Ingrese un texto valido')
      return
    }
    if (String(search).match(/^\[0-9]+$/)) {
      setError('Ingrese solo letras')
      return
    }
    if (String(search).match(/^\d+$/)) {
      setError('Ingrese solo letras')
      return
    }
    if (String(search).length < 3) {
      setError('Ingrese al menos 3 caracteres')
      return
    }
    setError(null)
  }, [search])

  const handleSearch = useCallback((e) => {      
    e.preventDefault()
    if (search === '') {      
      return
    }        
   
    setLocation(`/ofertas/${toslug(search)}`)      
    getsProducts({ search: search, category: "all", brand: 'all', price: 0, page: 1, limit: 25 })
  }, [search])
  
  const handleChange = (e) => {      
      e.preventDefault()
      const newSearch = e.target.value
     setSearch(newSearch)        
  }    

  return { search, setSearch, error, setError, handleChange, handleSearch,searchBlog,setSearchBlog,searchDetail, setSearchDetail }
}