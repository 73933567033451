import { useEffect, useState, useRef } from 'react'
import { ProductCard } from '../../components/Products/ProductCard.jsx'
import { useSingleProduct } from '../../hooks/useSingleProduct.js'
import { NoProductFound } from '../../components/NoResultsFound/NoProductFound.jsx'
import { useGoTop } from '../../hooks/useGoTop.js'
import { useParams } from 'wouter'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Spinner } from '../../components/Spinner/Spinner.jsx'
import { ListOfCarrousel } from '../../components/Carrousel/ListOfCarrousel.jsx'
import { Popup } from '../../components/PopUp/PopUp.jsx'
import { useGenerateAi } from '../../hooks/useGenerateAi.js'
import { Error } from '../Error/error.jsx'
import { useLocation } from 'wouter'

export function Detail() {

  const { id } = useParams()
  const isInitial = useRef(true)
  const { productSingle, getsProductsId, loadingSingleProducts, errorSingleProducts } = useSingleProduct({ id })
  const [containerWidth, setContainerWidth] = useState(null);
  const [, setLocation] = useLocation();
  const { getDescription, loading, error, descriptionText } = useGenerateAi()
  const { scrollTop } = useGoTop()

  const [showPopup, setShowPopup] = useState(false);
  const data = useParams()


  useEffect(() => {
    if (productSingle.length === 0 || id !== productSingle.id) {
      getsProductsId({ id })
    }
    scrollTop()
  }, [id]);

  useEffect(() => {
    const fetchDescription = async () => {
      try {
        // Verifica que `productSingle` ya esté definido antes de llamar a `getDescription`
        if (productSingle !== undefined && productSingle !== null) {
          await getDescription({ title: productSingle.title, description: productSingle.description });
        }
      } catch (error) {
        console.error('Error en getDescription:', error);
      }
    }

    // Llama a la función asíncrona para obtener la descripción solo si `productSingle` está definido
    if (productSingle !== undefined && productSingle !== null) {
      if (process.env.NODE_ENV === 'production' && Math.random() < 0.1) { // 10% de las veces en producción
        fetchDescription();
      }
    }
  }, [productSingle]);

  useEffect(() => {
    setContainerWidth(window.innerWidth);
    const handleResize = () => setContainerWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {

    if (isInitial.current) {
      isInitial.current = false
      //1053
      if (containerWidth < 790) {
        setShowPopup(false)
      } else {
        setShowPopup(true)
        const timer = setTimeout(() => {
          setShowPopup(false)
        }, 4000)

        return () => clearTimeout(timer)
      }
    }

  }, [])

  const toslug = (title) => {
    return title?.toLowerCase()
      .replace(/[áàäâ]/g, 'a')   // Reemplazar vocales con acento
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .replace(/[ñ]/g, 'n')      // Reemplazar ñ por n
      .replace(/[Ñ]/g, 'n')     // Reemplazar Ñ por N
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')      // Reemplazar espacios por guiones
      .replace(/-+/g, '-')      // Reemplazar múltiples guiones por uno solo
      .replace(/^-+|-+$/g, '')
  }

  const undefinedProductOne = productSingle === undefined || productSingle === null

  function descriptionLow(descripcion) {
    // Encuentra el índice del primer punto (".")
    if (!descripcion) {
      return "";  // O devolver un mensaje como "Descripción no disponible"
    }

    // Encuentra el índice del primer punto (".")
    const primerPunto = descripcion.indexOf(".");

    // Si no hay un primer punto, devuelve la descripción completa
    if (primerPunto === -1) {
      return descripcion;
    }

    // Encuentra el índice del segundo punto (".") después del primer punto
    const segundoPunto = descripcion.indexOf(".", primerPunto + 1);

    // Si hay un segundo punto, devuelve la subcadena hasta el segundo punto (incluyéndolo)
    if (segundoPunto !== -1) {
      return descripcion.slice(0, segundoPunto + 1);
    }

    // Si no hay un segundo punto, devuelve la descripción completa hasta el primer punto
    return descripcion.slice(0, primerPunto + 1);
  }


  const precios = [productSingle?.price, productSingle?.priceSearch, productSingle?.priceSaving, productSingle?.priceBase, productSingle?.priceRrp, productSingle?.priceNow, productSingle?.priceOld].filter(value => Number.isFinite(value))
  const priceMax = Math.max.apply(Math, precios)
  const priceMin = Math.min.apply(Math, precios)


  const jsonLd = productSingle ? {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": productSingle.title,
    "image": productSingle.imageUrl,
    "description": productSingle.description,
    "brand": {
      "@type": "Brand",
      "name": productSingle.brand
    },
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": priceMin,
      "priceCurrency": "EUR"
    }

  } : null
  //availability: "https://schema.org/InStock",


  // const hasProducts = productSingle !== undefined || productSingle.length > 0
  // if (productSingle === undefined || productSingle === null) return (<NoProductFound />)
  // const porcentaje = (((productSingle.price * 100) / productSingle.priceNow) - 100).toFixed(2)
  // porcentaje={porcentaje ? porcentaje + ' %' : ''


  if (errorSingleProducts) {
    setLocation('/error');
    return (
      <>
        <Error />
        < NoProductFound productName={productSingle ? productSingle?.title : data[0]} />
      </>
    )
  }

  return (
    <HelmetProvider>
      {
        loadingSingleProducts && undefinedProductOne && (
          <Helmet>
            <title>Cargando Producto...</title> && <meta name='description' content={'Cargando producto'} />
          </Helmet>
        )
      }
      {
        loadingSingleProducts &&
        <Spinner title={'Cargando Producto...'} />
      }

      {
        !errorSingleProducts && !loadingSingleProducts && !undefinedProductOne && (
          <Helmet>
            <title>{`${productSingle.title}  ${priceMin ? `por tan solo ${priceMin}` : ''} en ${productSingle.merchant} || Precios Rebajados`}</title>
            <meta name='description' content={`¡La mejor oferta en ${productSingle.merchant}! ${descriptionLow(productSingle.description)} `} />
            <meta name='keywords' content={`${productSingle.title}`} />
            <script type="application/ld+json">
              {JSON.stringify(jsonLd)}
            </script>
            <meta name="robots" content="index, follow"></meta>
            <meta property="og:title" content={productSingle.title} />
            <meta property="og:description" content={productSingle.description} />
            <meta property="og:image" content={productSingle.imageUrl} />
            <meta property="og:url" content={`https://preciosrebajados.com/ofertas/${toslug(productSingle.title)}/${productSingle.id}}`} />
            <link rel="canonical" href={`https://preciosrebajados.com/ofertas/${toslug(productSingle.title)}/${productSingle.id}`} />
            <meta property="og:type" content="product" />
            <meta property="og:site_name" content="Precios Rebajados" />
            <meta property="og:locale" content="es_ES" />
          </Helmet>)
      }
      {
        !errorSingleProducts && !loadingSingleProducts && !undefinedProductOne &&
        (<>
          <div>
            {showPopup && <Popup onClose={() => setShowPopup(false)} />}
          </div>
          <ProductCard
            id={productSingle.id}
            merchant={productSingle.merchant}
            linkToProduct={productSingle.linkToProduct}
            title={productSingle.title}
            price={productSingle.price}
            priceNow={productSingle.priceNow}
            priceSearch={productSingle.priceSearch}
            priceSaving={productSingle.priceSaving}
            priceBase={productSingle.priceBase}
            priceRrp={productSingle.priceRrp}
            priceOld={productSingle.priceOld}
            imageUrl={productSingle.imageUrl}
            description={productSingle.description}
            category={productSingle.category}
            images={productSingle.images}
            brand={productSingle.brand}
            rating={productSingle.rating}
            descriptionAi={descriptionText}
            titleAi={productSingle.title}
            linkToDetail={`preciosrebajados.com/ofertas/${toslug(productSingle.title)}/${productSingle.id}`}
          />
        </>
        )}
      {
        productSingle.length === 0 ? <Spinner title={'Cargando Carrousel...'} /> : <ListOfCarrousel />
      }


    </HelmetProvider>

  )
}
