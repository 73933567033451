
export const getsFetchSearchId = async ({ id }) => {
  
  try {
    const baseUrl = import.meta.env.VITE_API_SEARCH
    if (!baseUrl) {
      throw new Error('API_SEARCH is not defined')
    } 
   
    const response = await fetch(`${baseUrl}/product/?id=${id}`)
    const json = await response.json()
    const datas = json

    function eliminarEs(merchant_name) {
    // Verifica si el nombre termina con " ES" y tiene más de 3 caracteres
    if (merchant_name.endsWith(" ES") && merchant_name.length > 3) {
        return merchant_name.slice(0, -3);  // Elimina los últimos 3 caracteres (" ES")
    }
    return merchant_name;  // Si no termina en " ES", devuelve el nombre original
}
    

    const data = ({
      id: datas.aw_product_id,
      linkToProduct: datas.aw_deep_link,
      title: datas.product_name,
      merchant: eliminarEs(datas.merchant_name),
      merchantId: datas.merchant_id,
      price: parseFloat(datas.store_price),//ok
      priceNow: parseFloat(datas.display_price.substring(3)), //ok
      priceSearch: parseFloat(datas.search_price), //ok
      priceSaving: parseFloat(datas.saving), //ok
      priceBase: parseFloat(datas.base_price), //ok
      priceRrp: parseFloat(datas.rrp_price), //ok
      priceOld:parseFloat(datas.product_price_old), //ok
      idBrand: datas.brand_id,
      imageUrl: datas.merchant_image_url,
      images:
      [
        datas.merchant_image_url,
        datas.large_image,
        datas.alternate_image,
        datas.alternate_image_two
      ],
      description: datas.description,
      category: datas.category_name,
      categoryId: datas.category_id,
      brand: datas.brand_name

    })
    
    return data
  } catch (error) {
    throw new Error('Error en la petición: ' + error)
  }
}

// productos con una categoria al ahcer click en una categoria
export const getsFetchProductsByCategory = async ({ category,brand,price,page }) => {  
  
  try {
    const baseUrl = import.meta.env.VITE_API_SEARCH
    if (!baseUrl) {
      throw new Error('API_SEARCH is not defined')
    }    
    const response = await fetch(`${baseUrl}/categories/?category=${category}&brand=${brand}&price=${price}&page=${page}`)
    const json = await response.json()
    const datas = json[0].Data
    
    
   
      const totalProducts = json[0].Metadata[0].total
      const totalProductsSearch = json[0].Metadata[0].totalProducts
      
  
      const products = datas?.map(data => ({
        id: data.aw_product_id ,
        linkToProduct: data.aw_deep_link,
        title: data.product_name,
        merchant: data.merchant_name,
        merchantId: data.merchant_id,
        price: parseFloat(data.store_price),
        priceNow: parseFloat(data.display_price.substring(3)),
        priceSearch: parseFloat(data.search_price),
        priceSaving: parseFloat(data.saving),
        priceBase: parseFloat(data.base_price),
        priceRrp: parseFloat(data.rrp_price),
        priceOld:parseFloat(data.product_price_old),
        idBrand: data.brand_id,
        images:
              [
                data.aw_image_url,
                data.merchant_image_url,
                data.large_image,
                data.alternate_image,
                data.alternate_image_two
              ],
        imageUrl: data.merchant_image_url,
        imageUrls: data.aw_image_url,
        imageUrl1: data.alternate_image,
        imageUrl2: data.alternate_image_two,
        description: data.description,
        category: data.category_name,
        categoryId: data.category_id,
        brand: data.brand_name
      }))
      
      return { products,totalProducts,totalProductsSearch }
    
  } catch (error) {
    
    throw new Error('Error en la petición: ' + error)
  }
}

// todos los productos con todos los filtros
export const getsFetch = async ({ search, category, brand, price, page,limit }) => {
  try {   
    const baseUrl = import.meta.env.VITE_API_SEARCH_ALL_VALUES
    if (!baseUrl) {
      throw new Error('API_SEARCH is not defined')
    } 
    const response = await fetch(`${baseUrl}/?search=${search}&category=${category}&brand=${brand}&price=${price}&page=${page}&limit=${limit}`)
    
    const json = await response.json()
    const datas = json[0].Data
    const totalProducts = json[0].Metadata[0].total
    const totalProductsSearch = json[0].Metadata[0].totalProducts
       
    const products = datas?.map(data => ({
      id: data.aw_product_id,
      linkToProduct: data.aw_deep_link,
      title: data.product_name,
      merchant: data.merchant_name,
      merchantId: data.merchant_id,
      price: parseFloat(data.store_price),
      priceNow: parseFloat(data.display_price.substring(3)),
      priceSearch: parseFloat(data.search_price),
      priceSaving: parseFloat(data.saving),
      priceBase: parseFloat(data.base_price),
      priceRrp: parseFloat(data.rrp_price),
      priceOld:parseFloat(data.product_price_old),
      idBrand: data.brand_id,
      images:
        [          
          data.aw_image_url,
          data.merchant_image_url,
          data.large_image,
          data.alternate_image,
          data.alternate_image_two
        ],
      imageUrl: data.merchant_image_url,
      imageUrls: data.aw_image_url,
      imageUrl1: data.alternate_image,
      imageUrl2: data.alternate_image_two,
      description: data.description,
      category: data.category_name,
      categoryId: data.category_id,
      brand: data.brand_name
    }))
    
    return { products, totalProducts, totalProductsSearch }
  } catch (error) {
    throw new Error('Error en la petición: ' + error)
  }
}

export const getsFetchCarrousel = async ({ search, category, brand, price, page,limit }) => {
  
  try {   
    const baseUrl = import.meta.env.VITE_API_SEARCH_ALL_VALUES
    if (!baseUrl) {
      throw new Error('API_SEARCH is not defined')
    } 
    const response = await fetch(`${baseUrl}/?search=${search}&category=${category}&brand=${brand}&price=${price}&page=${page}&limit=${limit}`)
    
    const json = await response.json()
    const datas = json[0].Data
    const totalProductsCarrousel = json[0].Metadata[0].total
    const totalProductsSearchCarrousel = json[0].Metadata[0].totalProducts
    

    const productsCarrousel = datas?.map(data => ({
      id: data.aw_product_id,
      linkToProduct: data.aw_deep_link,
      title: data.product_name,
      merchant: data.merchant_name,
      merchantId: data.merchant_id,
      price: parseFloat(data.store_price),
      priceNow: parseFloat(data.display_price.substring(3)),
      priceSearch: parseFloat(data.search_price),
      priceSaving: parseFloat(data.saving),
      priceBase: parseFloat(data.base_price),
      priceRrp: parseFloat(data.rrp_price),
      priceOld:parseFloat(data.product_price_old),
      idBrand: data.brand_id,
      images:
        [
          data.aw_image_url,
          data.merchant_image_url,
          data.large_image,
          data.alternate_image,
          data.alternate_image_two
        ],
      imageUrl: data.merchant_image_url,
      imageUrls: data.aw_image_url,
      imageUrl1: data.alternate_image,
      imageUrl2: data.alternate_image_two,
      description: data.description,
      category: data.category_name,
      categoryId: data.category_id,
      brand: data.brand_name
    }))
    return { productsCarrousel, totalProductsCarrousel, totalProductsSearchCarrousel }
  } catch (error) {
    throw new Error('Error en la petición: ' + error)
  }
}

export const getsFetchBlog = async ({ search, category, brand, price, page,limit,blogSearch }) => {
  
  try {   
    const baseUrl = import.meta.env.VITE_API_SEARCH_ALL_VALUES
    if (!baseUrl) {
      throw new Error('API_SEARCH is not defined')
    } 
    const response = await fetch(`${baseUrl}/?search=${search}&category=${category}&brand=${brand}&price=${price}&page=${page}&limit=${limit}`)
    
    const json = await response.json()
    const datas = json[0].Data
    const totalProducts = json[0].Metadata[0].total
    const totalProductsSearch = json[0].Metadata[0].totalProducts
       
    const products = datas?.map(data => ({
      id: data.aw_product_id,
      linkToProduct: data.aw_deep_link,
      title: data.product_name,
      merchant: data.merchant_name,
      merchantId: data.merchant_id,
      price: parseFloat(data.store_price),
      priceNow: parseFloat(data.display_price.substring(3)),
      priceSearch: parseFloat(data.search_price),
      priceSaving: parseFloat(data.saving),
      priceBase: parseFloat(data.base_price),
      priceRrp: parseFloat(data.rrp_price),
      priceOld:parseFloat(data.product_price_old),
      idBrand: data.brand_id,
      images:
        [
          
          data.aw_image_url,
          data.merchant_image_url,
          data.large_image,
          data.alternate_image,
          data.alternate_image_two
        ],
      imageUrl: data.merchant_image_url,
      imageUrls: data.aw_image_url,
      imageUrl1: data.alternate_image,
      imageUrl2: data.alternate_image_two,
      description: data.description,
      category: data.category_name,
      categoryId: data.category_id,
      brand: data.brand_name,
      blogSearch: blogSearch
    }))
    
    return { products, totalProducts, totalProductsSearch }
  } catch (error) {

    throw new Error('Error en la petición: ' + error)
  }
}

 export const getsFetchRelated = async ({ search, category, brand, price, page,limit }) => {
  console.log('getsFetchRelated')
  try {   
    const baseUrl = import.meta.env.VITE_API_SEARCH_ALL_VALUES
    if (!baseUrl) {
      throw new Error('API_SEARCH is not defined')
    } 
    const response = await fetch(`${baseUrl}/?search=${search}&category=${category}&brand=${brand}&price=${price}&page=${page}&limit=${limit}`)
    
    const json = await response.json()
    const datas = json[0].Data
    const totalProducts = json[0].Metadata[0].total
    const totalProductsSearch = json[0].Metadata[0].totalProducts
       
    const products = datas?.map(data => ({
      id: data.aw_product_id,
      linkToProduct: data.aw_deep_link,
      title: data.product_name,
      merchant: data.merchant_name,
      merchantId: data.merchant_id,
      price: parseFloat(data.store_price),
      priceNow: parseFloat(data.display_price.substring(3)),
      priceSearch: parseFloat(data.search_price),
      priceSaving: parseFloat(data.saving),
      priceBase: parseFloat(data.base_price),
      priceRrp: parseFloat(data.rrp_price),
      priceOld:parseFloat(data.product_price_old),
      idBrand: data.brand_id,
      images:
        [          
          data.aw_image_url,
          data.merchant_image_url,
          data.large_image,
          data.alternate_image,
          data.alternate_image_two
        ],
      imageUrl: data.merchant_image_url,
      imageUrls: data.aw_image_url,
      imageUrl1: data.alternate_image,
      imageUrl2: data.alternate_image_two,
      description: data.description,
      category: data.category_name,
      categoryId: data.category_id,
      brand: data.brand_name
    }))
    
    return { products, totalProducts, totalProductsSearch }
  } catch (error) {
    
    throw new Error('Error en la petición: ' + error)
  }
}
