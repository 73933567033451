import { useRef, useState } from 'react'
import { Copy } from '../../icons/icons';

export function Coupons({
    id,
    name,
    titleCode,
    descriptionCode,
    code,
    urlCode,
    startDate,
    endDate,
    terms,
    idimgLogo
}) {
    const inputRef = useRef(null)
    const [showTooltip, setShowTooltip] = useState(false);

    const copyToClipboard = (e) => {
        e.preventDefault()

        if (inputRef.current) {

            const inputValue = inputRef.current.placeholder
            navigator.clipboard.writeText(inputValue)
                .then(() => {
                    setShowTooltip(true);
                    inputRef.current.blur()
                    setTimeout(() => {
                        setShowTooltip(false);
                    }, 2000)
                }).catch((error) => {
                    console.error('error', error)
                })
        }
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    }

    return (
        <>
            <section className="content-vouncher-card">
                <article className="vouncher-card">
                    <div className="vouncher-card-image">
                        <img src={`https://ui.awin.com/images/upload/merchant/profile/${idimgLogo}.png`} alt="imagen del vouncher" loading="lazy" />
                    </div>
                    <div>
                        <header className="vouncher-card-title">
                            <h2>{titleCode}</h2>
                            <p>{name}</p>
                        </header>
                        <main className="vouncher-card-content">
                            <div>
                                <strong>{descriptionCode}</strong>
                                <p>Fecha de comienzo: {formatDate(startDate)}</p>
                                <p>Fecha de caducidad: {formatDate(endDate)}</p>
                                {
                                    terms ?
                                        <span><strong>Condiciones</strong>: {terms}</span>
                                        : null
                                }
                            </div>
                        </main>
                    </div>
                    <footer className="vouncher-card-buttons">
                        {
                            code ?
                                <div onClick={copyToClipboard} className="vouncher-card-buttons-code">
                                    {showTooltip && (
                                        <div className="tooltip">Copiado al portapapeles</div>
                                    )}
                                    <span> <input ref={inputRef} placeholder={code} readOnly></input><Copy /></span>


                                </div>
                                : <div onClick={copyToClipboard} className="vouncher-card-buttons-code">
                                    <span> <input ref={inputRef} placeholder={'Click canjear código'} readOnly></input></span>

                                </div>
                        }

                        <div className="vouncher-card-buttons-url">
                            <a href={urlCode} target="_blank" rel="noreferrer nofollow" >
                                <button>Canjear Código</button>
                            </a>
                        </div>
                    </footer>
                </article>
            </section>
        </>
    )
}