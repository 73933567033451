import { createContext,useState } from "react"

export const CategoryContext = createContext({
    getCategory: [],
    getCategoryProduct: [],
    setGetCategory: () => {},
    setGetCategoryProduct: () => {},
    clearCategory: () => {},
    clearCategoryProduct: () => {}
})

export function CategoryProvider ({ children }) {
    const [getCategory, setGetCategory] = useState([])
    const [getCategoryProduct, setGetCategoryProduct] = useState([])
  
  const clearCategory = () => {
    setGetCategory([])
  }
  const clearCategoryProduct = () => {
    setGetCategoryProduct([])
  }
  
  return (
    <CategoryContext.Provider value={{
        getCategory,    
        getCategoryProduct,    
        setGetCategory,
        setGetCategoryProduct,
        clearCategory,
        clearCategoryProduct
    }}
    >
      {children}
    </CategoryContext.Provider>
  )
}