import './App.css'
import './AppCss.css'
import './Footer.css'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import React, { useEffect, useState } from 'react';
import { Header } from './components/Header/Header'

import { ButtonUp } from './components/Buttons/ButtonUp'
import { Footer } from './components/Footer/Footer'
import { Contact } from './components/Contact/Contact'
import { CookiesBar } from './components/Cookies/CookiesBar'
import { NavBar } from './components/NavBar/NavBar'


import { Switch, Route, Redirect, useLocation } from 'wouter'

import { Privacy } from './pages/Legal/privacy'
import { Cookies } from './pages/Legal/cookies'
import { Term } from './pages/Legal/term'
import { Home } from './pages/Home/home'
import { Detail } from './pages/Detail/detail.jsx'
import { Search } from './pages/Search/search'
import { Error } from './pages/Error/error'
import { Coupon } from './pages/Coupon/Coupon.jsx'
import { New } from './components/New/New.jsx'
import { Blog } from './pages/Blog/blogPage.jsx'
import { newBlog } from './pages/Blog/newBlog.jsx'
import { DetailBlog } from './pages/DetailBlog/detailBlog.jsx';
import { Link } from './pages/Link/link.jsx'

import { initGA, logPageView } from './services/analytics.js'
import { BlackFriday } from './pages/BlackFriday/BlackFriday.jsx';
import { BackToSchool } from './pages/BackToSchool/BackToSchool.jsx';



function App() {
  const [location, setLocation] = useLocation();


  useEffect(() => {
    const trackingId = 'G-N4C2879R1J'
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'custom') {
      document.addEventListener('DOMContentLoaded', () => {
        initGA(trackingId)
        logPageView()
      })
    }
    /*
    if (location === '/ofertas' || location === '/ofertas/') {
      window.location.replace('/ofertas/adidas');
    }
      */
  }, []);



  return (
    <>
      <HelmetProvider>
        <Helmet>
          {
            /*
            location === '/ofertas' && (
              <meta name="robots" content="noindex, nofollow" />
            )*/
          }
        </Helmet>
        <div className='app'>
          <New />
          <Header />
          {
            (location === '/' || location === '/ofertas') &&
            < NavBar />
          }

          <main className='main-content'>
            <Switch>
              <Route path='/' component={Home} />

              <Route path='/ofertas/vuelta-al-cole' exact component={BackToSchool} />
              <Route path="/ofertas/black-friday" exact component={BlackFriday} />

              <Route path="/ofertas/:keyword?" exact component={Search} />

              <Route path='/categorias/:category?' component={Search} />
              <Route path='/marca/:brand?' component={Search} />
              <Route path='/ofertas/:title/:id' component={Detail} />

              <Route path='/blog' component={Blog} />
              <Route path='/blog/new' component={newBlog} />
              <Route path='/blog/:slug' component={DetailBlog} />
              <Route path='/:slug' component={DetailBlog} />

              <Route path='/links' component={Link} />

              <Route path='/cupones/:tienda?' component={Coupon} />

              <Route path='/legal/privacy' component={Privacy} />
              <Route path='/legal/cookies' component={Cookies} />
              <Route path='/legal/term' component={Term} />

              <Route path='/contacto' component={Contact} />
              <Route path='/:rest*' component={Error} />
              <Route path='/error' exact component={Error} />
              {
                /*
                <Route path='/ofertas/:title/:id/*' component={Error} />
                <Route path='/blog/:title?/*' component={Error} />
                <Route path='/legal/cookies/*' component={Error} />
                <Route path='/legal/privacy/*' component={Error} />
                <Route path='/links/*' component={Error} />
                */
              }




              <Redirect to="/error" />
            </Switch>
          </main>
          <Footer />
        </div>
      </HelmetProvider>
      <CookiesBar />
      {
        //<ButtonUp />
      }
    </>
  )
}

export default App
