import { useCallback } from "react";
import { getVouncher,getVouncherAdvertisers } from '../services/getFetchVouncher'
import { useStoreVouncher } from '../store/vouncher'


export function useVouncher() {
    const vouncher = useStoreVouncher(state => state.vouncher)
    const setVouncher = useStoreVouncher(state => state.setVouncher)
    const vouncherAdvertisers = useStoreVouncher(state => state.vouncherAdvertisers)
    const setVouncherAdvertisers = useStoreVouncher(state => state.setVouncherAdvertisers)
    const totalVouncher = useStoreVouncher(state => state.totalVouncher)
    const setTotalVouncher = useStoreVouncher(state => state.setTotalVouncher)
    const loading = useStoreVouncher(state => state.loading)
    const setLoading = useStoreVouncher(state => state.setLoading)
    const error = useStoreVouncher(state => state.error)
    const setError = useStoreVouncher(state => state.setError)
    const setMerchand = useStoreVouncher(state => state.setMerchand)
    const merchand = useStoreVouncher(state => state.merchand)


    const getVouncherData = useCallback(async ({id,type,exclusiveOnly}) => {
        
        try {
            setLoading(true)
            setError(null)            
            const data = await getVouncher({id,type,exclusiveOnly})                             
            setVouncher(data)            
            setTotalVouncher(data.pagination.total)
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }, [])

    const getVouncherAdvertisersData = useCallback(async () => {
        try {
            setLoading(true)
            setError(null)            
            const datas = await getVouncherAdvertisers()            
            setVouncherAdvertisers(datas)
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }, [])
    
    
   

    return { vouncher,vouncherAdvertisers,totalVouncher, loading, error, getVouncherData,getVouncherAdvertisersData }
}