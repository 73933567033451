import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useRef, useState } from 'react'
import { useParams, useLocation } from 'wouter'

import { ListOfProductCardAwin } from '../../components/Products/ListOfProductCardAwin'
import { NoResultsFound } from '../../components/NoResultsFound/NoResultsFound'
import { Filters } from '../../components/Filters/Filters'
import { Spinner } from '../../components/Spinner/Spinner.jsx'

import { usePagination } from '../../hooks/usePagination.js'
import { useProduct } from '../../hooks/useProduct'
import { useSearch } from '../../hooks/useSearch'
import { useGoTop } from '../../hooks/useGoTop'
import { useFilter } from '../../hooks/useFilter'
import { useBrand } from '../../hooks/useBrand.js'
import { translateCategory, translateCategorySpanish } from '../../services/translateCategory.js'

import { ArroyRight, ArroyLeft } from '../../icons/icons.jsx'
import { useUrl } from '../../hooks/useUrl.js'
import { getsCategoryFromSearch } from '../../services/getFetchCategory.js'

export function BlackFriday() {
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const data = useParams()
    const isInitial = useRef(true)
    const { search, setSearch } = useSearch()
    const { scrollTop } = useGoTop()
    const { filters, setFilters, prevFilters } = useFilter()
    const { handleArroyRight, handleArroyLeft, currentPage, setCurrentPage, currentPageRelated, setCurrentPageRelated, handleArroyLeftRelated, handleArroyRightRelated } = usePagination()
    const { productRelated, loadingRelatedProducts, errorRelatedProducts, totalProductsRelated, totalProductsSearchRelated, getsProductsRelated, products, loading, getsProducts, error, totalProducts, totalProductsSearch, getsProductsByCategory } = useProduct()
    const [location, setLocation] = useLocation()
    const { getBrandFromSearch } = useBrand()

    let limit = 10
    const total = search || search === '' ? totalProductsSearch : totalProducts
    const total1 = search || search === '' ? totalProductsSearchRelated : totalProductsRelated

    //const maxPage = Math.ceil(total / limit)

    const url = useUrl({ search, currentPage, filters: filters })
    const hasProducts = totalProducts > 0
    const hasProductsRelated = totalProductsRelated > 0

    useEffect(() => {
        if (containerWidth > 1130 && containerWidth < 1280) {
            limit = 10
        }
        if (containerWidth > 881 && containerWidth < 1024) {
            limit = 8
        }
        if (containerWidth > 604 && containerWidth < 880) {
            limit = 6
        }
        if (containerWidth < 604) {
            limit = 6
        }
    }, [])


    const urlParams = new URLSearchParams(window.location.search);
    let marcaParam = urlParams.get('marca');
    const precioParam = urlParams.get('precio');
    const pageParam = urlParams.get('page');
    let categoryParam = urlParams.get('categorias');
    /*
        useEffect(() => {
            setLocation(url)
        }, [url, location, setLocation])
        */


    useEffect(() => {
        if (products.length <= 0 && location.startsWith('/ofertas')) {
            if (marcaParam === null) {
                marcaParam = 'all'
            }
            if (categoryParam === null) {
                categoryParam = 'todo'

            }
            if (isInitial.current) {

                if (marcaParam || categoryParam) {
                    console.log(search, translateCategory(categoryParam), marcaParam, precioParam, currentPage, limit)
                    getsProducts({ search: 'samsung s22', category: translateCategory(categoryParam), brand: marcaParam, price: filters.price, page: currentPage, limit: limit })
                    getsProductsRelated({ search: 'Ordenadores', category: translateCategory(categoryParam), brand: marcaParam, price: filters.price, page: currentPage, limit: limit })
                } else {
                    getsProducts({ search: 'samsung s22', category: filters.category, brand: filters.brand, price: filters.price, page: currentPage, limit: limit })
                    getsProductsRelated({ search: 'Ordenadores', category: filters.category, brand: filters.brand, price: filters.price, page: currentPage, limit: limit })
                }
            }
        }
        window.scrollTo(0, -9);
        //getBrandFromSearch({ search: " ", category: filters.category, price: filters.price })
        //getsCategoryFromSearch({ search: " ", category: filters.category, price: filters.price })
    }, [])


    useEffect(() => {

        if (isInitial.current) {
            isInitial.current = false
            return
        }

    }, [search, filters])


    useEffect(() => {
        if (isInitial.current) {
            isInitial.current = false
            return
        }

        if (products.length > 0) {
            getsProducts({ search: "samsung s22", category: filters.category, brand: filters.brand, price: filters.price, page: currentPage, limit: limit })
            scrollTop()
        }

    }, [currentPage])

    useEffect(() => {
        if (isInitial.current) {
            isInitial.current = false
            return
        }
        if (productRelated.length > 0) {
            getsProductsRelated({ search: "Ordenadores", category: filters.category, brand: filters.brand, price: filters.price, page: currentPage, limit: limit })
            window.scrollTo({ top: document.documentElement.scrollHeight - 100, behavior: 'smooth' });
        }
    }, [currentPageRelated])



    const busqueda = `${total} Ofertas de Black Friday Samsung S22`
    const busqueda1 = `${total1} Ofertas de Black Friday Ordenadores`


    const categorias = data.category ? (data.category) : categoryParam


    const getNextUpdateDate = () => {
        const today = new Date()
        let nextUpdateDate = new Date(today)

        // Determinar el próximo día de actualización
        switch (today.getDay()) {
            case 1: // Lunes
                nextUpdateDate.setDate(today.getDate() + 2) // Miércoles
                break;
            case 3: // Miércoles
                nextUpdateDate.setDate(today.getDate() + 4) // Sábado
                break;
            case 6: // Sábado
                nextUpdateDate.setDate(today.getDate() + 2) // Lunes
                break;
            default:
                // Si no es un día de actualización, ir al próximo lunes
                const daysUntilMonday = (1 - today.getDay() + 7) % 7
                nextUpdateDate.setDate(today.getDate() + daysUntilMonday)
        }
        return nextUpdateDate.toISOString().split('T')[0]
    }

    const processedProducts = products.map((product) => {
        const precios = [product.price, product.priceSearch, product.priceSaving, product.priceBase, product.priceRrp, product.priceNow, product.priceOld].filter(value => Number.isFinite(value));
        const priceMax = precios.length > 0 ? Math.max(...precios) : null;
        const priceMin = precios.length > 0 ? Math.min(...precios) : null;
        return {
            ...product,
            priceMin,
            priceMax,
            priceValidUntil: getNextUpdateDate(),
        }
    })


    const jsonLd = hasProducts ? {
        "@context": "http://schema.org/",
        "@type": "ItemList",
        "itemListElement": processedProducts.map((product, index) => ({

            "@type": "ListItem",
            "position": index + 1,
            "item": {
                "@type": "Product",
                "name": product.title,
                "image": product.imageUrl,
                "description": product.description,
                "brand": {
                    "@type": "Brand",
                    "name": product.brand
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "EUR",
                    "price": product.priceMin !== null ? product.priceMin : (product.priceMax !== null ? product.priceMax : "N/A"),
                    "priceValidUntil": product.priceValidUntil,
                    "availability": "https://schema.org/InStock"
                }
            }
        }))

    } : null

    const today = new Date();
    const monthIndex = today.getMonth();
    const year = today.getFullYear()
    const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ]
    const monthName = months[monthIndex]

    return (
        <>
            <HelmetProvider>
                {
                    //<Filters />
                }
                {
                    (!hasProducts && loading && !error) &&
                    <>
                        <Helmet>
                            <title>Cargando ofertas...</title> && <meta name='description' content='Cargando Ofertas' />
                        </Helmet>
                        <Spinner title={'Cargando Ofertas...'} />
                    </>
                }
                {(!hasProducts && error) &&
                    <>
                        <Helmet>
                            <title>Precios Rebajados || Ofertas y descuentos || No se encontraron ofertas</title>
                            &&
                            <meta name='description' content='No se encontraron ofertas' />
                        </Helmet>
                        <NoResultsFound />
                    </>
                }

                {
                    // (!hasProducts && !loading && !error) && <> <NoResultsFound /> <p>probando</p></>
                }

                {
                    //(hasProducts && !loading && error) ? <><NoResultsFound /> <p>asdasd</p> <h4>Te proponemos estos productos:</h4></> : null
                }


                {(hasProducts && loading && !error) &&
                    <Helmet>
                        <title>Cargando ofertas de Black Friday...</title> && <meta name='description' content='Cargando Ofertas' />
                    </Helmet> &&
                    <Spinner title={'Cargando ofertas de Black Friday...'} />
                }
                {
                    //hasProducts && <p>hayyyyyyy</p>
                }
                {hasProducts && !loading &&
                    (<>
                        <Helmet>
                            {// ➜ ${monthName} ${year}
                                /*
                                Las mejores Ofertas del Black Friday 2024 ✔️ Chollos de de Amazon, El Corte Inglés, PcComponentes y más ⭐️
                                */
                            }
                            <title>{`Ofertas de Black Friday ➜ ${monthName} ${year} || Precios Rebajados`}</title>
                            <meta name='description' content={`Los mejores descuentos del Black Friday 2024. Este Black Friday descuentos en Amazon, Pccomponentes , Pandora y muchos más `} />
                            <meta name="robots" content="index, follow"></meta>
                            <script type="application/ld+json">
                                {JSON.stringify(jsonLd)}
                            </script>
                            <meta property="og:title" content={`Explora ${search ? search : 'nuestros productos'} - Ofertas y Descuentos`} />
                            <meta property="og:description" content={`Encuentra los mejores productos en oferta ${search ? search : 'en nuestra tienda'}. Aprovecha descuentos exclusivos.`} />
                            <meta property="og:image" content="https://preciosrebajados.com/api/images/black-friday-descuentos.webp" />
                            <meta property="og:url" content={`https://preciosrebajados.com/ofertas/black-friday}`} />
                            <meta property="og:type" content="website" />
                            <link rel="canonical" href={`https://preciosrebajados.com/ofertas/black-friday`} />
                        </Helmet>
                        {products.length === 0
                            ? <NoResultsFound />
                            : (<ListOfProductCardAwin products={products} title={`Ofertas Black Friday: ${totalProducts} `} busqueda={busqueda} />
                            )
                        }
                        {products.length > 0
                            ? <div className='listofproducts-buttons'>
                                <a className={`listofproducts-arroyleft ${currentPage === 1 ? 'desactive' : ''} `} onClick={handleArroyLeft} >
                                    <ArroyLeft />
                                </a>
                                <div>
                                    {currentPage} de {Math.ceil(total / limit)}
                                </div>
                                <a className={`listofproducts-arroyright ${parseInt(currentPage) === Math.ceil(total / limit) ? 'desactive' : ''}`} onClick={handleArroyRight}>
                                    <ArroyRight />
                                </a>
                            </div>
                            : null
                        }

                    </>)
                }
                <br />
                {hasProductsRelated && !loadingRelatedProducts &&
                    <>
                        {

                            productRelated.length === 0
                                ? <NoResultsFound />
                                : (<ListOfProductCardAwin products={productRelated} title={`Ofertas Black Friday en Ordenadores: ${totalProductsSearchRelated} `} />
                                )
                        }
                        {productRelated.length > 0
                            ? <div className='listofproducts-buttons'>
                                <a className={`listofproducts-arroyleft ${currentPageRelated === 1 ? 'desactive' : ''} `} onClick={handleArroyLeftRelated} >
                                    <ArroyLeft />
                                </a>
                                <div>
                                    {currentPageRelated} de {Math.ceil(total1 / limit)}
                                </div>
                                <a className={`listofproducts-arroyright ${parseInt(currentPageRelated) === Math.ceil(total / limit) ? 'desactive' : ''}`} onClick={handleArroyRightRelated}>
                                    <ArroyRight />
                                </a>
                            </div>
                            : null
                        }
                    </>
                }
                {
                    loadingRelatedProducts || loading ? null :

                        <div className='black-friday'>
                            <h4>Black Friday 2024: ¡Las Mejores Ofertas Están Aquí!</h4>
                            <p>El Black Friday es el momento perfecto para conseguir los productos que siempre has querido al mejor precio.
                                Este 2024, prepárate para disfrutar de las mejores ofertas Black Friday en tecnología, electrodomésticos, moda y mucho más.
                            </p>
                            <h4>¿Qué es el Black Friday y por qué es tan esperado?</h4>
                            <p>
                                El Black Friday marca el inicio de la temporada de compras navideñas con descuentos que pueden superar el 70%. Cada año, este evento nos sorprende con nuevas promociones:

                                En 2022, vimos ofertas increíbles en televisores, smartphones y gadgets.
                                En 2023, se destacaron las promociones en laptops, auriculares y accesorios de gaming.

                                Este 2024, la expectativa es aún mayor, con descuentos en todas las categorías y opciones de compra tanto online como en tiendas físicas.
                            </p>
                            <h4>¡No te pierdas las mejores ofertas Black Friday!</h4>
                            <p>
                                Durante el Black Friday 2024, encontrarás promociones en:
                                Tecnología

                                Actualiza tus dispositivos con rebajas irresistibles en:

                                Smartphones: Modelos de marcas líderes a precios reducidos.
                                Televisores 4K: Perfectos para disfrutar tus series y películas favoritas.
                                Ordenadores y gaming: Laptops, PCs y consolas con descuentos imperdibles.

                                Hogar y Electrodomésticos

                                Renueva tu hogar con las ofertas más destacadas:

                                Robots aspiradores y electrodomésticos inteligentes.
                                Descuentos en muebles y decoración para todos los estilos.

                                Moda y Belleza

                                Compra tus marcas favoritas con grandes descuentos en ropa, calzado y productos de belleza.

                            </p>
                            <h4>¿Cómo preparar tus compras para el Black Friday 2024?</h4>
                            <p>
                                Revisa las ofertas anteriores: Mira las promociones del Black Friday 2023 y Black Friday 2022 para identificar tendencias y precios promedio.
                                Aprovecha las promociones previas: Muchas tiendas lanzan descuentos antes del Black Friday.
                            </p>
                            <h4>Compra fácil, rápido y seguro</h4>
                            <p>
                                Encuentra las mejores ofertas en nuestro buscador de ofertas, y aprovechar los mejores precios. Ya sea que estés buscando tecnología, moda o regalos para Navidad, este Black Friday 2024 es tu oportunidad de ahorrar a lo grande.
                            </p>
                            <p>Si quieres saber más sobre el black friday en nuestro <a href='/blog/black-friday-2024' target="_blank"><strong>blog</strong></a> te contamos toda la historia.</p>
                        </div>
                }
            </HelmetProvider >

        </>
    )
}