import { Link } from 'wouter'
import { useState } from 'react'

export const New = () => {
    const [visible, setVisible] = useState(true)

    return (
        visible && (
            <div className='new'>
                <span className='new-badge'>Nuevo!</span>
                <Link to='/cupones' className='new-link'>Cupones Descuento!</Link>
                <button className='close-button' onClick={() => setVisible(false)}>X</button>
            </div>
        )
    )
}
