import { ListOfCategory } from '../Category/ListOfCategory.jsx'
import { Link } from 'wouter'


export const Footer = () => {
  return (
    <footer className='footer-footer'>

      <div className='rightereserved'>
        <div className='rightereserved-content'>
          <span>© 2024 Precios Rebajados. Todos los derechos reservados.</span>

        </div>
      </div>
      <div className='footerlinks'>
        <div className='footerlinks-title'>
          <h4>Otros</h4>
        </div>
        <div className='listfooterlinks'>
          <ul>
            <li>
              <Link to='/legal/privacy'> Política de Privacidad </Link>
            </li>
            <li>
              <Link to='/legal/cookies'> Cookies </Link>
            </li>
            <li>
              <Link to='/legal/term'>Aviso legal y Condiciones generales de uso</Link>
            </li>
            <li>
              <Link to='/nosotros'>Quienes Somos</Link>
            </li>
            <li>
              <Link to='/contacto'>Contacto</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='categories'>
        <div className='categories-title'>
          <h4>Categorías</h4>
        </div>
        <div className='listofcategory'>
          <ListOfCategory />

        </div>
      </div>
      <div className='comunity'>
        <div className='comunity-title'>
          <h4 >Comunidad</h4>
        </div>
        <div className='listcomunity'>
          <nav>
            <ul>
              <li>
                <img src="https://img.icons8.com/?size=100&id=55004&format=png&color=000000" alt="blog icons" loading="lazy" />
                <Link to='/blog'>Blog</Link>
              </li>
              <li>
                <img src="https://img.icons8.com/?size=100&id=JSk97QZ2nujd&format=png&color=000000" alt="cupones icons" loading="lazy" />
                <Link to='/cupones'>Cupones</Link>
              </li>
              <li>
                <img src="https://img.icons8.com/?size=100&id=63761&format=png&color=000000" alt="ofertas icons" loading="lazy" />
                <Link to='/ofertas/black-friday'>Ofertas Black Friday</Link>
              </li>
              <li>
                <img src="https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" alt="instagram logo" loading="lazy" />
                <a href='https://www.instagram.com/precios__rebajados' rel='nofollow'>
                  Instagram
                </a>
              </li>
              <li>
                <img src="https://img.icons8.com/?size=100&amp;id=p62ASPK2Kpqp&amp;format=png&amp;color=000000" alt="facebook logo" loading="lazy" />
                <a href='https://www.facebook.com/profile.php?id=61566968670859' rel='nofollow'>
                  Facebook
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

    </footer>
  )
}
