import { useGoTop } from '../../hooks/useGoTop'
import { createPortal } from 'react-dom'

export function ButtonUp() {
  const { scrollTop, showScroll } = useGoTop()

  return createPortal(
    <div className='button'>
      {
        showScroll
          ? <button onClick={scrollTop} className='button-up' type='button'><span>Subir</span></button>
          : null
      }

    </div>,
    document.body
  )
}


