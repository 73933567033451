import ReactGA from 'react-ga4'


export const initGA = (trackingId) => {
    ReactGA.initialize(trackingId)
}

export const logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  };

  export const logEvent = (category, action, label = '', value = 0) => {
    ReactGA.event({ category, action, label, value });
  };