import { useCallback, useState } from 'react'
import { getsFetchSearchId } from '../services/getFetchProducts.js'
import { useStoreProduct } from '../store/products.js'

export function useSingleProduct ({id}) {
  const products = useStoreProduct(state => state.products)
  
  const productSingle = useStoreProduct(state => state.productSingle)
  const setProductSingle = useStoreProduct(state => state.setProductSingle)
  const loadingSingleProducts = useStoreProduct(state => state.loadingSingleProducts)
  const setLoadingSingleProducts = useStoreProduct(state => state.setLoadingSingleProducts)
  const errorSingleProducts = useStoreProduct(state => state.errorSingleProducts)
  const setErrorSingleProducts = useStoreProduct(state => state.setErrorSingleProducts)

  const product = products?.find(product => product.id === parseInt(id))
  
  
  if (product) {    
    
    setProductSingle(product)    
  }
  

  /*const getsProductsId = useCallback(async ({ id }) => {
    try {
      setLoadingOne(true)
      setErrorOne(null)
      const newSearch = await getsFetchSearchId({ id })
      setSingleProduct(newSearch)
    } catch (e) {
      setErrorOne(e.message)
    } finally {
      setLoadingOne(false)
    }
  }, [])*/

  const getsProductsId = useCallback(async ({ id }) => {
    
    try {
      setLoadingSingleProducts(true)
      setErrorSingleProducts(null)
      const newSearch = await getsFetchSearchId({ id })
      
      setProductSingle(newSearch)
    } catch (e) {
      setErrorSingleProducts(e.message)
    } finally {
      setLoadingSingleProducts(false)
    }
  }, [])

  return { getsProductsId, productSingle, loadingSingleProducts, errorSingleProducts }
}
