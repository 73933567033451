import { useCallback } from 'react'
import { getFetchBlog, getFetchBlogList } from '../services/getFetchBlog.js'
//import blogs from '../mocks/blogs.json'
import { useStoreBlogs } from '../store/blogs.js'

export function useBlog() {
    const blogs = useStoreBlogs(state => state.blogs)
    const setBlogs = useStoreBlogs(state => state.setBlogs)    
    const loadingBlogs = useStoreBlogs(state => state.loadingBlogs)
    const setLoadingBlogs = useStoreBlogs(state => state.setLoadingBlogs)
    const errorBlogs = useStoreBlogs(state => state.errorBlogs)  
    const setErrorBlogs = useStoreBlogs(state => state.setErrorBlogs)

    const listBlogs = useStoreBlogs(state => state.listBlogs)
    const setlistBlogs = useStoreBlogs(state => state.setlistBlogs)  
    const errorListBlogs = useStoreBlogs(state => state.errorListBlogs)
    const setErrorListBlogs = useStoreBlogs(state => state.setErrorListBlogs)
    const loadingListBlogs = useStoreBlogs(state => state.loadingListBlogs)
    const setLoadingListBlogs = useStoreBlogs(state => state.setLoadingListBlogs)  
    
 /*
    const getsBlogslug = useCallback(({ slug }) => {
        try {
            setLoading(true)
            setError(null)

            // Buscar el blog específico por el slug
            const blog = blogs.find(blog => blog.slug === slug)

            if (!blog) {
                throw new Error('Artículo no encontrado')
            }
            setArticles(blog)
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }, [])

    const getsArticles = useCallback(() => {
        try {
            setLoading(true)
            setError(null)
            // Cargar todos los blogs desde el JSON
            setArticles(blogs)
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }, [])
   */
   
    
    const getsBlogslug = useCallback(async ({ slug }) => {
        try {
            setLoadingBlogs(true)
            setErrorBlogs(null)
            const newSearch = await getFetchBlog({ slug })           
           
            setBlogs(newSearch)
           
        } catch (e) {
            setErrorBlogs(e.message)
        } finally {
            setLoadingBlogs(false)
        }
    }, [])

    const searchBlogSlug = useCallback(async ({ slug }) => {
        try {
            setLoadingBlogs(true)
            setErrorBlogs(null)
            const foundBlog = listBlogs.find(blog => blog.slug === slug.slug)
            if (foundBlog) {
                setBlogs(foundBlog)
            }      
            
        } catch (e) {
            setErrorBlogs(e.message)
        } finally {
            setLoadingBlogs(false)
        }
    }, [])
    
    const getsArticles = useCallback(async () => {
        try {
            setLoadingListBlogs(true)
            
            setErrorListBlogs(null)
            const newSearch = await getFetchBlogList()            
            setlistBlogs(newSearch)          
            
        } catch (e) {
            setErrorListBlogs(e.message)
        } finally {
            setLoadingListBlogs(false)
        }
    }, [])
    
    return { blogs,setBlogs,listBlogs, loadingBlogs, errorBlogs,loadingListBlogs,errorListBlogs, getsBlogslug, getsArticles,searchBlogSlug }
}