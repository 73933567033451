import { Link } from "wouter"

export function LastBlog({ id, title, description, tags, image, category, author, date, content, slug }) {
    author = author ? author : "Jorge"
    const url = `/blog/${slug}`
    return (
        <div className="container-last-blog" key={id}>
            <h1>Lo último en {category.toLowerCase()}</h1>
            <section className="last-blog">
                <article className="article-last-blog">
                    <header>
                        <Link to={url}><h1>{title}</h1></Link>
                        <div className="author-date">
                            <span className="author-name">{author}</span>. <span className="date-blog"> {new Date(date).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                        </div>
                    </header>
                    <div className="article-image">
                        <img src={image} alt={title} loading="lazy" />
                    </div>
                    <div className="article-description">
                        <div className="article-description" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    <footer className="article-footer-last-blog">
                        <div className="category-blog">
                            <span>Categoria:</span>
                            <span className="category-blog-category "> {category}</span>
                        </div>
                        {tags && (
                            <div className="tags">
                                <h4>Etiquetas:</h4>
                                {tags.map((tag, index) => (<span key={index} className="tag">{tag}</span>
                                ))}
                            </div>
                        )}
                    </footer>
                    <div className="blog-footer-link">
                        <Link to={url}>Ver más...</Link>
                    </div>
                </article>
            </section>
        </div>
    );
}
