
import { Coupons } from './Coupons'
import './coupons.css'
import { Link } from 'wouter'

import { useFilter } from '../../hooks/useFilter'
import { useVouncher } from '../../hooks/useVouncher'
import { useGenerateAi } from '../../hooks/useGenerateAi'
import { Spinner } from '../Spinner/Spinner'
import { useGoTop } from '../../hooks/useGoTop'

export function ListOfCoupons({ vouncher, informacion, historia }) {
    const { scrollTop } = useGoTop()
    const { vouncherAdvertisers, loading, getVouncherData } = useVouncher()
    const { filters, setFilters } = useFilter()
    const { loadingInfo, loadingText } = useGenerateAi()
    const firstAdvertiserId = vouncher.data[0]?.advertiser.id;

    const handleFilterIcon = (coupon) => {
        setFilters(prevFilters => ({
            ...prevFilters, merchant: coupon.name, merchantId: coupon.id
        }))
        scrollTop()
        getVouncherData({ id: coupon.id, type: filters.type })
    }

    // Verificar si todos los cupones tienen el mismo advertiser.id
    const allSameAdvertiser = vouncher.data.every(coupon => coupon.advertiser.id === firstAdvertiserId);

    return (

        <section className='container-listofcoupons'>
            <ul className='listofcoupons-coupons'>
                {
                    vouncher.data.map((coupon) => (

                        <li key={coupon.promotionId} className='container-vouncher'>
                            <Coupons
                                id={coupon.promotionId}
                                name={coupon.advertiser.name}
                                titleCode={coupon.title}
                                descriptionCode={coupon.description}
                                code={coupon.voucher ? coupon.voucher.code : ''}
                                urlCode={coupon.urlTracking}
                                startDate={coupon.startDate}
                                endDate={coupon.endDate}
                                terms={coupon.terms}
                                idimgLogo={coupon.advertiser.id}
                            />
                        </li>
                    ))
                }
            </ul>
            {
                allSameAdvertiser && (
                    (historia === undefined || informacion === undefined) ? ' ' : (


                        <div className='infocoupons-container'>
                            {loadingInfo ? <Spinner title={loadingText} /> :
                                <>
                                    <div className='infocoupons1'>
                                        <h3>Un poco de historia de {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}</h3>

                                        {//loadingInfo ? <Spinner title={'Cargando historia...'} /> : 
                                            <p>{historia}</p>
                                        }

                                    </div>

                                    <div className='infocoupons2'>
                                        <h3>Algo que no sabias de {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}</h3>
                                        {

                                            //loadingInfo ? <Spinner title={'Cargando informacion...'} /> :
                                            <p>{informacion}</p>
                                        }

                                    </div>
                                </>
                            }
                            {console.log(historia)}
                            {
                                historia !== '' ?
                                    <div className='infocoupons1'>
                                        <h3>Un poco de historia de {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}</h3>
                                        <p>{historia}</p>

                                    </div>
                                    : null
                            }


                            {informacion !== '' ?
                                <div className='infocoupons2'>
                                    <h3>Algo que no sabias de {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}</h3>


                                    <p>{informacion}</p>

                                </div>
                                : null
                            }

                            <div className='infocoupons3'>
                                <h3>Consejos para ahorrar más</h3>
                                <p>Revisa nuestras ofertas y promociones periódicamente.</p>
                                <p>Combina cupones con descuentos por temporada para maximizar el ahorro.</p>
                                <p>Aprovecha los envíos gratuitos en compras superiores a una cierta cantidad.</p>

                            </div>
                            <div className='what'>
                                <h3>Cómo ahorrar en {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}</h3>
                                <p>Utiliza nuestros cupones de descuento para {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()} y consigue increíbles ahorros. No te pierdas nuestras promociones exclusivas.</p>
                            </div>
                            <div className="faq-section">
                                <h3>Preguntas frecuentes sobre {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}</h3>
                                <div className="faq-item-who">
                                    <h3>¿Cómo utilizo los cupones en {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()}?</h3>
                                    <p>Es sencillo, selecciona un cupón de nuestra lista y aplica el código en la página de pago para recibir el descuento.</p>
                                </div>
                                <div className="faq-item-frequency">
                                    <h3>¿Con qué frecuencia {vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()} ofrece cupones?</h3>
                                    <p>{vouncher.data[0].advertiser.name.replace(/\bES\b/g, '').trim()} ofrece cupones de manera periódica, especialmente durante eventos de ventas o promociones especiales.</p>
                                </div>
                            </div>
                            <div className='another-brand'>
                                <h3>
                                    Otras tiendas populares
                                </h3>
                                <div className='brand-list'>
                                    {
                                        loading ? <p>Cargando...</p> :
                                            vouncherAdvertisers.map((coupon) => (
                                                <div key={coupon.id} className='brand-item' >

                                                    <div onClick={() => handleFilterIcon(coupon)}>
                                                        <img src={`https://ui.awin.com/images/upload/merchant/profile/${coupon.id}.png`} alt={coupon.name} loading="lazy" />
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    )
                )
            }

            <div className='listofcoupons-coupons-moreinfo'>
                <div>
                    <h2>¡Ahorra en todo lo que quieras! Cupones de descuento actualizados diariamente.</h2>
                    <br />
                    <p>¿Buscas las mejores ofertas y descuentos para tus compras online y offline? ¡Estás en el lugar indicado! En preciosrebajados.com encontrarás una amplia variedad de cupones de descuento actualizados diariamente para que puedas ahorrar en todas tus compras.</p>
                </div>
                <br />
                <div>
                    <h2>¿Por qué elegir nuestros cupones?</h2>
                    <br />
                    <ul>
                        <li>Variedad: Desde moda y electrónica hasta viajes y alimentación, tenemos cupones para todos los gustos.</li>
                        <li>Actualizados diariamente: Nuestra selección de cupones se renueva constantemente para que siempre encuentres las mejores ofertas.</li>
                        <li>Fáciles de usar: Canjear nuestros cupones es muy sencillo, solo tienes que seguir las instrucciones.</li>
                    </ul>
                    <br />
                </div>
                <div>
                    <h2>Descubre nuestros cupones por categoría:</h2>
                    <br />
                    <ul>
                        <li>Moda y accesorios: Ropa, calzado, complementos...</li>
                        <li>Tecnología: Electrónica, informática, telefonía...</li>
                        <li>Viajes: Hoteles, vuelos, alquiler de coches...</li>
                        <li>Belleza y salud: Cosmética, productos para el cuidado personal...</li>
                        <li>Alimentación: Supermercados, restaurantes, comida a domicilio...</li>
                        <li>Y mucho más...</li>
                    </ul>
                    <br />
                </div>
                <div>
                    <h2>¿Cómo encontrar el cupón perfecto?</h2>
                    <br />
                    <ul>
                        <li>Utiliza nuestro filtro: Selecciona el nombre de la tienda o el tipo de cupón que buscas y encuentra los cupones disponibles.</li>
                        <li>Explora nuestras categorías: Descubre nuevas ofertas en las diferentes secciones de nuestra web.</li>
                        <li>Siguenos en <a href='https://www.instagram.com/precios__rebajados' target='_blank' rel='noreferrer nofollow'>nuestras redes</a> para enterarte de los mejores cupones</li>
                    </ul>
                    <br />
                </div>
                <div>
                    <h2>Consejos para ahorrar aún más?</h2>
                    <br />
                    <ul>
                        <li>Compara precios: Antes de realizar una compra, compara los precios en diferentes tiendas y utiliza nuestros cupones para conseguir el mejor precio.</li>
                        <li>Aprovecha las ofertas de temporada: Las rebajas y los períodos de descuentos son una excelente oportunidad para ahorrar.</li>
                        <li>Crea una lista de la compra: Evita las compras impulsivas y céntrate en lo que realmente necesitas.</li>
                        <li>Aqui tienes<Link to='/como-ahorrar-con-cupones-de-descuento-en-2024-guia-definitiva'> <strong>un blog</strong> </Link>con consejos para ahorrar.</li>
                    </ul>
                    <br />
                </div>
            </div>
        </section >


    )
}