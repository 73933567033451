import { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation, useParams } from 'wouter'

import { Spinner } from '../../components/Spinner/Spinner'
import { FiltersCoupon } from '../../components/Filters/FiltersCoupon'
import { NoResultsFound } from '../../components/NoResultsFound/NoResultsFound'
import { ListOfCoupons } from "../../components/Coupons/ListOfCoupons"

//import coupons from '../../mocks/vouncher.json'
import { useVouncher } from '../../hooks/useVouncher'
import { useUrl } from '../../hooks/useUrl.js'
import { useFilter } from '../../hooks/useFilter.js'
import { useSearch } from '../../hooks/useSearch.js'
import { usePagination } from '../../hooks/usePagination.js'
import { useGenerateAi } from '../../hooks/useGenerateAi.js'

export function Coupon() {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [location, setLocation] = useLocation()
  const { filters, setFilters } = useFilter()
  const { getInfos, info, loadingInfo } = useGenerateAi()
  const { search } = useSearch()
  const data = useParams()
  const { currentPage } = usePagination()
  const url = useUrl({ search, currentPage, filters: filters })
  const { vouncher, vouncherAdvertisers, totalVouncher, loading, error, getVouncherData, getVouncherAdvertisersData } = useVouncher()



  const urlParams = new URLSearchParams(window.location.search)


  //const tiendaParam = urlParams.get('tienda')
  const typeParam = urlParams.get('type')

  /*function capitalizeWords(str) {
    return str
      .split(' ') // Divide el string en palabras
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza cada palabra
      .join(' '); // Une las palabras de nuevo
  }*/
  useEffect(() => {
    const fetch = async () => {
      if (data.tienda !== undefined) {
        setFilters(prevFilters => ({
          ...prevFilters, merchant: data.tienda, type: typeParam || 'all'
        }))
        await getInfos({ title: data.tienda })
      }
    }
    //fetch()
  }, [data.tienda])

  useEffect(() => {
    //const tienda = capitalizeWords(data.tienda)
    const tienda = data.tienda
    //, price: 0, category: 'all', brand: 'all'
    if (data.tienda !== undefined) {
      setFilters(prevFilters => ({
        ...prevFilters, merchant: data.tienda, price: 0, category: 'all', brand: 'all'
      }))
    }
    //getInfo({ title: tienda })
    const fetch = async () => {
      await getVouncherAdvertisersData()
    }
    fetch()
  }, [])

  useEffect(() => {

    async function fetchData() {


      if (data.tienda !== undefined) {

        if (vouncher.length === 0) {
          const merchands = (vouncherAdvertisers.find(m => m.name.toLowerCase() === filters.merchant.toLowerCase()))

          if (merchands) {
            setFilters(prevFilters => ({ ...prevFilters, merchantId: merchands.id }));
            await getVouncherData({ id: merchands.id, type: filters.type, exclusiveOnly: false });
          }

        }
      }


      if (!data.tienda && vouncher.length === 0) {
        setFilters(prevFilters => ({ ...prevFilters, merchantId: filters.merchantId, type: filters.type, merchant: filters.merchant }));
        await getVouncherData({ id: filters.merchantId, type: filters.type, exclusiveOnly: false })
      }


    }
    fetchData()
  }, [vouncherAdvertisers])


  useEffect(() => {
    setLocation(url);
  }, [url, location, setLocation, filters]);


  const hasVouncher = totalVouncher > 0

  const date = new Date()
  const month = date.toLocaleString('es-ES', { month: 'long' })
  const year = date.getFullYear()




  const jsonLd = hasVouncher ? {

    "@context": "https://schema.org/",
    "@type": "ItemList",
    "itemListElement": vouncher.data.map((coupon, index) => {
      const regionsList = coupon.regions && Array.isArray(coupon.regions.list) ? coupon.regions.list : [];
      return {
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@type": "Product",
          "name": coupon.title,
          "image": `https://ui.awin.com/images/upload/merchant/profile/${coupon.advertiser.id}.png`,
          "description": coupon.description,
          "brand": {
            "@type": "Brand",
            "name": coupon.advertiser.name,
          },
          "offers": {
            "@type": "Offer",
            "priceCurrency": "EUR", // Cambia esto si es necesario
            "price": "0.00", // El precio es 0 porque es un cupón
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "url": coupon.url,
            "discountCode": coupon.voucher ? coupon.voucher.code : null,
            "validFrom": coupon.startDate,
            "validThrough": coupon.endDate,
            "eligibleRegion": regionsList.length > 0 ? {
              "@type": "Place",
              "name": regionsList[0].name || 'Desconocido',
              "addressCountry": regionsList[0].countryCode || 'Desconocido'
            } : {
              "@type": "Place",
              "name": 'Desconocido',
              "addressCountry": 'Desconocido'
            }
          }
        }
      }
    })
  } : null



  return (
    <HelmetProvider>
      <h1>Códigos descuento, Vales y Cupones para {month} {year}</h1>
      <FiltersCoupon />

      {
        !hasVouncher && loading && !error &&
        <>
          <Helmet>
            <title>Cargando cupones descuento...</title>
            <meta name="description" content={`Cargando cupones descuento...`} />
          </Helmet>
          <Spinner title={'Cargando cupones...'} />
        </>
      }
      {(!hasVouncher && error) &&
        <>
          <Helmet>
            <title>Precios Rebajados || Cupones y Vales || No se encontraron cupones</title> && <meta name='description' content='No se encontraron cupones' />
          </Helmet>
          <NoResultsFound />
        </>
      }
      {
        (!hasVouncher && !loading && !error) && <NoResultsFound />
      }

      {(hasVouncher && error) ? <> <NoResultsFound /> <h4>Te proponemos estos cupones:</h4></> : ''}

      {(hasVouncher && loading && !error) &&
        <Helmet>
          <title>Cargando cupones...</title> &&
          <meta name="description" content={`Códigos descuento, Vales y Cupones para ${month} ${year}`} />
        </Helmet> &&
        <Spinner title={'Cargando cupones...'} />
      }
      {hasVouncher && !loading &&
        (<>
          <Helmet>
            <title>{hasVouncher ? `Cupones de Descuento - Mejores Ofertas` : 'Ofertas y Cupones - Descuentos Exclusivos'}</title>
            && <meta name='description'
              content={hasVouncher
                ? 'Descubre miles de cupones de descuento para tus tiendas y marcas favoritas. ¡Renueva tus ofertas cada día y ahorra en todas tus compras!'
                : 'Explora grandes descuentos en una amplia variedad de productos. No te pierdas nuestras promociones.'} />
            <script type="application/ld+json">
              {JSON.stringify(jsonLd)}
            </script>
            <meta property="og:title" content={`Cupones descuento y ofertas - Ahorra en la compra `} />
            <meta property="og:description" content={`Encuentra cupones y descuentos únicos para ahorrar en tus compras. Ofertas actualizadas diariamente.`} />
            <meta property="og:image" content="https://preciosrebajados.com/images/cupones-ofertas.webp" />
            <meta property="og:url" content={`https://preciosrebajados.com/cupones}`} />
            <meta property="og:type" content="website" />
            <link rel="canonical" href="https://www.preciosrebajados.com/cupones" />
          </Helmet>
          {vouncher.length === 0 ?
            <NoResultsFound />
            : (<>{filters.merchant === 'all'
              || filters.merchant === ''
              || filters.merchant === undefined
              ? <h2>Todos los cupones descuento </h2>
              : <h2>Cupones descuento de {filters.merchant}</h2>}
              <ListOfCoupons vouncher={vouncher} informacion={loadingInfo ? <Spinner /> : info.informacion} historia={loadingInfo ? <Spinner /> : info.historia} /></>)
          }
        </>)
      }
    </HelmetProvider>
  )
}