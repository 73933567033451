import { CarrouselItem } from './CarrouselItem.jsx'
import { ArroyRight, ArroyLeft, Ellipsis } from '../../icons/icons.jsx'
import { usePagination } from '../../hooks/usePagination.js'
import { useEffect, useState } from 'react'
import { Spinner } from '../Spinner/Spinner.jsx'
import { useParams } from 'wouter'
import { useProduct } from '../../hooks/useProduct.js'
import { useSearch } from '../../hooks/useSearch.js'
import { useBlog } from '../../hooks/useBlog.js'
import { useSingleProduct } from '../../hooks/useSingleProduct.js'
import { useRef, useCallback } from 'react'

export function ListOfCarrousel() {
  const { id } = useParams()
  const data = useParams()
  const { getsProductsCarrousel, productsCarrousel, totalProductsCarrousel, totalProductsSearchCarrousel, errorCarrousel, loadingCarrousel } = useProduct()
  const { search, searchBlog, searchDetail, setSearchDetail } = useSearch()
  const { productSingle } = useSingleProduct({ id })
  const { handleArroyRightCarrousel, handleArroyLeftCarrousel, currentPageCarrousel, setCurrentPageCarrousel } = usePagination()
  //const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const startX = useRef(0); // Almacenar la posición inicial
  const endX = useRef(0); // Almacenar la posición final
  const lastTouchEnd = useRef(0);

  let limit = totalProductsSearchCarrousel > 0 && totalProductsSearchCarrousel < 25 ? totalProductsCarrousel : 25

  /*

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX; // Captura el inicio del toque
  };

  const handleTouchMove = (e) => {
    endX.current = e.touches[0].clientX; // Captura el movimiento del toque
  };

  const handleTouchEnd = () => {
    const deltaX = startX.current - endX.current;

    // Evitar demasiado cálculo en un tiempo corto
    if (Date.now() - lastTouchEnd.current < 200) return; // Si es muy rápido, no hacer nada.

    lastTouchEnd.current = Date.now();

    // Umbral de deslizamiento
    if (Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        // Deslizar hacia la izquierda (siguiente página)
        setCurrentPageCarrousel((prevPage) => prevPage + 1);
      } else {
        // Deslizar hacia la derecha (página anterior)
        setCurrentPageCarrousel((prevPage) => prevPage - 1);
      }
    }
  };
*/

  useEffect(() => {
    if (data.title) {
      setSearchDetail(data.title.replace(/-/g, ' '))

    }
  }, [data.title])


  const goToPage = (pageIndex) => {
    setCurrentPageCarrousel(pageIndex);
    // Aquí puedes agregar lógica adicional para cambiar el contenido de la página
  };


  const handleNext = () => {
    const totalPages = Math.ceil(productsCarrousel.length / itemsPerPage);
    if (currentPageCarrousel < totalPages) {
      setCurrentPageCarrousel(currentPageCarrousel + 1);
    }
  };

  const handlePrev = () => {
    if (currentPageCarrousel > 1) {
      setCurrentPageCarrousel(currentPageCarrousel - 1);
    }
  }

  //1280

  //const totalpantalla = 2015;

  let itemsPerPage = 5;
  /*// Cantidad de ítems por página
  if (containerWidth > 769 && containerWidth < 1053) {
    itemsPerPage = 3
    limit = totalProductsSearchCarrousel > 0 && totalProductsSearchCarrousel < 15 ? totalProductsCarrousel : 15
  }
  if (containerWidth > 1053 && containerWidth < 1180) {
    itemsPerPage = 4
    limit = totalProductsSearchCarrousel > 0 && totalProductsSearchCarrousel < 16 ? totalProductsCarrousel : 16
  }
    */
  const gap = 9.9; // Espacio entre ítems
  //const contenedor = 1280; // Ancho del contenedor principal
  const anchoProducto = 248;
  // const anchor = (contenedor - ((itemsPerPage - 1) * gap)) / itemsPerPage;


  const totalPages = Math.ceil(limit / itemsPerPage);

  const offset = (currentPageCarrousel - 1) * (itemsPerPage * (anchoProducto + gap))
  const transformValue = `translate3d(-${offset}px,0,0)`;

  const extractRK = (title) => {
    // Lista de palabras que deben ser ignoradas
    const stopWords = [
      'de', 'para', 'en', 'y', 'con', 'la', 'el', 'los', 'las', 'a', 'del', 'como', 'por', 'o', 'es', 'su', 'sus',
      'nuevo', 'nueva', 'mejor', 'último', 'edición', 'versión', 'pack', 'caja', 'combo', 'oferta', 'promoción',
      'incluye', 'kit', 'tamaño', 'color', 'modelo', 'tipo', 'marca', 'precio', 'uso', 'cantidad', 'poco', 'mucho',
      'gran', 'grande', 'pequeño', 'bajo', 'alto', 'equipo', 'set', 'función', 'smart', 'funcionalidad', 'pro',
      // Inglés
      'the', 'of', 'to', 'in', 'and', 'with', 'on', 'at', 'for', 'from', 'by', 'as', 'an', 'or', 'it', 'this', 'that',
      'these', 'those', 'a', 'some', 'any', 'no', 'yes', 'not', 'all', 'each', 'every', 'both', 'such', 'one', 'two',
      'few', 'many', 'more', 'most', 'other', 'another', 'which', 'what', 'when', 'where', 'why', 'how', 'is', 'are',
      'was', 'were', 'be', 'been', 'has', 'have', 'do', 'does', 'did', 'will', 'shall', 'may', 'might', 'can', 'could',
      'would', 'should', 'there', 'here', 'also', 'too', 'than', 'into', 'about', 'up', 'out', 'over', 'under', 'again',
      // Símbolos
      '&', '+', '/', '-', '_', '|', '!', '#', '$', '%'
    ];

    // Normalización de caracteres acentuados
    const normalizeString = (str) => {
      return str.toLowerCase()
        .replace(/[áàäâ]/g, 'a')
        .replace(/[éèëê]/g, 'e')
        .replace(/[íìïî]/g, 'i')
        .replace(/[óòöô]/g, 'o')
        .replace(/[úùüû]/g, 'u')
        .replace(/[ñ]/g, 'n')
        .replace(/[Ñ]/g, 'n')
        .replace(/[^a-z0-9\s-]/g, '') // Eliminar caracteres no alfanuméricos excepto guiones
        .trim();
    };

    // Normalizamos la marca y el título
    const brandNormalized = normalizeString(productSingle ? productSingle.brand : '');
    const titleNormalized = normalizeString(title);

    // Si la marca está presente en el título, la eliminamos
    let titleWithoutBrand = titleNormalized;
    if (brandNormalized) {
      const brandRegex = new RegExp(brandNormalized.split(' ').join('[\\s-]+'), 'g');
      titleWithoutBrand = titleNormalized.replace(brandRegex, ' ').trim();
    }

    // Separar el título en palabras, considerando los guiones como espacios
    const words = titleWithoutBrand.split(/[\s-]+/); // Esto asegura que los guiones sean tratados como espacios

    // Filtrar las palabras clave (eliminamos las stopWords y palabras de menos de 3 caracteres)
    let keywords = words.filter(word => !stopWords.includes(word) && word.length > 2);

    // Limitar a un máximo de 2 palabras clave
    keywords = keywords.slice(0, 2);

    // Si no hay suficientes palabras clave, combinamos las dos primeras
    const keywordString = keywords.join(" ");

    return keywordString;
  }

  //console.log(search, searchBlog, data.title)


  useEffect(() => {

    if (searchBlog !== '') {

      getsProductsCarrousel({ search: searchBlog, category: 'all', brand: 'all', price: 0, page: currentPageCarrousel, limit: limit })
    } else {
      if (search === '') {
        getsProductsCarrousel({ search: extractRK(data.title), category: 'all', brand: 'all', price: 0, page: currentPageCarrousel, limit: limit })
      } else {
        getsProductsCarrousel({ search: search, category: 'all', brand: 'all', price: 0, page: currentPageCarrousel, limit: limit })
      }
    }

    setCurrentPageCarrousel(1)
  }, [search, searchBlog, data.title])

  const valueSearch = search !== '' ? `Ofertas similares a tu última búsqueda “${search}”` : searchDetail !== '' ? `Ofertas relacionadas` : 'Ofertas vistas por otros usuarios'

  return (
    <>
      { }
      {errorCarrousel && <p>Ha ocurrido un error: {errorCarrousel}</p>}
      {
        totalProductsCarrousel > 0 &&
        <h2 className='title-carrousel'>
          {valueSearch}
          {/*
          search === ''
            ? 'Productos que pueden interesarte'
            : searchDetail ? `Productos similares a tu última búsqueda “${searchDetail}”` : `Productos similares a tu última búsqueda “${search}”`
            */
          }
        </h2>
      }
      {
        //onTouchStart={handleTouchStart} onTouchMove = { handleTouchMove } onTouchEnd={handleTouchEnd}
      }
      {loadingCarrousel ? <Spinner /> :
        <section className="listofcarrousel-container " >
          <div className='listofcarrousel-buttons'>
            <a className={`arroyleft ${currentPageCarrousel === 1 ? 'desactive-carrousel' : ''}`} onClick={handlePrev} >
              <ArroyLeft />
            </a>
            <a className={`arroyright ${currentPageCarrousel === totalPages ? 'desactive-carrousel' : ''}`} onClick={handleNext}>
              <ArroyRight />
            </a>
          </div>

          <div className='listofcarrousel-listofproducts' style={{ transform: transformValue }}>
            <ul className='listofcarrousel-products'>
              {
                productsCarrousel.map((productCarrousel) => {
                  return (
                    <li key={productCarrousel.id} className="carrousel-product-container">
                      <CarrouselItem
                        id={productCarrousel.id}
                        title={productCarrousel.title}
                        image={productCarrousel.imageUrl}
                        description={productCarrousel.description}
                        price={productCarrousel.price}
                        priceNow={productCarrousel.priceNow}
                        priceOld={productCarrousel.priceOld}
                        priceSearch={productCarrousel.priceSearch}
                        priceSaving={productCarrousel.priceSaving}
                        priceBase={productCarrousel.priceBase}
                        priceRrp={productCarrousel.priceRrp}
                        linkToProduct={productCarrousel.linkToProduct}

                      />
                    </li>
                  )
                })
                //
              }

            </ul>
          </div>


        </section>
      }
      {productsCarrousel.length === 0 ? null :
        <div className='listofcarrousel-buttons-pointer'>
          {Array.from({ length: totalPages }, (_, idx) => (
            <div
              key={idx + 1}
              className={`listofcarrousel-buttons-item ${idx + 1 === currentPageCarrousel ? "active" : ""}`}
              onClick={() => goToPage(idx + 1)}
            >
              <Ellipsis />
            </div>
          ))}
        </div>
      }
    </>
  )
}
