

//categorias del home y de footer
export const getsFetchCategories = async () => {
  try {
    const baseUrl = import.meta.env.VITE_API_SEARCH_CATEGORIES
    if (!baseUrl) {
      throw new Error('VITE_API_SEARCH_CATEGORY is not defined')
    } 
    
    const response= await fetch(`${baseUrl}/categories`)
    const json = await response.json()
    const datas = json
 
    const data = datas?.map(data => ({
      id: data._id,
      title: data.title,
      value: data.value,
      valueurl: data.valueurl,
      imageUrl: data.image,
      description: data.description
    }))
    return data
  } catch (error) {
    throw new Error('Error en la petición: ' + error)
  }
}
export const getsCategoryFromSearch = async ({ search,brand ,price}) => {
  
  if (brand === undefined) {
    brand = 'all'
    
  }
  try {
    const baseUrl = import.meta.env.VITE_API_SEARCH_CATEGORY
    if (!baseUrl) {
      throw new Error('API_SEARCH_CATEGORY is not defined')
    }    
    const response = await fetch(`${baseUrl}/?search=${search}&brand=${brand}&price=${price}`)
    const json = await response.json()
    const datas = json[0].Data
    
    
    const data = datas?.filter(value => value.category_name !== '')
    .filter(value => value.brand_name !== '')
    .filter((item, index, array) => array.findIndex(t => (t.category_name === item.category_name)) === index)    
    .map(data => ({
      category_name: data.category_name
      
    }))
    
    return data
  } catch (error) {
    throw new Error('Error en la petición: ' )
  }
}