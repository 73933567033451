import { useEffect } from "react"
import { useLocation } from "wouter"
import { logPageView } from "../services/analytics"



export function RouteChangeTracker (){    
        const [location] = useLocation();
      
        useEffect(() => {
          logPageView();
        }, [location]);
      
        return null
      

    
    
}