import { useContext, useState, useCallback } from 'react'
import {  getsCategoryFromSearch,getsFetchCategories } from '../services/getFetchCategory.js'
import { useSearch } from './useSearch.js'
import { CategoryContext } from '../context/categorycontext.jsx'


export function useCategory () { 
  const {getCategory,
    getCategoryProduct,
    setGetCategory,
    setGetCategoryProduct,clearCategory,
    clearCategoryProduct} = useContext(CategoryContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { search } = useSearch()
 
 
  const getsCategory = useCallback(async () => {
    try {
      setLoading(true)
      setError(null)
      if (getCategory.length > 0) {
        return        
      }else{
        const categories = await getsFetchCategories()
        setGetCategory(categories)
      }
      
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])
  const getCategoryFromSearch = useCallback(async ({ search ,brand ,price }) => {
    
    try {
      setLoading(true)
      setError(null)
      const categories = await getsCategoryFromSearch({ search ,brand ,price})
      setGetCategoryProduct(categories)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])
  
  

  return { getsCategory,clearCategory,
    clearCategoryProduct, getCategoryFromSearch,
     getCategory, loading, error, getCategoryProduct }
}
