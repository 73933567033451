import { useState, useEffect } from 'react'
import { Spinner } from '../Spinner/Spinner';
import { useGenerateAi } from '../../hooks/useGenerateAi.js'

export function ProductCard({
  id, linkToProduct,
  title, merchant,
  merchantId, price, priceSearch, priceSaving, priceBase, priceRrp, priceNow, priceOld,
  idBrand,
  imageUrl,
  description, category,
  categoryId, delivery,
  brand, rating,
  images = [],
  linkToDetail,
  descriptionAi,
  titleAi
}) {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainImage, setMainImage] = useState(images[0] || imageUrl);
  const [loadingImage, setLoadingImage] = useState('');
  const { loadingText, error } = useGenerateAi()


  const formatText = (text) => {
    return text
      .replace(/### (.+)/g, '<h3>$1</h3>')  // Encabezados de nivel 3
      .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>')  // Texto en negrita
      .replace(/\n/g, '<br/>')  // Saltos de línea
      .replace(/(<b>.*?<\/b>)<br\/>/g, '$1\n')  // Evita <br/> después de <b>
      .split('\n').map((line, index) => `<p key=${index}>${line}</p>`).join(''); // Párrafos
  }

  const precios = [price, priceSearch, priceSaving, priceBase, priceRrp, priceNow, priceOld].filter(value => Number.isFinite(value))
  const priceMax = Math.max.apply(Math, precios)
  const priceMin = Math.min.apply(Math, precios)



  useEffect(() => {
    if (loadingImage) {

      const img = new Image();
      img.src = loadingImage;
      img.onload = () => setMainImage(loadingImage); // Solo cambia la imagen cuando esté completamente cargada
    }
  }, [loadingImage]);

  useEffect(() => {

    if (images.length > 0) {
      // Cambia la imagen automáticamente cada 15 segundos (15000 ms)

      const interval = setInterval(() => {
        const nextIndex = (currentIndex + 1) % images.length;
        setLoadingImage(images[nextIndex]); // Prepara la nueva imagen
        setCurrentIndex(nextIndex); // Actualiza el índice
      }, 15000);

      return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
    }
  }, [currentIndex])

  const handleImageClick = (index) => {
    console.log('index', index)
    setCurrentIndex(index)
    setLoadingImage(images[index])
  }

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(linkToDetail)}&text=${encodeURIComponent('Mira este producto: ' + title)}`;
    window.open(url, '_blank');
  };

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(linkToDetail)}`;
    window.open(url, '_blank');
  };

  const shareOnWhatsApp = () => {
    const url = `https://wa.me/?text=${encodeURIComponent('Mira este producto: ' + title + ' ' + linkToDetail + 'en @preciosrebajados')}`;
    window.open(url, '_blank');
  };

  function extractProductDetails(title, brand) {
    // Crear un objeto donde almacenaremos los detalles extraídos
    const productDetails = {
      brand: brand, // Usamos el brand que ya está en el objeto
      productType: '',
      features: [],
      volume: ''
    };

    // Expresión regular para extraer el volumen (en mililitros)
    const volumeMatch = title.match(/(\d+ml)/);
    if (volumeMatch) {
      productDetails.volume = volumeMatch[1]; // Volumen (7ml en el ejemplo)
    }

    // Separar las palabras claves del producto (puede ser todo lo que está entre los guiones, excluyendo la marca y volumen)
    const productTypeMatch = title.match(/ - (.+?) -/);
    if (productTypeMatch) {
      productDetails.productType = productTypeMatch[1]; // Tipo de producto (Top coat No Wipe Real Colour)
    }

    // Características adicionales (palabras clave como "No Wipe", "Real Colour", etc.)
    const featureMatches = title.match(/(?: - )([A-Za-z0-9 ]+?)(?= -|$)/g);
    if (featureMatches) {
      productDetails.features = featureMatches.map(feature => feature.trim());
    }

    return productDetails;
  }



  // const porcentaje = (((priceMin * 100) / priceMax) - 100).toFixed(2) + ' %'
  return (
    <>
      <section className='content-product-card'>
        <article className='product-article-card'>
          <div className='product-article-card-main'>
            <main className="product-card-main">
              <div className='product-img-card'>
                <div className="product-img-card-main">
                  <a >
                    <img src={mainImage} alt={title} loading="lazy" onError={(e) => e.target.src = "https://images2.productserve.com/noimage.gif"} />
                  </a>
                </div>
                <div className='product-img-card-list'>
                  <ul className='listofimg'>
                    {images.map((image, index) => {
                      if (!image) return null;
                      return (

                        <li key={index} className='listofimg-img'>
                          <a onClick={() => handleImageClick(index)}>
                            {
                              (image === undefined || image === '' || image === null)
                                ? null
                                : <img src={image} alt={title} className='listofimg-img-img' loading="lazy" onError={(e) => e.target.src = "https://images2.productserve.com/noimage.gif"} />
                            }
                          </a>
                        </li>
                      )
                    })

                    }

                  </ul>
                </div>

              </div>
              <div className='product-description-card'>
                <header className='product-header-card'>
                  <h1 className='product-title-card' key={id}><span>Oferta en {title}</span></h1>
                </header>
                <div className='product-brand-card'>
                  <div>
                    <span>Marca : {brand}</span>
                  </div>
                </div>
                <div className='product-descriptiondetaill-card'>
                  <span>Descripción: </span>
                  <p>{description}</p>
                </div>
                <div className='product-merchant-card'>
                  <span>Tienda : {merchant}</span>
                </div>
              </div>
            </main>
          </div>

          <footer>
            <div className='product-footer-card-fixed'>
              <div className="product-prices">
                {
                  priceMax === priceMin
                    ? <div className="product-price-now">
                      <span >Precio: {priceMin} €</span>
                    </div>
                    : <>
                      <div className="product-price-later">
                        <span >Antes: {priceMax} €</span>
                      </div>
                      <div className="product-price-now">
                        <span >Ahora: {priceMin} €</span>
                      </div>
                    </>
                }
              </div>
              <div className="product-prices-mobile">
                {
                  priceMax === priceMin
                    ?
                    <div className="product-price-now">
                      <span > {priceMin} €</span>
                    </div>
                    :
                    <>
                      <div className="product-price-now">
                        <span > {priceMin} €</span>
                      </div>
                      <div className="product-price-later">
                        <span > {priceMax} €</span>
                      </div>

                    </>
                }
              </div>
              <div>
                <a href={linkToProduct} target='_blank' rel="noreferrer nofollow">
                  <button > Ir a la Oferta </button>
                </a>
              </div>
            </div>
          </footer>
          <div className='product-article-card-share'>
            <div >
              <button onClick={shareOnTwitter}>Compartir en X (Twitter)</button>
            </div>
            <div>
              <button onClick={shareOnFacebook}>Compartir en Facebook</button>
            </div>
            <div>
              <button onClick={shareOnWhatsApp}>Compartir en WhatsApp</button>
            </div>
          </div>
        </article>

      </section>

      {
        error ? null : (
          loadingText ?
            <Spinner />
            : (!descriptionAi ? null :
              <div className="content-product-card-moreinfo">
                <>
                  <h2>Información adicional de {titleAi}</h2>
                  <br />
                  {
                    descriptionAi ? <div dangerouslySetInnerHTML={{ __html: formatText(descriptionAi) }} /> : null
                  }

                </>


              </div>
            )
        )
      }
      {

      }

    </>
  )
}
