import { createContext,useState } from "react"

export const BrandContext = createContext({
    getBrand: [],
    setGetBrand: () => {},
  clearBrand: () => {}
})

export function BrandProvider ({ children }) {
  const [getBrand, setGetBrand] = useState([])
  
  const clearBrand = () => {
    setGetBrand([])
  }
  
  return (
    <BrandContext.Provider value={{
        getBrand,
        setGetBrand,
        clearBrand
    }}
    >
      {children}
    </BrandContext.Provider>
  )
}