import { Fire, Desktop, Shopping, Home, Regalar } from '../../icons/icons.jsx'

import { useFilter } from '../../hooks/useFilter.js'

export const FiltersHeader = () => {
  const { handleCategoryClick } = useFilter()

  

  return (
    <div className='filters-header'>
      <div>
        <a className='icons-filters' onClick={handleCategoryClick} value='all' valueurl='todo'>
          <label>
            <Fire to='/' />
          </label>
          <span>
            Ùltimas ofertas
          </span>
        </a>
      </div>
      <div>
        <a className='icons-filters' onClick={handleCategoryClick} value='Computer' valueurl='ordenadores'>
          <span>
            <Desktop />
          </span>
          <span>
            Tecnología
          </span>
        </a>
      </div>
      <div>
        <a className='icons-filters' onClick={handleCategoryClick} value='Bags' valueurl='bolsas'>
          <span>
            <Shopping />
          </span>
          <span>
            Compras
          </span>
        </a>
      </div>
      <div>
        <a className='icons-filters' onClick={handleCategoryClick} value='Skincare' valueurl='cuidado-de-la-piel'>
          <span>
            <Regalar />
          </span>
          <span>
            Regalos
          </span>
        </a>
      </div>
      <div>
        <a className='icons-filters' onClick={handleCategoryClick} value='Home Accessories' valueurl='accesorios-para-el-hogar'>
          <span>
            <Home />
          </span>
          <span>
            Hogar
          </span>
        </a>
      </div>
    </div>

  )
}
