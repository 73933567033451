import { useEffect, useState } from 'react'
import { useSearch } from './useSearch'

export function useCookies () {
  const { search } = useSearch()
  const [cookies, setCookie, removeCookie] = useState({
    cookieBar: '',
    cookieSearch: ''
  })

  const handleCookies = () => {
    if (localStorage.getItem('cookieBar') === null) {
      localStorage.setItem('cookieBar', 'cookiesbarhidden')
      const cookiesData = localStorage.getItem('cookieBar')
      setCookie({ cookieBar: cookiesData })
    } else if (localStorage.getItem('cookieBar') === 'cookiesbarhidden') {
      localStorage.setItem('cookieBar', 'cookiesbarhidden')
      const cookiesData = localStorage.getItem('cookieBar')
      setCookie({ cookieBar: cookiesData })
    }
  }
  const handleCookiesSearch = (search) => {
    if (localStorage.getItem('cookiesSearch') === null || localStorage.getItem('cookiesSearch') === '') {
      localStorage.setItem('cookiesSearch', `${search}`)
      const cookiesData = localStorage.getItem('cookiesSearch')
      setCookie({ cookieSearch: cookiesData })
    } else if (localStorage.getItem('cookiesSearch')) {
      localStorage.setItem('cookiesSearch', `${search}`)
      const cookiesData = localStorage.getItem('cookiesSearch')
      setCookie({ cookieSearch: cookiesData })
    }
  }

  useEffect(() => {
    if (localStorage.getItem('cookieBar') === 'cookiesbarhidden') {
      localStorage.setItem('cookieBar', 'cookiesbarhidden')
      const cookiesData = localStorage.getItem('cookieBar')
      setCookie({ cookieBar: cookiesData })
    }

    if (localStorage.getItem('cookiesSearch') === null) {
      localStorage.setItem('cookiesSearch', `${search}`)
      const cookiesDataSearch = localStorage.getItem('cookies')
      setCookie({ cookieSearch: cookiesDataSearch })
      // handleCookiesSearch(search)
    }
  }, [])

  return { cookies, removeCookie, handleCookies, handleCookiesSearch }
}
