

export function Category ({ title}) {
  return (
    <a >
      {title}
    </a>

  )
}
