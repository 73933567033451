
import { useSearch } from '../../hooks/useSearch'

export function SearchBar() {

  let { search, error, handleChange, handleSearch } = useSearch()


  return (
    <>
      {<span className='error'>{error}</span>}
      <form onSubmit={handleSearch}>
        {error
          ?
          <input className='errorInput'
            value={search}
            name='input'
            type='text'
            placeholder='Buscar Ofertas'
            onChange={handleChange}
          />
          :
          <input
            value={search}
            name='input'
            type='text'
            placeholder='Buscar Ofertas'
            onChange={handleChange}
          />

        }
        <button type='submit'>Buscar</button>
      </form>
    </>

  )
}