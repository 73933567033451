import { create } from "zustand";

export const useStoreSearch = create((set, get) => {
    return {
        search: "",
        setSearch: (search) => set(() => ({ search })),
        error: null,
        setError: (error) => set(() => ({ error })),
        location: "",
        searchBlog: "",
        setSearchBlog: (searchBlog) => set(() => ({ searchBlog })),
        searchDetail: "",
        setSearchDetail: (searchDetail) => set(() => ({ searchDetail })),
    };
    });