import { useState, useEffect, useId, useCallback } from 'react'
import { FilterIcon } from '../../icons/icons.jsx'
import { translateCategorySpanish } from '../../services/translateCategory.js'

import { useCategory } from '../../hooks/useCategory.js'
import { useFilter } from '../../hooks/useFilter.js'
import { useBrand } from '../../hooks/useBrand.js'
import { useSearch } from '../../hooks/useSearch.js'
import { Spinner } from '../Spinner/Spinner.jsx'
import { useProduct } from '../../hooks/useProduct.js'


export function Filters() {
  const [isModal, setIsModal] = useState(window.innerWidth <= 1180)
  const { clearFilters, filters, handleCategory, handleBrand, handlePrice, prevFilters } = useFilter()
  const { getCategoryProduct, getCategory, getsCategory, getCategoryFromSearch } = useCategory()
  const { getBrand, getBrandFromSearch } = useBrand()
  const { search, setSearch } = useSearch()
  const [value, setValue] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const { loading } = useProduct()

  const priceFilterId = useId()
  const categoryFilterId = useId()
  const brandFilterId = useId()


  const handleOpenModal = () => {

    setOpenModal(!openModal);
  }


  const filtersActive = useCallback(() => {
    /*
    if (filters.price !== 0 && filters.category === 'all' && filters.brand === 'all') {
      return setValue(prevState => ({ ...prevState, value: 1 }))
    }
    if (filters.price === 0 && filters.category !== 'all' && filters.brand === 'all') {
      return setValue(prevState => ({ ...prevState, value: 1 }))
    }
    if (filters.price === 0 && filters.category === 'all' && filters.brand !== 'all') {
      return setValue(prevState => ({ ...prevState, value: 1 }))
    }
    if (filters.price !== 0 && filters.category !== 'all' && filters.brand === 'all') {
      return setValue(prevState => ({ ...prevState, value: 2 }))
    }
    if (filters.price === 0 && filters.category !== 'all' && filters.brand !== 'all') {
      return setValue(prevState => ({ ...prevState, value: 2 }))
    }
    if (filters.price !== 0 && filters.category === 'all' && filters.brand !== 'all') {
      return setValue(prevState => ({ ...prevState, value: 2 }))
    }
    if (filters.price === 0 && filters.category !== 'all' && filters.brand !== 'all') {
      return setValue(prevState => ({ ...prevState, value: 2 }))
    }
    if (filters.price !== 0 && filters.category !== 'all' && filters.brand !== 'all') {
      return setValue(prevState => ({ ...prevState, value: 3 }))
    }
    if (filters.price === 0 && filters.category === 'all' && filters.brand === 'all') {
      return setValue(prevState => ({ ...prevState, value: 0 }))
    }
    return setValue(prevState => ({ ...prevState, value: 0 }))*/

    if (filters.price !== "0" || filters.category !== 'all' || filters.brand !== 'all') {
      const activeFilters = [filters.price !== "0", filters.category !== 'all', filters.brand !== 'all'].filter(Boolean).length;
      setValue(activeFilters);

    } else {
      setValue(0);
    }
  }, [filters])



  useEffect(() => {
    if (getCategory.length === 0) {
      getsCategory()
    }
    const handleResize = () => {
      setIsModal(window.innerWidth <= 1180); // Cambia entre modal y normal según el tamaño de la pantalla
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    filtersActive()
  }, [filters])




  return isModal ? (
    <div className={`filters-modal-content ${loading ? 'filters.loading' : ''}`}>
      {loading && <div className='spinner-overlay'><Spinner /></div>}
      <div className='filters-modal'>
        <div className='filters-modal-active'>
          <span>
            {value === 1 ? `${value} Filtro activo` : `${value} Filtros activos`}

          </span>
        </div>
        <div className='filters-modal-icon'>
          <a onClick={handleOpenModal}>
            <FilterIcon />
          </a>
        </div>
      </div>
      {openModal &&
        <div className='filters-modal-window'>
          <div className='filters-modal-price'>
            <div className='filters-modal-price-title'>
              <label htmlFor={priceFilterId}>Precio</label>
            </div>
            <div className='filters-modal-price-input'>
              <div>
                <input
                  type='range'
                  id={priceFilterId}
                  min='0'
                  max='1000'
                  onChange={handlePrice}
                  value={filters.price}
                />
              </div>
              <div>
                <input
                  type='number'
                  min='0'
                  max='1000'
                  onChange={handlePrice}
                  value={filters.price}
                />
                <span>€</span>
              </div>
            </div>
          </div>
          <div className='filters-modal-category'>
            <div className='filters-modal-category-title'>
              <label htmlFor={categoryFilterId}>Categorías</label>
            </div>
            <div className='filters-modal-category-select'>
              <select
                id={categoryFilterId}
                onChange={handleCategory}
                value={filters.category}>
                return (
                <option value='all'>Todo</option>
                {
                  (getCategoryProduct.length > 0) ?

                    getCategoryProduct.map((category, key) =>
                    (
                      <option key={key} value={category.category_name} valueurl={translateCategorySpanish(category.category_name).replace(/-/g, ' ')}>
                        {translateCategorySpanish(category.category_name).replace(/-/g, ' ')}
                      </option>
                    )
                    )
                    :
                    getCategory.map((category, key) =>
                    (
                      <option key={key} value={category.value} valueurl={category.valueurl}>
                        {category.title}
                      </option>
                    ))
                }
                )
              </select>
            </div>
          </div>
          <div className='filters-modal-brand'>
            <div className='filters-modal-brand-title'>
              <label htmlFor={brandFilterId}>Marca</label>
            </div>
            <div className='filters-modal-brand-select'>
              <select
                id={brandFilterId}
                onChange={handleBrand}
                value={filters.brand}>
                return (<option value='all'>Todo</option>
                {(
                  getBrand.map((brand, index) =>
                  (
                    <option key={index} value={brand.brand_name}>
                      {brand.brand_name}
                    </option>
                  )
                  ))
                })
              </select>
            </div>
          </div>
          <div className='filters-modal-clear'>
            <button onClick={() => setOpenModal(false)}>Cerrar</button>
            <button onClick={clearFilters}>Resetear</button>
            {
              //  <button onClick={handleFilters}>Aplicar</button>
            }
          </div>

        </div>}
    </div>
  )
    : (
      <section className={`filters-main ${loading ? 'filters.loading' : ''}`}>
        {loading && <div className='spinner-overlay'><Spinner /></div>}
        <div className='container-filters-main'>
          <div className='filters-price filters-grid-row'>
            <div className='filters-price-title'>
              <label htmlFor={priceFilterId}>Precio</label>
            </div>
            <div className='filters-price-input'>
              <div className='filters-price-input-range'>
                <input
                  type='range'
                  id={priceFilterId}
                  min='0'
                  max='1000'
                  onChange={handlePrice}
                  value={parseInt(filters.price)}
                />
              </div>
              <div className='filters-price-input-number'>
                <input
                  className='no-arrows'
                  type='number'
                  min='0'
                  max='1000'
                  onChange={handlePrice}
                  value={filters.price}
                />€

              </div>
            </div>
          </div>
          <div className='filters-category filters-grid-row'>
            <div className='filters-category-title'>
              <label htmlFor={categoryFilterId}>Categorías</label>
            </div>
            <div className='filters-category-select'>
              <select id={categoryFilterId} onChange={handleCategory} value={filters.category}>
                return (
                <option value='all' valueurl='todo'>Todo</option>
                {
                  (getCategoryProduct.length > 0) ?

                    getCategoryProduct.map((category, key) =>
                    (
                      <option key={key} value={category.category_name} valueurl={translateCategorySpanish(category.category_name).replace(/-/g, ' ')}>
                        {translateCategorySpanish(category.category_name).replace(/-/g, ' ')}
                      </option>
                    )
                    )
                    :
                    getCategory.map((category, key) =>
                    (
                      <option key={key} value={category.value} valueurl={category.valueurl}>
                        {category.title}
                      </option>
                    ))
                }
                )
              </select>
            </div>
          </div>
          <div className='filters-brand filters-grid-row'>
            <div className='filters-brand-title'>
              <label htmlFor={brandFilterId}>Marca</label>
            </div>
            <div className='filters-brand-select'>
              <select id={brandFilterId} onChange={handleBrand} value={filters.brand}>
                return (<option value='all'>Todo</option>

                {
                  (
                    getBrand.map((brand, index) =>
                    (
                      <option key={index} value={brand.brand_name}>
                        {brand.brand_name}
                      </option>
                    )
                    )
                  )
                })
              </select>
            </div>
          </div>
          {/*
        <div className='filters-rating filters-grid-row'>
          <div className='filters-rating-title'>
            <label htmlFor={ratingFilterId}>Valoración</label>
          </div>
          <div className='filters-rating-select'>
            <select name='rating' id={ratingFilterId} onChange={handleRating} value={filters.rating}>
              <option value='all'>Todo</option>
              <option value='1'>1 estrellas</option>
              <option value='2'>2 estrellas</option>
              <option value='3'>3 estrellas</option>
              <option value='4'>4 estrellas</option>
              <option value='5'>5 estrellas</option>
            </select>
          </div>
        </div> */
          }
          <div className='filters-reset filters-grid-row'>
            <div className='filters-reset-title'>
              <label>Resetear filtros</label>
            </div>
            <div className='filters-reset-button'>
              <button onClick={clearFilters}>Resetear</button>
            </div>
          </div>

        </div>
      </section>
    )
}